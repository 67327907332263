import React from "react";
import { Flex, Text, Color, TextWeight } from "@ableco/baseline";

export default function ChartSummaryValues({ chartSummary = [] }) {
  return chartSummary.length > 0 ? (
    <Flex className="pr-8 mb-2" distribution="end">
      <Text
        className="font-lato"
        color={Color.Neutral100}
        weight={TextWeight.Light}
      >
        {chartSummary.map(({ label, value }, index) => {
          return (
            <div className="pb-1" key={`ChartSummaryValue${index}`}>
              {`${label}: `} <span className="text-green-light">{value} </span>
            </div>
          );
        })}
      </Text>
    </Flex>
  ) : null;
}
