import { useQuery } from "react-query";
import { format } from "date-fns";

async function fetchUserStorys(id, from, to) {
  const response = await fetch(
    `/api/users/${id}/metrics/events?from=${from}&to=${to}`,
  );

  return await response.json();
}
async function fetchUserSpecificStorys(id, from, to, type) {
  const response = await fetch(
    `/api/users/${id}/metrics/events?from=${from}&to=${to}&only=${type}`,
  );

  return await response.json();
}
async function fetchUser(id) {
  const response = await fetch(`/api/users/${id}`);

  return await response.json();
}
function useUserQuery(userId) {
  const query = useQuery(`users/${userId}`, () => fetchUser(userId));

  return query;
}
function useUserPrQuery(userId, from, to) {
  const query = useQuery([`usersPr/${userId}`, { from, to }], () =>
    fetchUserSpecificStorys(
      userId,
      format(from, "yyyy-MM-dd"),
      format(to, "yyyy-MM-dd"),
      "pr",
    ),
  );
  return query;
}
function useUserStoriesQuery(userId, from, to) {
  const query = useQuery([`userStorys/${userId}`, { from, to }], () =>
    fetchUserStorys(
      userId,
      format(from, "yyyy-MM-dd"),
      format(to, "yyyy-MM-dd"),
    ),
  );
  return query;
}
export { useUserQuery, useUserPrQuery, useUserStoriesQuery };
