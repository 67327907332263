import React, { useState } from "react";
import InlineDeleteConfirmation from "./InlineDeleteConfirmation";
import DeleteIcon from "icons/delete.svg";
import useRisksQuery from "hooks/v2/useRisksQuery";
import { useMutation, useQueryClient } from "react-query";
import { isEmpty } from "lodash";
import EditIcon from "icons/edit.svg";
import RiskForm from "./RiskForm";

function RiskItem({ risk, handleDelete, dateFilters, projectId }) {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isRiskFormOpen, setIsRiskFormOpen] = useState(false);

  return (
    <li className="mb-1 px-2 group hover:bg-neutral-800 bg-nord-light rounded-lg">
      <div className="flex items-center">
        <div className="flex-1 text-base text-neutral-100 leading-loose">
          {risk.description}
        </div>

        {showConfirmation ? (
          <InlineDeleteConfirmation
            onCancel={() => setShowConfirmation(false)}
            onDelete={() => {
              handleDelete(risk.id);
              setShowConfirmation(false);
            }}
          />
        ) : (
          <div className="flex items-center">
            <EditIcon
              className="text-neutral-200 fill-current flex-shrink-0 ml-1.5 cursor-pointer"
              onClick={() => setIsRiskFormOpen(true)}
            />
            <DeleteIcon
              className="text-neutral-200 fill-current flex-shrink-0 ml-1.5 cursor-pointer"
              onClick={() => setShowConfirmation(true)}
            />
          </div>
        )}
        <RiskForm
          open={isRiskFormOpen}
          setOpen={setIsRiskFormOpen}
          dateFilters={dateFilters}
          isEditing={true}
          risk={risk}
          projectId={projectId}
        />
      </div>
    </li>
  );
}

export default function RisksList({ items, projectId, dateFilters }) {
  const queryClient = useQueryClient();
  const { deleteRisk } = useRisksQuery();

  const { mutateAsync: removeRisk } = useMutation(deleteRisk, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        "/api/**/**/risks",
        { ...dateFilters, projectId },
      ]);
    },
  });

  return (
    <div data-testid="risksList">
      {isEmpty(items) ? (
        <span className="block text-neutral-200 my-6">
          No risks for this week.
        </span>
      ) : (
        <ul>
          {items.map(item => (
            <RiskItem
              key={`${item.type}-${item.id}`}
              risk={item.attributes}
              handleDelete={riskId => removeRisk(riskId)}
              dateFilters={dateFilters}
              projectId={projectId}
            />
          ))}
        </ul>
      )}
    </div>
  );
}
