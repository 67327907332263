import React, { useContext, useState } from "react";
import { BarChart } from "components/charts/Charts";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
} from "recharts";
import MetricChartContainer from "components/MetricChartContainer";
import { renderCustomBarLabel } from "helpers/customBarLabel";
import { useMetricsQuery } from "hooks/useMetricsQuery";
import { format, parse, addDays } from "date-fns";
import { uniq } from "lodash";
import { BAR_COLORS } from "helpers/constants";
import { DateFiltersContext } from "contexts/DateFiltersContext";
import JobMappingSelector from "./JobMappingSelector";
import CustomFilters from "components/CustomFilters";
import { Inline } from "@ableco/baseline";

const FILTERS = [
  { label: "Role", value: "role" },
  { label: "Job", value: "job" },
];

function formatXAxis(date, resolution) {
  switch (resolution) {
    case "weekly":
      return format(date, "EEE d");
    case "quarterly":
      const from = format(date, "MMM d");
      const to = format(addDays(date, 6), "MMM d");
      return `${from} - ${to}`;
    case "yearly":
      return format(date, "MMM");
    default:
      return format(date, "MMM d");
  }
}

function formatTooltip(date, resolution) {
  switch (resolution) {
    case "weekly":
      return format(date, "EEE MMM do, y");
    case "quarterly":
      const from = format(date, "MMM d");
      const to = format(addDays(date, 6), "MMM d");
      return `Week: ${from} - ${to}`;
    case "yearly":
      return format(date, "MMMM, y");
    default:
      return format(date, "MMM do, y");
  }
}

export default function ReferralsOverTimeChart({ minimal = false }) {
  const { dateFilters } = useContext(DateFiltersContext);
  const [currenByFilter, setCurrenByFilter] = useState("role");
  const [jobIds, setJobsIds] = useState("");

  const handleByFilterChange = by => {
    setCurrenByFilter(by);
  };

  const { data: metrics, isLoading } = useMetricsQuery(
    "recruitment/referrals_over_time",
    {
      ...dateFilters,
      by: currenByFilter,
      job_ids: jobIds,
    },
  );

  const fetchedData = metrics?.data || [];
  const barItems = fetchedData.reduce(
    (acc, current) =>
      uniq([
        ...acc,
        ...Object.keys(current).filter(key => !["name", "total"].includes(key)),
      ]),
    [],
  );
  const renderChart = (
    <BarChart
      data={fetchedData}
      height={minimal && 280}
      margin={{ top: 20, right: 30, left: 0, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="name"
        type="category"
        tickFormatter={tickItem => {
          const tickDate = parse(tickItem, "yyyy-MM-dd", new Date());
          return formatXAxis(tickDate, dateFilters.resolution);
        }}
      />
      <YAxis type="number" />
      <Tooltip
        cursor={{ fill: "transparent" }}
        labelFormatter={name => {
          const tooltipDate = parse(name, "yyyy-MM-dd", new Date());
          return formatTooltip(tooltipDate, dateFilters.resolution);
        }}
      />
      {!minimal && <Legend />}
      {barItems.map((barItem, index) => (
        <Bar
          key={index}
          stackId="group"
          dataKey={barItem}
          name={barItem}
          fill={BAR_COLORS[index]}
          maxBarSize={48}
        >
          {index === barItems.length - 1 && (
            <LabelList dataKey="total" content={renderCustomBarLabel} />
          )}
        </Bar>
      ))}
    </BarChart>
  );
  return (
    <MetricChartContainer
      data={fetchedData}
      isLoading={isLoading}
      title={!minimal && "Referral Over Time"}
      minimal={minimal}
      resolution={dateFilters.resolution}
      filterComponent={
        <Inline>
          <CustomFilters
            options={FILTERS}
            filterValue={currenByFilter}
            onFilterChange={handleByFilterChange}
          />
          {currenByFilter === "job" && (
            <JobMappingSelector onChangeJobIds={setJobsIds} />
          )}
        </Inline>
      }
    >
      {renderChart}
    </MetricChartContainer>
  );
}
