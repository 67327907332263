import React, { useState } from "react";

export default function Select({
  previousSelectedOption = "",
  options,
  handleSelect,
  defaultOptionValue,
  defaultOptionPlaceholder,
  isDefaultOptionDisabled = false,
}) {
  const [selectedOption, setSelectedOption] = useState(previousSelectedOption);

  const handleOnChange = e => {
    setSelectedOption(e.target.value);
    handleSelect(e.target.value);
  };

  return (
    <select
      className="bg-neutral-800"
      value={selectedOption}
      onChange={handleOnChange}
    >
      <option
        selected
        value={defaultOptionValue}
        disabled={isDefaultOptionDisabled}
      >
        {defaultOptionPlaceholder}
      </option>
      {options.map((issue_tag, index) => {
        return (
          <option value={issue_tag} key={index}>
            {issue_tag}
          </option>
        );
      })}
    </select>
  );
}
