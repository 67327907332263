import React from "react";

export function renderCustomBarLabel(props) {
  const { x = 0, y = 0, width, value, horizontal = false } = props;
  const radius = 10;
  const valueOfX = horizontal ? x + width + 20 : x + width / 2;
  const valueOfY = horizontal ? y + 20 - radius : y - radius;
  return (
    <g>
      <text
        x={valueOfX}
        y={valueOfY}
        fill="#fff"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {value != 0 && value}
      </text>
    </g>
  );
}
