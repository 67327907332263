import React, { useContext, useState } from "react";
import { BarChart } from "components/charts/Charts";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
} from "recharts";
import MetricChartContainer from "components/MetricChartContainer";
import LinkableAxisTick from "components/charts/LinkableAxisTick";
import { renderCustomBarLabel } from "helpers/customBarLabel";
import { useMetricsQuery } from "hooks/useMetricsQuery";
import { DateFiltersContext } from "contexts/DateFiltersContext";
import { uniq } from "lodash";
import { BAR_COLORS } from "helpers/constants";
import CustomFilters from "components/CustomFilters";

const HISTORICAL_BY = [
  { label: "Offer acceptance rate", value: "offer" },
  {
    label: "Recruiter Interview to Hire",
    value: "experience-fit",
  },
  {
    label: "Technical Interview to Hire",
    value: "devskiller",
  },
];

export default function HistoricalInterviewChart() {
  const [historicalBy, setHistoricalBy] = useState("offer");
  const { dateFilters } = useContext(DateFiltersContext);

  const { data: metrics, isLoading } = useMetricsQuery(
    "recruitment/historical_interview",
    {
      ...dateFilters,
      by: historicalBy,
    },
  );

  const fetchedData = metrics?.data || [];
  const { total_candidates, total_hires, conversion_rate } =
    metrics?.meta || {};

  const barItems = fetchedData.reduce(
    (acc, current) =>
      uniq([
        ...acc,
        ...Object.keys(current).filter(
          key => !["total", "stage"].includes(key),
        ),
      ]),
    [],
  );

  const renderChart = (
    <BarChart
      data={fetchedData}
      margin={{ top: 20, right: 30, left: 0, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="stage" tick={<LinkableAxisTick data={fetchedData} />} />
      <YAxis />
      <Tooltip cursor={{ fill: "transparent" }} />
      <Legend wrapperStyle={{ position: "relative" }} />
      {barItems.map((barItem, index) => (
        <Bar
          key={index}
          dataKey={barItem}
          name={barItem}
          fill={BAR_COLORS[index]}
          barSize={48}
        >
          <LabelList dataKey={barItem} content={renderCustomBarLabel} />
        </Bar>
      ))}
    </BarChart>
  );

  return (
    <MetricChartContainer
      data={fetchedData}
      isLoading={isLoading}
      title="Historical Interview"
      resolution={dateFilters.resolution}
      filterComponent={
        <CustomFilters
          options={HISTORICAL_BY}
          filterValue={historicalBy}
          onFilterChange={setHistoricalBy}
        />
      }
    >
      <p className="text-white">
        <span className="font-bold">Total candidates: </span>
        <span>{total_candidates}</span>
      </p>
      <p className="text-white">
        <span className="font-bold">Total hires: </span>
        <span>{total_hires}</span>
      </p>
      <p className="text-white">
        <span className="font-bold">Conversion rate: </span>
        <span>{`${conversion_rate}%`}</span>
      </p>
      {renderChart}
    </MetricChartContainer>
  );
}
