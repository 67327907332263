import React, { useState } from "react";
import StoryTracker from "./StoryTracker";
import StoryTrackerForm from "./StoryTrackerForm";
import PullRequestTracker from "./PullRequestTracker";
import PullRequestTrackerForm from "./PullRequestTrackerForm";
import Button from "components/FilterButton";
import LoadingData from "components/LoadingData";
import useResourcesQuery from "queries/resourcesQueries";

export default function Trackers({ projectId }) {
  const [isStoryTrackerFormOpen, setStoryTrackerFormOpen] = useState(false);
  const [isPrTrackerFormOpen, setPrTrackerFormOpen] = useState(false);

  const {
    data: storyTrackers,
    isLoading: isLoadingStoryTrackers,
  } = useResourcesQuery("story_trackers", {
    projectId,
  });

  const {
    data: prTrackers,
    isLoading: isLoadingPrTrackers,
  } = useResourcesQuery("pull_request_trackers", {
    projectId,
  });

  const fetchedStoryTrackers = storyTrackers?.data || [];
  const fetchedPrTrackers = prTrackers?.data || [];

  return (
    <div className="px-8 pt-4 pb-8">
      <div className="flex justify-between mb-4">
        <h3 className="text-white text-xl font-bold">Story Trackers</h3>
        <Button
          className="h-10 rounded-md w-auto"
          onClick={() => setStoryTrackerFormOpen(true)}
        >
          Add Tracker
        </Button>
      </div>
      {isLoadingStoryTrackers ? (
        <LoadingData />
      ) : (
        <div className="space-y-4">
          {fetchedStoryTrackers.map(tracker => (
            <StoryTracker
              key={`tracker-${tracker.id}`}
              tracker={tracker.attributes}
            />
          ))}
        </div>
      )}
      <div className="flex justify-between mt-8 mb-4">
        <h3 className="text-white text-xl font-bold">Pull Requests Trackers</h3>
        <Button
          className="h-10 rounded-md w-auto"
          onClick={() => setPrTrackerFormOpen(true)}
        >
          Add Tracker
        </Button>
      </div>
      {isLoadingPrTrackers ? (
        <LoadingData />
      ) : (
        <div className="space-y-4">
          {fetchedPrTrackers.map(tracker => (
            <PullRequestTracker
              key={`tracker-${tracker.id}`}
              tracker={tracker.attributes}
            />
          ))}
        </div>
      )}
      <StoryTrackerForm
        open={isStoryTrackerFormOpen}
        setOpen={setStoryTrackerFormOpen}
        projectId={projectId}
      />

      <PullRequestTrackerForm
        open={isPrTrackerFormOpen}
        setOpen={setPrTrackerFormOpen}
        projectId={projectId}
      />
    </div>
  );
}
