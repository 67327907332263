import React, { useState, useEffect, createContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { parse, format, isSameDay } from "date-fns";
import useDOMState from "hooks/useDOMState";
import {
  currentDateRange,
  previousDateRange,
  nextDateRange,
} from "helpers/calendar";

const DateFiltersContext = createContext();

function DateFiltersProvider({ children }) {
  const history = useHistory();
  const { projectId, userId } = useParams();
  const [{ dateFilters: baseDateFilters }] = useDOMState("root");
  const [dateFilters, setDateFilters] = useState(() => {
    const { from, to } = baseDateFilters;

    return {
      ...baseDateFilters,
      from: parse(from, "yyyy-MM-dd", new Date()),
      to: parse(to, "yyyy-MM-dd", new Date()),
    };
  });

  function changeResolution(resolution) {
    if (resolution) {
      const [newFrom, newTo] = currentDateRange({
        resolution,
      });
      setDateFilters(previousDateFilters => ({
        ...previousDateFilters,
        resolution,
      }));

      changeDateRange(newFrom, newTo);
    }
  }

  function changeDateRange(from, to) {
    setDateFilters(previousDateFilters => {
      if (
        isSameDay(previousDateFilters.from, from) &&
        isSameDay(previousDateFilters.to, to)
      ) {
        return previousDateFilters;
      }

      return {
        ...previousDateFilters,
        from,
        to,
      };
    });
  }

  useEffect(() => {
    const isSearchPage = history.location.pathname
      .toLowerCase()
      .endsWith("search");
    if (projectId || userId || isSearchPage) {
      const from = format(dateFilters.from, "yyyy-MM-dd");
      const to = format(dateFilters.to, "yyyy-MM-dd");

      const newSearch = `?resolution=${dateFilters.resolution}&from=${from}&to=${to}`;

      if (history.location.search !== newSearch) {
        history.replace(newSearch);
      }
    }
  }, [history, dateFilters, projectId, userId]);

  return (
    <DateFiltersContext.Provider
      value={{
        dateFilters,
        setDateFilters,
        changeResolution,
        changeDateRange,
        currentDateRange,
        previousDateRange,
        nextDateRange,
      }}
    >
      {children}
    </DateFiltersContext.Provider>
  );
}

export { DateFiltersContext };
export default DateFiltersProvider;
