import React, { useEffect, useContext } from "react";
import { Flex, Text, Color } from "@ableco/baseline";
import { format, isAfter } from "date-fns";
import FilterButton from "components/FilterButton";
import { DateFiltersContext } from "contexts/DateFiltersContext";
import ArrowIcon from "icons/arrow.svg";
import classNames from "classnames";

function labelDateRange({ from, to, resolution }) {
  switch (resolution) {
    case "yearly":
      return format(from, "yyyy");
    case "quarterly":
      return format(from, "QQQ yyyy");
    case "monthly":
      return format(from, "MMMM yyyy");
    case "weekly":
      return `${format(from, "MMM dd")} - ${format(to, "MMM dd yyyy")}`;
  }
}

const FILTERS = {
  w: { label: "Week", value: "weekly" },
  m: { label: "Month", value: "monthly" },
  q: { label: "Quarter", value: "quarterly" },
  y: { label: "Year", value: "yearly" },
};

export default function Filter({
  showResolutions = true,
  filters = "wmqy",
  defaultResolution,
}) {
  const {
    dateFilters,
    changeResolution,
    changeDateRange,
    currentDateRange,
    previousDateRange,
    nextDateRange,
  } = useContext(DateFiltersContext);
  useEffect(() => {
    changeResolution(defaultResolution);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultResolution]);
  return (
    <Flex wrap>
      {showResolutions && (
        <Flex className="mr-5 mb-6">
          {filters.split("").map((item, index) => {
            const { label, value } = FILTERS[item.toLowerCase()];
            const filterItemClassName = classNames({
              "rounded-tl-md rounded-bl-md": index === 0,
              "rounded-tr-md rounded-br-md": index === filters.length - 1,
            });
            return (
              <FilterButton
                key={index}
                className={filterItemClassName}
                active={dateFilters.resolution === value}
                onClick={() => changeResolution(value)}
              >
                {label}
              </FilterButton>
            );
          })}
        </Flex>
      )}
      <Flex className="mr-5 mb-6">
        <FilterButton
          className="rounded-tl-md rounded-bl-md"
          onClick={() => {
            const [from, to] = previousDateRange(dateFilters);
            changeDateRange(from, to);
          }}
        >
          <ArrowIcon className="w-4 h-4" />
        </FilterButton>
        <Text
          color={Color.Neutral100}
          className="py-3 px-5 bg-neutral-800"
          style={{ display: "block" }}
        >
          {labelDateRange(dateFilters)}
        </Text>
        <FilterButton
          className="rounded-tr-md rounded-br-md"
          disabled={isAfter(dateFilters.to, new Date())}
          onClick={() => {
            const [from, to] = nextDateRange(dateFilters);
            changeDateRange(from, to);
          }}
        >
          <ArrowIcon className="w-4 h-4 rotate-180 transform" />
        </FilterButton>
      </Flex>
      <FilterButton
        className="mb-6 rounded-md"
        onClick={() => {
          const [from, to] = currentDateRange(dateFilters);

          changeDateRange(from, to);
        }}
      >
        Current
      </FilterButton>
    </Flex>
  );
}
