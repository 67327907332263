import React, { createContext } from "react";
import { useQuery } from "react-query";
import useDOMState from "hooks/useDOMState";
import { Redirect, useLocation } from "react-router-dom";
const ProjectsContext = createContext();

async function fetchProjects() {
  const response = await fetch("/api/projects");

  return await response.json();
}

function ProjectsProvider({ children }) {
  const location = useLocation();
  const query = useQuery("projects", fetchProjects);
  const [{ currentUser }] = useDOMState("root", {});
  if (!currentUser) {
    return (
      <Redirect
        to={{ pathname: "/login", state: { referrer: location.pathname } }}
      />
    );
  }
  const isLoadingProjects = query.isLoading;
  const data = query?.data?.data || [];
  const projects = data.map(prj => prj.attributes);

  return (
    <ProjectsContext.Provider value={{ isLoadingProjects, projects }}>
      {children}
    </ProjectsContext.Provider>
  );
}

export { ProjectsContext };
export default ProjectsProvider;
