import React, { useState, useEffect } from "react";
import Modal from "components/Modal";
import ListboxPicker from "components/ListboxPicker";
import FormLabel from "components/FormLabel";
import FormFooter from "components/FormFooter";
import pullRequestTrackerQueries from "queries/pullRequestTrackerQueries";
import { useMutation, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import { isEmpty } from "lodash";

const trackerTypes = [{ value: 0, text: "Github" }];

function invalidateQueries(queryClient, projectId, currentLocation) {
  if (currentLocation === "/admin/settings")
    queryClient.invalidateQueries("projects");
  else
    queryClient.invalidateQueries([
      "/api/**/**/pull_request_trackers",
      { projectId },
    ]);
}

export default function PullRequestTrackerForm({
  open,
  setOpen,
  projectId,
  isEditing = false,
  tracker,
}) {
  const [trackerName, setTrackerName] = useState("");
  const [trackerType, setTrackerType] = useState(trackerTypes[0]);
  const [defaultBranch, setDefaultBranch] = useState("");
  const [codeClimateId, setCodeClimateId] = useState("");
  const [herokuApp, setHerokuApp] = useState("");

  const location = useLocation();

  const queryClient = useQueryClient();
  const {
    createPullRequestTracker,
    editPullRequestTracker,
  } = pullRequestTrackerQueries();

  const { mutateAsync: addTracker } = useMutation(createPullRequestTracker, {
    onSuccess: () =>
      invalidateQueries(queryClient, projectId, location.pathname),
  });

  const { mutateAsync: editTracker } = useMutation(editPullRequestTracker, {
    onSuccess: () =>
      invalidateQueries(queryClient, projectId, location.pathname),
  });

  useEffect(() => {
    if (isEditing) {
      const {
        repo_id: name,
        repo_type,
        default_branch,
        integration_ids,
      } = tracker;

      setTrackerName(name);
      setDefaultBranch(default_branch);
      setCodeClimateId(integration_ids?.codeclimate_id);
      setHerokuApp(integration_ids?.heroku_app_name);
      setTrackerType(
        trackerTypes.find(t => t.text.toLowerCase() === repo_type),
      );
    }
  }, [tracker, isEditing]);

  const handleOpenForm = isOpen => {
    setOpen(isOpen);

    if (!isOpen && !isEditing) {
      setTrackerName("");
      setDefaultBranch("");
      setCodeClimateId("");
      setHerokuApp("");
    }
  };

  const handleSubmit = () => {
    const payload = {
      id: tracker?.id,
      project_id: projectId,
      repo_id: trackerName,
      repo_type: trackerType.value,
      default_branch: defaultBranch,
      integration_ids: {
        codeclimate_id: codeClimateId,
        heroku_app_name: herokuApp,
      },
    };

    if (isEditing) {
      editTracker(payload).then(() => {
        handleOpenForm(false);
      });
    } else {
      addTracker(payload).then(() => {
        handleOpenForm(false);
      });
    }
  };

  const formContent = (
    <div>
      <FormLabel required>Name</FormLabel>
      <input
        className="focus:outline-none rounded-md w-full p-2"
        placeholder="e.g. ableco/metrics-dashboard"
        onChange={({ target }) => setTrackerName(target.value)}
        value={trackerName}
      />

      <div className="flex my-2">
        <div className="w-1/2 mr-4">
          <FormLabel required>Tracker</FormLabel>
          <ListboxPicker
            onSelect={setTrackerType}
            items={trackerTypes}
            preSelectedOption={trackerType}
          />
        </div>
        <div className="w-1/2">
          <FormLabel required>Default Branch</FormLabel>
          <input
            className="focus:outline-none rounded-md w-full p-2"
            placeholder="e.g. main"
            onChange={({ target }) => setDefaultBranch(target.value)}
            value={defaultBranch}
          />
        </div>
      </div>

      <FormLabel>Code Climate ID</FormLabel>
      <input
        className="focus:outline-none rounded-md w-full p-2 mb-2"
        placeholder="e.g. 597a2ff6fb0b8f02a50d099"
        onChange={({ target }) => setCodeClimateId(target.value)}
        value={codeClimateId}
      />

      <FormLabel>Heroku App</FormLabel>
      <input
        className="focus:outline-none rounded-md w-full p-2"
        placeholder="e.g. metrics-dashboard-production"
        onChange={({ target }) => setHerokuApp(target.value)}
        value={herokuApp}
      />
    </div>
  );

  return (
    <Modal
      title={`${isEditing ? "Edit" : "Add"} Pull Request Tracker`}
      subtitle={`Fill out the form to ${
        isEditing ? "edit" : "add a new"
      } Pull Request Tracker`}
      content={formContent}
      footer={
        <FormFooter
          onCancel={() => handleOpenForm(false)}
          onSubmit={handleSubmit}
          disabled={isEmpty(trackerName) || isEmpty(defaultBranch)}
        />
      }
      open={open}
      setOpen={handleOpenForm}
    />
  );
}
