import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import OverviewDashboard from "components/dashboard/OverviewDashboard";
import { PROJECT_MENU } from "./config";
import ProfilePage from "pages/ProfilePage";
import PipelinePerJob from "pages/recruitment/PipelinePerJob";
import PipelineStageUpdates from "pages/recruitment/PipelineStageUpdates";
import ApplicantCycleTime from "pages/recruitment/ApplicantCycleTime";
import ReferralsOverTime from "pages/recruitment/ReferralsOverTime";
import CrmProspecting from "pages/recruitment/CrmProspecting";
import HistoricalInterview from "pages/recruitment/HistoricalInterview";
import PassThroughRate from "pages/recruitment/PassThroughRate";
import AdminSettingsPage from "pages/AdminSettingsPage";
import useDOMState from "hooks/useDOMState";
import UserSnapshotPage from "pages/UserSnapshot";

function RedirectNonAdmin() {
  alert("Only admins have access to this page.");
  return <Redirect to="/" />;
}

function ActivePage() {
  const [{ currentUser }] = useDOMState("root", {});
  const { level } = currentUser;

  return (
    <div className="overflow-auto w-full h-full px-4 pt-4 pb-16">
      <Switch>
        <Route exact path="/">
          <OverviewDashboard />
        </Route>
        {PROJECT_MENU.map((route, i) => {
          let childRoute = <route.main />;

          if (route?.adminOnly && level != "admin")
            childRoute = <RedirectNonAdmin />;

          return (
            <Route
              key={i}
              exact
              path={`/project/:projectId/${route.path}`}
              children={childRoute}
            />
          );
        })}
        <Route exact path={"/recruitment"}>
          <Redirect to="/recruitment/pipeline_per_job" />
        </Route>
        <Route exact path={"/recruitment/pipeline_per_job"}>
          <PipelinePerJob />
        </Route>
        <Route exact path={"/recruitment/applicant_cycle_time"}>
          <ApplicantCycleTime />
        </Route>
        <Route exact path={"/recruitment/referrals_over_time"}>
          <ReferralsOverTime />
        </Route>
        <Route exact path={"/recruitment/crm_prospecting_activity"}>
          <CrmProspecting />
        </Route>
        <Route exact path={"/recruitment/historical_interview"}>
          <HistoricalInterview />
        </Route>
        <Route exact path={"/recruitment/pass_through_rate"}>
          <PassThroughRate />
        </Route>
        <Route exact path={"/recruitment/pipeline_stage_updates"}>
          <PipelineStageUpdates />
        </Route>
        <Route exact path="/users/:userId">
          <ProfilePage />
        </Route>
        <Route exact path="/admin/settings">
          <AdminSettingsPage selectedTab={"projects"} />
        </Route>
        <Route exact path="/admin/settings/recruitment">
          <AdminSettingsPage selectedTab={"recruitment"} />
        </Route>
        <Route exact path="/admin/settings/data_fetching">
          <AdminSettingsPage selectedTab={"data_fetching"} />
        </Route>
        <Route exact path="/users/:userId/snapshot">
          <UserSnapshotPage />
        </Route>
      </Switch>
    </div>
  );
}

export default ActivePage;
