import React from "react";

export default function Scrollable({
  className,
  children,
  maxHeight,
  innerRef = null,
  as: As = "div",
  ...otherProps
}) {
  return (
    <As
      className={className}
      style={{ maxHeight }}
      ref={innerRef}
      {...otherProps}
    >
      {children}
    </As>
  );
}
