import React, { useContext } from "react";
import { DateFiltersContext } from "contexts/DateFiltersContext";
import { useParams } from "react-router-dom";
import { BarChart } from "components/charts/Charts";
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import MetricChartContainer from "components/MetricChartContainer";
import ChartSummaryValues from "./ChartSummaryValues";
import useMetricsQuery from "hooks/v2/useMetricsQuery";
import IssuesBeforeDeploymentDetails from "./IssuesBeforeDeploymentDetails";

export default function IssuesBeforeDeploymentChart() {
  const { dateFilters } = useContext(DateFiltersContext);
  const { projectId, userId } = useParams();
  const { data: metrics, isLoading } = useMetricsQuery("issues", {
    ...dateFilters,
    projectId,
    userId,
  });
  const fetchedData = metrics?.data || [];

  const renderChartSummary = (
    <>
      <ChartSummaryValues
        chartSummary={
          metrics
            ? [
                {
                  label: "Data",
                  value: metrics.meta.data || 0,
                },
                {
                  label: "Functional",
                  value: metrics.meta.functional || 0,
                },
                {
                  label: "UX/UI",
                  value: metrics.meta.ux_ui || 0,
                },
                {
                  label: "Copy",
                  value: metrics.meta.copy || 0,
                },
                {
                  label: "Edge Case",
                  value: metrics.meta.edge_case || 0,
                },
                {
                  label: "Unknown",
                  value: metrics.meta.unknown || 0,
                },
              ]
            : null
        }
      />
      <ChartSummaryValues
        chartSummary={
          metrics
            ? [
                {
                  label: "Closed Issues",
                  value: metrics.meta.issues_closed || 0,
                },
                {
                  label: "Opened Issues",
                  value: metrics.meta.issues_opened || 0,
                },
              ]
            : null
        }
      />
    </>
  );

  const renderChart = (
    <BarChart data={fetchedData}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="start_date" />
      <YAxis />
      <Tooltip cursor={{ fill: "transparent" }} />
      <Legend wrapperStyle={{ position: "relative" }} />
      <Bar
        dataKey="data"
        name="Data"
        stackId="issues_stack_id"
        fill="#a8bb51"
        maxBarSize={48}
      />
      <Bar
        dataKey="functional"
        name="Functional"
        stackId="issues_stack_id"
        fill="#4da2dc"
        maxBarSize={48}
      />
      <Bar
        dataKey="ux_ui"
        name="UX/UI"
        stackId="issues_stack_id"
        fill="#9d4ec9"
        maxBarSize={48}
      />
      <Bar
        dataKey="copy"
        name="Copy"
        stackId="issues_stack_id"
        fill="#da713e"
        maxBarSize={48}
      />
      <Bar
        dataKey="edge_case"
        name="Edge case"
        stackId="issues_stack_id"
        fill="#60c2c2"
        maxBarSize={48}
      />
      <Bar
        dataKey="unknown"
        name="Unknown"
        stackId="issues_stack_id"
        fill="#5f68ef"
        maxBarSize={48}
      />
    </BarChart>
  );

  return (
    <MetricChartContainer
      data={fetchedData}
      isLoading={isLoading}
      title="Issues Before Deployments"
      description="The Issues Before Deployments chart shows the number of issues reported in Pitches, Tweaks, Bugs and Chores grouped by periods of time."
    >
      {renderChartSummary}
      {renderChart}
      <IssuesBeforeDeploymentDetails />
    </MetricChartContainer>
  );
}
