import React from "react";
import { METRICS_DATA } from "helpers/constants";
import { isEmpty } from "lodash";

function thresholdColor(statValue, statConfig) {
  if (isEmpty(statConfig) || isEmpty(statConfig.green_threshold)) return null;

  const greenThreshold = parseInt(statConfig.green_threshold);
  const yellowThreshold = parseInt(statConfig.yellow_threshold);
  const redThreshold = parseInt(statConfig.red_threshold);

  if (greenThreshold > yellowThreshold) {
    if (statValue >= greenThreshold) return "green";
    return statValue >= yellowThreshold ? "yellow" : "red";
  } else if (greenThreshold < yellowThreshold) {
    if (statValue <= greenThreshold) return "green";
    return statValue <= yellowThreshold ? "yellow" : "red";
  } else {
    if (statValue <= redThreshold) return "red";
    return statValue >= greenThreshold ? "green" : "yellow";
  }
}

function thresholdDescription(statConfig) {
  let description = [];
  const {
    green_threshold,
    yellow_threshold,
    red_threshold,
    is_percentage,
  } = statConfig;

  const suffix = is_percentage ? "%" : "";
  const greenThreshold = green_threshold + suffix;
  const yellowThreshold = yellow_threshold + suffix;
  const redThreshold = red_threshold + suffix;

  if (parseInt(green_threshold) > parseInt(yellow_threshold)) {
    description = [
      `Green: Greater than or equal to ${greenThreshold}`,
      `Yellow: Greater than or equal to ${yellowThreshold}`,
      `Red: Less than ${yellowThreshold}`,
    ];
  } else if (parseInt(green_threshold) > parseInt(red_threshold)) {
    description = [
      `Green: Greater than or equal to ${greenThreshold}`,
      `Yellow: Greater than or equal to ${redThreshold}`,
      `Red: Less than ${redThreshold}`,
    ];
  } else {
    description = [
      `Green: Less than or equal to ${greenThreshold}`,
      `Yellow: Less than or equal to ${yellowThreshold}`,
      `Red: Greater than ${yellowThreshold}`,
    ];
  }

  return description;
}

function StatTooltipContent({ description, formula = [], thresholds = [] }) {
  return (
    <div className="max-w-xs space-y-2 text-black">
      <p>{description}</p>
      {!isEmpty(formula) && (
        <div>
          <strong className="block">Formula:</strong>
          <ul className="ml-4 list-disc space-y-2">
            {formula.map((desc, index) => (
              <li key={index}>{desc}</li>
            ))}
          </ul>
        </div>
      )}
      {!isEmpty(thresholds) && (
        <div>
          <strong className="block">Thresholds:</strong>
          <ul className="ml-4 list-disc space-y-2">
            {thresholds.map((desc, index) => (
              <li key={index}>{desc}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export function buildStatsInfo(statValues, project) {
  const {
    pitches_count,
    avg_deviation_definition,
    avg_deviation_development,
    avg_last_task_vs_scheduled_end_date,
    issues_per_flow,
    time_to_fix_per_issue,
    pr_ready_to_approved_hours,
    pct_pitches_ahead_of_time,
    pct_accept_task_issues_one_day,
    pct_prs_ready_approve_two_days,
  } = statValues;

  let projectStats = [];

  const buildStatContent = (statKey, statConfig) => {
    switch (statKey) {
      case "pitch_count":
        projectStats.push({
          name: METRICS_DATA[statKey].name,
          firstValue: pitches_count,
          firstUnit: "",
          thresholdColor: thresholdColor(pitches_count, statConfig),
          tooltipContent: (
            <StatTooltipContent description={METRICS_DATA[statKey].tooltip} />
          ),
        });
        break;
      case "definition_deviation":
        projectStats.push({
          name: METRICS_DATA[statKey].name,
          firstValue: avg_deviation_definition,
          firstUnit: "%",
          thresholdColor: thresholdColor(avg_deviation_definition, statConfig),
          tooltipContent: (
            <StatTooltipContent
              description={METRICS_DATA[statKey].tooltip}
              formula={METRICS_DATA[statKey].formula}
              thresholds={thresholdDescription(statConfig)}
            />
          ),
        });
        break;
      case "development_deviation":
        projectStats.push({
          name: METRICS_DATA[statKey].name,
          firstValue: avg_deviation_development,
          firstUnit: "%",
          thresholdColor: thresholdColor(avg_deviation_development, statConfig),
          tooltipContent: (
            <StatTooltipContent
              description={METRICS_DATA[statKey].tooltip}
              formula={METRICS_DATA[statKey].formula}
              thresholds={thresholdDescription(statConfig)}
            />
          ),
        });
        break;
      case "deliver_sed":
        projectStats.push({
          name: METRICS_DATA[statKey].name,
          firstValue: pct_pitches_ahead_of_time,
          firstUnit: "%",
          secondValue: avg_last_task_vs_scheduled_end_date,
          secondUnit: "days",
          thresholdColor: thresholdColor(pct_pitches_ahead_of_time, statConfig),
          tooltipContent: (
            <StatTooltipContent
              description={METRICS_DATA[statKey].tooltip}
              formula={METRICS_DATA[statKey].formula}
              thresholds={thresholdDescription(statConfig)}
            />
          ),
        });
        break;
      case "flow_issues":
        projectStats.push({
          name: METRICS_DATA[statKey].name,
          firstValue: issues_per_flow,
          firstUnit: "",
          thresholdColor: thresholdColor(issues_per_flow, statConfig),
          tooltipContent: (
            <StatTooltipContent
              description={METRICS_DATA[statKey].tooltip}
              thresholds={thresholdDescription(statConfig)}
            />
          ),
        });
        break;
      case "ttf_isues":
        projectStats.push({
          name: METRICS_DATA[statKey].name,
          firstValue: pct_accept_task_issues_one_day,
          firstUnit: "%",
          secondValue: time_to_fix_per_issue,
          secondUnit: "hrs",
          thresholdColor: thresholdColor(
            pct_accept_task_issues_one_day,
            statConfig,
          ),
          tooltipContent: (
            <StatTooltipContent
              description={METRICS_DATA[statKey].tooltip}
              thresholds={thresholdDescription(statConfig)}
            />
          ),
        });
        break;
      case "pr_ready_approved":
        projectStats.push({
          name: METRICS_DATA[statKey].name,
          firstValue: pct_prs_ready_approve_two_days,
          firstUnit: "%",
          secondValue: pr_ready_to_approved_hours,
          secondUnit: "hrs",
          thresholdColor: thresholdColor(
            pct_prs_ready_approve_two_days,
            statConfig,
          ),
          tooltipContent: (
            <StatTooltipContent
              description={METRICS_DATA[statKey].tooltip}
              thresholds={thresholdDescription(statConfig)}
            />
          ),
        });
        break;
    }
  };

  if (!isEmpty(project)) {
    Object.entries(project?.status_metrics).map(([key, value]) => {
      if (value.is_visible) buildStatContent(key, value);
    });
  }

  return projectStats;
}
