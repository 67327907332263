import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import {
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { BarChart } from "components/charts/Charts";
import { DateFiltersContext } from "contexts/DateFiltersContext";
import MetricChartContainer from "components/MetricChartContainer";
import useMetricsQuery from "hooks/v2/useMetricsQuery";
import MetricChartFormula from "components/charts/MetricChartFormula";

const COLORS = {
  bug: "#c0392b",
  pitch: "#9b59b6",
  tweak: "#1abc9c",
  chore: "#e67e22",
  story: "#3498db",
};

function tooltipFormatter(value, label, props) {
  const { payload } = props;

  return [
    `${value.toFixed(1)}% (${payload.delivered} of ${payload.total})`,
    label,
  ];
}

export default function ProjectsDeliveredOnTimeChart() {
  const { projectId, userId } = useParams();
  const { dateFilters } = useContext(DateFiltersContext);
  const { data: metrics, isLoading } = useMetricsQuery(
    "projects_delivered_on_time",
    {
      ...dateFilters,
      projectId,
      userId,
    },
  );
  const fetchedData = metrics?.data || [];
  const renderDescription = (
    <>
      <p className="pb-2">
        This chart shows{" "}
        <strong>
          how many projects (bugs, chores, tweaks or pitches) were delivered on
          time
        </strong>{" "}
        based on their scheduled end date and the time they were deployed.
      </p>
      <MetricChartFormula>
        {"countif(deployed_at <= scheduled_end_date) / total * 100%"}
      </MetricChartFormula>
    </>
  );

  const renderChart = (
    <BarChart data={fetchedData}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="label" />
      <YAxis ticks={[0, 20, 40, 60, 80, 100]} />
      <Tooltip cursor={{ fill: "transparent" }} formatter={tooltipFormatter} />
      <Legend wrapperStyle={{ position: "relative" }} />
      <Bar
        dataKey="percentage"
        name="Percentage"
        fill="#7f8c8d"
        background={{ fill: "rgba(236, 240, 241, 0.05)" }}
        maxBarSize={48}
      >
        {fetchedData.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={COLORS[entry.label.toLowerCase()]}
          />
        ))}
      </Bar>
    </BarChart>
  );

  return (
    <MetricChartContainer
      data={fetchedData}
      isLoading={isLoading}
      title="Projects Delivered on Time"
      description={renderDescription}
    >
      {renderChart}
    </MetricChartContainer>
  );
}
