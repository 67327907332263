import buildURL from "helpers/buildURL";
import fetchJSON from "helpers/fetchJSON";

const createRisk = body => {
  return fetchJSON(
    buildURL({
      path: `/api/risks`,
      filter: {},
    }),
    "POST",
    body,
  );
};

const updateRisk = body => {
  return fetchJSON(
    buildURL({
      path: `/api/risks/${body.id}`,
      filter: {},
    }),
    "PATCH",
    body,
  );
};

const deleteRisk = riskId => {
  return fetchJSON(
    buildURL({
      path: `/api/risks/${riskId}`,
      filter: {},
    }),
    "DELETE",
  );
};

function useRisksQuery() {
  return { createRisk, deleteRisk, updateRisk };
}

export default useRisksQuery;
