import React, { useContext } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Flex, Inline } from "@ableco/baseline";
import { DateFiltersContext } from "contexts/DateFiltersContext";
import { BarChart } from "components/charts/Charts";
import useMetricsQuery, {
  updateCodeReviewComment,
} from "hooks/v2/useMetricsQuery";
import MetricChartContainer from "components/MetricChartContainer";
import labelDate from "helpers/labelDate";
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import { TableLink } from "components/TableData";
import AccordionSection from "components/AccordionSection";
import TableWithPagination from "components/TableWithPagination";
import Rating from "components/Rating";
import { useMutation, useQueryClient } from "react-query";

const ratingValues = [
  { label: "✅", value: 1, title: "Approved" },
  { label: "👀", value: 2, title: "Reviewed" },
  { label: "👍", value: 3, title: "Good" },
  { label: "👍👍", value: 4, title: "Great" },
];

export default function CodeReviewsChart() {
  const { userId } = useParams();
  const { dateFilters } = useContext(DateFiltersContext);

  const { data: metrics, isLoading } = useMetricsQuery("code_reviews", {
    ...dateFilters,
    userId,
  });

  const dataChart = metrics?.data_chart || [];
  const data = metrics?.data || [];
  const allow_edition = metrics?.allow_edition || false;

  const queryClient = useQueryClient();
  const { mutateAsync: editCodeReviewComment } = useMutation(
    updateCodeReviewComment,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "/api/**/**/metrics/code_reviews",
          { ...dateFilters, userId },
        ]);
      },
    },
  );

  const detailsColumns = [
    {
      label: "PR Title",
      render: ({ item }) => {
        const value = {
          text: item.pr_title,
          url: item.pr_url,
        };
        return <TableLink value={value} isFullWidth />;
      },
    },
    {
      label: "PR Closed Date",
      render: ({ item }) => {
        return item.pr_closed;
      },
    },
    {
      label: "Status",
      render: ({ item }) => {
        return (
          <Rating
            arrayValues={ratingValues}
            status={item.status}
            commentStatus={item.status_comment}
            isEditable={allow_edition}
            onUpdateComment={body => {
              return editCodeReviewComment({ userId, reviewId: item.id, body });
            }}
          />
        );
      },
    },
  ];

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const resolution = query.get("resolution");
  const renderChart = (
    <BarChart data={dataChart}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="submitted_at"
        tickFormatter={value => labelDate(value, resolution)}
      />
      <YAxis />
      <Tooltip cursor={{ fill: "transparent" }} />
      <Legend wrapperStyle={{ position: "relative" }} />
      <Bar
        dataKey="approved"
        name="Approved"
        stackId="project"
        maxBarSize={48}
        fill="#3498db"
      />
      <Bar
        dataKey="commented"
        name="Commented"
        stackId="project"
        maxBarSize={48}
        fill="#1abc9c"
      />
    </BarChart>
  );

  const renderSummary = (
    <div className="summary">
      <Flex
        className="w-full"
        direction="horizontal"
        distribution="end"
        className="text-neutral-100"
      >
        <Inline className="px-4" space={2}>
          <span className="text-xs">
            Total:{" "}
            <span className="text-base text-green-400">
              {metrics?.total_review}
            </span>
          </span>
          <span className="text-xs">
            Approved:{" "}
            <span className="text-base text-green-400">
              {metrics?.total_approved}
            </span>
          </span>
          <span className="text-xs">
            Commented:{" "}
            <span className="text-base text-green-400">
              {metrics?.total_commented}
            </span>
          </span>
        </Inline>
      </Flex>
    </div>
  );

  const renderTableDetails = (
    <AccordionSection title="Details">
      <TableWithPagination columns={detailsColumns} data={data} />
    </AccordionSection>
  );

  return (
    <MetricChartContainer
      data={dataChart}
      isLoading={isLoading}
      title="Code Reviews"
    >
      {renderSummary}
      {renderChart}
      {renderTableDetails}
    </MetricChartContainer>
  );
}
