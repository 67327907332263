import React from "react";
import { Frame } from "@ableco/baseline";
import DateFiltersProvider from "contexts/DateFiltersContext";
import Filter from "components/Filter";
import PitchCycleTimeChart from "components/charts/PitchCycleTimeChart";
import PullRequestSizeChart from "components/charts/PullRequestSizeChart";
import PullRequestSizeByDateChart from "components/charts/PullRequestSizeByDateChart";
import ProjectsDeliveredOnTimeChart from "components/charts/ProjectsDeliveredOnTimeChart";
import ProjectDeliveryDeviationAverageChart from "components/charts/ProjectDeliveryDeviationAverageChart";
import ProjectThroughputChart from "components/charts/ProjectThroughputChart";
import CommitsChart from "components/charts/CommitsChart";
import CodingDaysPerWeekChart from "components/charts/CodingDaysPerWeekChart";
import BugsReportedChart from "components/charts/BugsReportedChart";
import IssuesBeforeDeploymentChart from "components/charts/IssuesBeforeDeploymentChart";
import CodeReviewsChart from "components/charts/CodeReviewsChart";

export default function ProfilePage() {
  return (
    <DateFiltersProvider>
      <Frame className="mb-10">
        <Filter />
      </Frame>
      <Frame className="mb-10">
        <PitchCycleTimeChart />
      </Frame>
      <Frame className="mb-10">
        <CodeReviewsChart />
      </Frame>
      <Frame className="mb-10">
        <ProjectsDeliveredOnTimeChart />
      </Frame>
      <Frame className="mb-10">
        <ProjectDeliveryDeviationAverageChart />
      </Frame>
      <Frame className="mb-10">
        <ProjectThroughputChart />
      </Frame>
      <Frame className="mb-10">
        <BugsReportedChart />
      </Frame>
      <Frame className="mb-10">
        <IssuesBeforeDeploymentChart />
      </Frame>
      <Frame className="mb-10">
        <PullRequestSizeChart />
      </Frame>
      <Frame className="mb-10">
        <PullRequestSizeByDateChart />
      </Frame>
      <Frame className="mb-10">
        <CommitsChart />
      </Frame>
      <Frame className="mb-10">
        <CodingDaysPerWeekChart />
      </Frame>
    </DateFiltersProvider>
  );
}
