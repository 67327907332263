import React from "react";
import { Frame } from "@ableco/baseline";
import PassThroughRateChart from "components/charts/recruitment/PassThroughRateChart";

export default function PassThroughRate() {
  return (
    <Frame className="mb-10">
      <PassThroughRateChart />
    </Frame>
  );
}
