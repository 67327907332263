import React from "react";
import { Flex } from "@ableco/baseline";
import FilterButton from "components/FilterButton";
import classNames from "classnames";

export default function CustomFilters({
  options,
  filterValue,
  onFilterChange,
}) {
  return (
    <Flex wrap>
      <Flex className="mr-5 mb-6">
        {options.map((item, index) => {
          const { label, value } = item;
          const filterItemClassName = classNames({
            "rounded-tl-md rounded-bl-md": index === 0,
            "rounded-tr-md rounded-br-md": index === options.length - 1,
          });
          return (
            <FilterButton
              key={index}
              className={filterItemClassName}
              filterItemClassName="bg-neutral-700"
              filterItemHoverClassName="hover:bg-neutral-800"
              active={filterValue === value}
              onClick={() => {
                onFilterChange(value);
              }}
            >
              {label}
            </FilterButton>
          );
        })}
      </Flex>
    </Flex>
  );
}
