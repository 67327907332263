import React from "react";
import ProjectRefetchButton from "../../dashboard/refetchButton/ProjectRefetchButton";
import RefetchData from "./RefetchData";

export default function RefetchProjectData({ projectId, title = "Project" }) {
  return (
    <RefetchData
      projectId={projectId}
      title={title}
      RefetchButton={ProjectRefetchButton}
    />
  );
}
