import { dateRange } from "./calendar";
import { format } from "date-fns";

export default function getQuarterParamsByDate(date) {
  let referenceDate = date;
  if (referenceDate.getFullYear() >= 2021 && referenceDate.getMonth() == 0) {
    referenceDate = new Date(referenceDate.getFullYear() - 1, 11, 31);
  }
  let [quarterFrom, quarterTo] = dateRange({
    resolution: "quarterly",
    date: referenceDate,
  });
  let quarterName = format(quarterFrom, "QQQ yyyy");
  if (quarterName === "Q2 2021" && date >= new Date(2021, 5, 13)) {
    quarterName = `Partial ${quarterName}`;
    quarterFrom = new Date(2021, 5, 14);
  }
  const isQ4 = format(referenceDate, "QQQ") === "Q4";
  const quarterFromName = format(quarterFrom, `MMM dd${isQ4 ? " yyyy" : ""}`);
  const quarterToName = format(quarterTo, `MMM dd${isQ4 ? " yyyy" : ""}`);
  return [
    quarterFrom,
    quarterTo,
    `${quarterName}: ${quarterFromName} - ${quarterToName}`,
  ];
}
