import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import fetchJSON from "helpers/fetchJSON";

function fetchProject(id) {
  return fetchJSON(`/api/projects/${id}`);
}

function useProject(projId = null) {
  const { projectId } = useParams();
  const param = projectId || projId;
  const response = useQuery(`projects/${param}`, () => fetchProject(param));
  const isLoading = response.isLoading;
  const project = isLoading ? {} : response.data.data.attributes;

  return { data: project, isLoading };
}

function useProjectStatus() {
  const { projectId } = useParams();
  const data = useQuery(`project-status/${projectId}`, () =>
    fetchProject(projectId),
  );

  return data;
}

export { useProject, useProjectStatus };
