import React, { useState } from "react";
import ProjectLogo from "components/ProjectLogo";
import Toggle from "components/Toggle";
import LabelValue from "components/LabelValue";
import ProjectForm from "components/ProjectForm";
import ConfirmationModal from "components/ConfirmationModal";
import StoryTrackersList from "./StoryTrackersList";
import PullRequestTrackersList from "./PullRequestTrackersList";
import EditIcon from "icons/edit.svg";
import projectQueries from "queries/projectQueries";
import { Link } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";

export default function ProjectSettingsCard({ project }) {
  const [isProjectFormOpen, setProjectFormOpen] = useState(false);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const {
    name,
    story_trackers,
    pull_request_trackers,
    id: projectId,
  } = project;

  const queryClient = useQueryClient();
  const { editProject } = projectQueries();

  const { mutateAsync: updateProject } = useMutation(editProject, {
    onSuccess: () => queryClient.invalidateQueries("projects"),
    onError: error => alert(error),
  });

  const handleShowStatus = value => {
    const payload = {
      id: project.id,
      show_status: value,
    };

    updateProject(payload);
  };

  const handleArchived = value => {
    const payload = {
      id: project.id,
      archived: value,
    };

    updateProject(payload);
  };

  return (
    <div className="flex p-4 mb-4 rounded-lg border border-gray-500 space-x-2">
      <div className="w-1/5 flex flex-col bg-nord-background rounded-lg p-4">
        <div className="flex space-x-2 mb-4 items-center">
          <ProjectLogo project={project} size="SM" />
          <h1 className="flex-1 text-xl font-bold text-white mx-3">{name}</h1>
          <EditIcon
            className="text-neutral-200 fill-current flex-shrink-0 cursor-pointer"
            onClick={() => setProjectFormOpen(true)}
          />
        </div>
        <Toggle
          wrapperClassName="mb-2 text-sm justify-between"
          prompt="Show/Hide"
          defaultValue={project?.show_status}
          onChange={handleShowStatus}
          disabled={project?.archived}
        />
        <Toggle
          wrapperClassName="text-sm justify-between"
          prompt="Archived project?"
          defaultValue={project?.archived}
          onChange={value => {
            if (value && project?.show_status) setConfirmationModalOpen(true);
            else handleArchived(value);
          }}
        />
        <div className="flex justify-between">
          <LabelValue
            label="Core Product ID"
            value={project?.core_product_id}
            wrapperClassName="my-2"
          />
          <LabelValue
            label="Core Project ID"
            value={project?.core_project_id}
            wrapperClassName="my-2"
          />
        </div>
        <Link
          to={`/project/${project.id}/settings`}
          className="text-primary-base text-sm mt-auto"
        >
          More settings
        </Link>
      </div>

      <div className="flex-1">
        <StoryTrackersList
          trackers={story_trackers}
          projectId={projectId.toString()}
        />
        <PullRequestTrackersList
          trackers={pull_request_trackers}
          projectId={projectId.toString()}
        />
      </div>

      <ProjectForm
        project={project}
        open={isProjectFormOpen}
        setOpen={setProjectFormOpen}
        isEditing
      />

      <ConfirmationModal
        title="Confirm Archive Action"
        prompt={
          <>
            <p>
              If you archive this project, it won't be visible in the Project
              Status anymore.
            </p>
            <p>Are you sure you want to continue?</p>
          </>
        }
        open={isConfirmationModalOpen}
        setOpen={setConfirmationModalOpen}
        onConfirm={() => {
          handleShowStatus(false);
          handleArchived(true);
        }}
        buttonName="Continue"
      />
    </div>
  );
}
