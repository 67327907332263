import React, { Fragment } from "react";
import ReactTooltip from "react-tooltip";
import { dateFormatter, formatDate, startWeekFormat } from "./SnashotCommons";
import { groupBy } from "lodash";

export default function Ptos({ onlyPto, week, isProject = false }) {
  const ptosInWeek = onlyPto?.some(el => {
    let ptoWeek = startWeekFormat(new Date(`${el.data.started_date} 00:00`), {
      weekStartsOn: 1,
    });
    let stringifyWeek = formatDate(week);
    return ptoWeek == stringifyWeek;
  });

  if (!ptosInWeek) return "";

  const filteredPtos = onlyPto.filter(el => {
    let ptoWeek = startWeekFormat(new Date(`${el.data.started_date} 00:00`), {
      weekStartsOn: 1,
    });
    let stringifyWeek = formatDate(week);
    return ptoWeek == stringifyWeek;
  });
  const groupedPtos = groupBy(filteredPtos, el => el.data.name);

  const renderUserPtos = !isProject
    ? filteredPtos.map((pto, index) => (
        <Fragment key={index}>
          <span data-tip data-for={`${pto.data.id}`}>
            {pto.data.emoji}
          </span>
          <ReactTooltip id={`${pto.data.id}`} backgroundColor="#ffffff">
            <span className="text-black">{`${pto.data.emoji} ${dateFormatter(
              pto.data.started_date,
            )}${
              pto.data.started_date != pto.data.completed_date
                ? ` → ${dateFormatter(pto.data.completed_date)}`
                : ""
            }`}</span>
          </ReactTooltip>
        </Fragment>
      ))
    : null;

  const renderProjectPtos = isProject
    ? Object.keys(groupedPtos).map(key => {
        const items = groupedPtos[key];
        const firstItem = items[0];
        return (
          <Fragment key={key}>
            <span data-tip data-for={`${firstItem.data.id}`}>
              {firstItem.data.emoji}
            </span>
            <ReactTooltip id={`${firstItem.data.id}`} backgroundColor="#ffffff">
              {items.map(pto => (
                <p className="text-black" key={pto.data.id}>{`${
                  pto.data.emoji
                } ${pto.data.user.name} : ${dateFormatter(
                  pto.data.started_date,
                )}${
                  pto.data.started_date != pto.data.completed_date
                    ? ` → ${dateFormatter(pto.data.completed_date)}`
                    : ""
                }`}</p>
              ))}
            </ReactTooltip>
          </Fragment>
        );
      })
    : null;

  return (
    <span className="absolute text-xl -top-2.5 -right-1">
      {isProject ? <>{renderProjectPtos}</> : <>{renderUserPtos}</>}
    </span>
  );
}
