import React from "react";
import Button from "components/FilterButton";

export default function FormFooter({ onCancel, onSubmit, disabled }) {
  return (
    <div className="flex justify-between">
      <Button className="h-10 rounded-md w-full sm:w-auto" onClick={onCancel}>
        Cancel
      </Button>
      <Button
        disabled={disabled}
        className="h-10 rounded-md w-full sm:w-auto"
        active={!disabled}
        onClick={onSubmit}
      >
        Save
      </Button>
    </div>
  );
}
