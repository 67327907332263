import React, { useState, useEffect } from "react";
import Modal from "components/Modal";
import ListboxPicker from "components/ListboxPicker";
import Checkbox from "components/Checkbox";
import FormLabel from "components/FormLabel";
import FormFooter from "components/FormFooter";
import projectQueries from "queries/projectQueries";
import { METRICS_TYPES } from "helpers/constants";
import { useMutation, useQueryClient } from "react-query";
import { isEmpty } from "lodash";

export default function MetricForm({
  open,
  setOpen,
  projectId,
  isEditing = false,
  project,
  metric,
}) {
  const [metricType, setMetricType] = useState(METRICS_TYPES[0]);
  const [greenValue, setGreenValue] = useState("");
  const [yellowValue, setYellowValue] = useState("");
  const [redValue, setRedValue] = useState("");
  const [isStatusVisible, setIsStatusVisible] = useState(true);
  const [isPercentageValue, setIsPercentageValue] = useState(false);
  const [isValueValid, setIsValidValue] = useState(true);
  const [isThresholdAscendant, setThresholdAscendant] = useState(true);
  const [isTresholdAvailable, setIsThresholdAvailable] = useState(true);

  const queryClient = useQueryClient();
  const { editProject } = projectQueries();

  const { mutateAsync: updateProject } = useMutation(editProject, {
    onSuccess: () => queryClient.invalidateQueries(`projects/${projectId}`),
  });

  useEffect(() => {
    if (!isEmpty(greenValue) && !isEmpty(yellowValue)) {
      if (isThresholdAscendant)
        setIsValidValue(parseInt(greenValue) > parseInt(yellowValue));
      else setIsValidValue(parseInt(greenValue) < parseInt(yellowValue));
    }
  }, [greenValue, yellowValue, isThresholdAscendant]);

  useEffect(() => {
    if (!isTresholdAvailable) {
      setGreenValue("");
      setYellowValue("");
      setRedValue("");
      setIsPercentageValue(false);
      setIsValidValue(true);
    }
  }, [isTresholdAvailable]);

  useEffect(() => {
    if (isEditing) {
      const {
        green_threshold,
        yellow_threshold,
        red_threshold,
        is_visible,
        is_percentage,
      } = project.status_metrics[metric];
      setGreenValue(green_threshold);
      setYellowValue(yellow_threshold);
      setRedValue(red_threshold);
      setIsStatusVisible(is_visible);
      setIsPercentageValue(is_percentage);
      setThresholdAscendant(
        parseInt(green_threshold) > parseInt(yellow_threshold),
      );
      setMetricType(METRICS_TYPES.find(t => t.value === metric));
    }
  }, [project, metric, isEditing]);

  const handleOpenForm = isOpen => {
    setOpen(isOpen);

    if (!isOpen && !isEditing) {
      setGreenValue("");
      setYellowValue("");
      setRedValue("");
      setIsValidValue(true);
      setIsStatusVisible(false);
      setIsPercentageValue(false);
      setIsThresholdAvailable(true);
    }
  };

  const handleSubmit = () => {
    project.status_metrics[metricType.value] = {
      is_visible: isStatusVisible,
      is_percentage: isPercentageValue,
      green_threshold: greenValue,
      yellow_threshold: yellowValue,
      red_threshold: redValue,
    };

    const payload = {
      id: project.id,
      status_metrics: { ...project.status_metrics },
    };

    updateProject(payload).then(() => {
      handleOpenForm(false);
    });
  };

  const formContent = (
    <div>
      <FormLabel required>Metric</FormLabel>
      <ListboxPicker
        WrapperClassName="mb-2"
        disabled={isEditing}
        onSelect={setMetricType}
        items={METRICS_TYPES}
        preSelectedOption={metricType}
      />

      <FormLabel required={isTresholdAvailable}>Thresholds</FormLabel>
      <Checkbox
        checked={!isTresholdAvailable}
        prompt={
          <span className="text-sm text-primary-light">
            Don't set Thresholds
          </span>
        }
        onChange={({ target }) => setIsThresholdAvailable(!target.checked)}
      />

      <div className="flex items-center my-2">
        <p className="text-neutral-200 w-3/4">
          <span className="text-neutral-100">Green: </span>Value{" ("}
          <input
            type="radio"
            value="greater"
            name="greenThreshold"
            checked={isThresholdAscendant}
            onChange={() => setThresholdAscendant(true)}
          />{" "}
          greater{" "}
          <input
            type="radio"
            value="less"
            name="greenThreshold"
            checked={!isThresholdAscendant}
            onChange={() => setThresholdAscendant(false)}
          />{" "}
          less) than or equal to
        </p>
        <input
          type="number"
          disabled={!isTresholdAvailable}
          className="focus:outline-none rounded-md p-2 w-1/4"
          onChange={({ target }) => setGreenValue(target.value)}
          value={greenValue}
        />
      </div>
      <div className="mb-2">
        <div className="flex items-center">
          <p className="text-neutral-200 w-3/4">
            <span className="text-neutral-100">Yellow: </span>Value{" "}
            {isThresholdAscendant ? "greater" : "less"} than or equal to
          </p>
          <input
            type="number"
            disabled={!isTresholdAvailable}
            className="focus:outline-none rounded-md p-2 w-1/4"
            onChange={({ target }) => {
              setYellowValue(target.value);
              setRedValue(target.value);
            }}
            value={yellowValue}
          />
        </div>
        {!isValueValid && (
          <p className="text-alert-base text-sm text-right">
            {isThresholdAscendant
              ? "Value cannot be greater than or equal to Green Threshold"
              : "Value cannot be less than or equal to Green Threshold"}
          </p>
        )}
      </div>
      <div className="flex items-center mb-2">
        <p className="text-neutral-200 w-3/4">
          <span className="text-neutral-100">Red: </span>Value{" "}
          {isThresholdAscendant ? "less" : "greater"} than
        </p>
        <input
          type="number"
          disabled
          className="focus:outline-none rounded-md p-2 w-1/4"
          value={redValue}
        />
      </div>

      <div className="flex flex-col space-y-2 mt-4">
        <Checkbox
          checked={isStatusVisible}
          prompt={
            <span className="text-sm text-primary-light">
              Use this Metric in the Project Status page?
            </span>
          }
          onChange={({ target }) => setIsStatusVisible(target.checked)}
        />
        <Checkbox
          disabled={!isTresholdAvailable}
          checked={isPercentageValue}
          prompt={
            <span className="text-sm text-primary-light">
              Are the Thresholds set in % values?
            </span>
          }
          onChange={({ target }) => setIsPercentageValue(target.checked)}
        />
      </div>
    </div>
  );

  return (
    <Modal
      title={`${isEditing ? "Edit" : "Add"} Project Metric`}
      subtitle={`Fill out the form to ${
        isEditing ? "edit" : "add a new"
      } Project Metric`}
      content={formContent}
      footer={
        <FormFooter
          onCancel={() => handleOpenForm(false)}
          onSubmit={handleSubmit}
          disabled={
            isTresholdAvailable &&
            (isEmpty(greenValue) ||
              isEmpty(yellowValue) ||
              isEmpty(redValue) ||
              !isValueValid)
          }
        />
      }
      open={open}
      setOpen={handleOpenForm}
    />
  );
}
