import React from "react";
import BambooRefetchButton from "../../dashboard/refetchButton/BambooRefetchButton";
import RefetchData from "./RefetchData";

export default function RefetchBambootData({ projectId = null }) {
  return (
    <RefetchData
      projectId={projectId}
      title="Bamboo"
      RefetchButton={BambooRefetchButton}
    />
  );
}
