import React, { createContext } from "react";
import { useQuery } from "react-query";

const StatusesContext = createContext();

async function fetchStatuses() {
  const response = await fetch("/api/statuses");

  return await response.json();
}

function StatusesProvider({ children }) {
  const query = useQuery("statuses", fetchStatuses);

  const isLoadingStatuses = query.isLoading;
  const statuses = query?.data || [];

  return (
    <StatusesContext.Provider value={{ isLoadingStatuses, statuses }}>
      {children}
    </StatusesContext.Provider>
  );
}

export { StatusesContext };
export default StatusesProvider;
