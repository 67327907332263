import { useQuery } from "react-query";
import { format } from "date-fns";

async function fetchProjectSnapshot(projectId, from, to) {
  const response = await fetch(
    `/api/projects/${projectId}/snapshots?from=${from}&to=${to}`,
  );
  return await response.json();
}

function useProjectSnapshot(projectId, from, to) {
  return useQuery([`projectSnapshot/${projectId}`, { from, to }], () =>
    fetchProjectSnapshot(
      projectId,
      format(from, "yyyy-MM-dd"),
      format(to, "yyyy-MM-dd"),
    ),
  );
}

export { useProjectSnapshot };
