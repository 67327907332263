import React, { useEffect, useState } from "react";
import DashBoardSection from "./DashBoardSection";
import ProjectLogo from "components/ProjectLogo";
import LoadingData from "components/LoadingData";
import IndicatorIcon from "icons/circle.svg";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

async function fetchProjectsOverview() {
  const response = await fetch("/api/projects_overview");
  return await response.json();
}

function ListElement({ title, val }) {
  return (
    <li className="flex flex-wrap justify-between text-neutral-100 text-sm">
      <span className="flex flex-wrap items-center gap-1 ">{title}</span>
      <span data-testid="val">{val}</span>
    </li>
  );
}

function DashboardOverviewCard({ project }) {
  return (
    <div className="rounded-lg bg-neutral-800 p-3 w-96">
      <div className="flex flex-wrap  items-center justify-between text-neutral-100 font-medium p-1 border-b border-neutral-700">
        <div className="flex flex-wrap items-center text-neutral-100 text-base font-medium">
          <ProjectLogo project={project} size="SM" />
          <Link to={`/project/${project.id}/status`}>
            <span className="ml-2 text-lg">{project.name}</span>
          </Link>
        </div>
        <div className="flex flex-wrap gap-1 items-center">
          <span className="text-xs font-normal">Status</span>
          <IndicatorIcon
            className={`text-indicator-${project.overview.current_status?.toLowerCase() ||
              "green"} fill-current w-3 h-3`}
            data-testid="status-icon"
          />
        </div>
      </div>
      <div className="p-1 mt-2.5">
        <ul className="flex flex-col gap-2.5">
          <ListElement
            title="Capacity (PTO)"
            val={`${project.overview.capacity} (${project.overview.ptos})`}
          />
          <ListElement
            title="Pitches Overdue"
            val={`${project.overview.pitches_overdue} / ${project.overview.current_pitches}`}
          />

          <ListElement
            title="Other Projects Overdue"
            val={`${project.overview.other_projects_overdue} / ${project.overview.other_projects_overdue_total}`}
          />

          <ListElement
            title="Pitches Deviation"
            val={`${
              project.overview.pitches_deviation
                ? project.overview.pitches_deviation
                : "--"
            }%`}
          />
        </ul>
      </div>
    </div>
  );
}

export default function OverviewDashboard() {
  const [activeProjects, setActiveProjects] = useState([]);
  const { data: projects, isLoading: isLoading } = useQuery(
    "projects_overview",
    fetchProjectsOverview,
  );

  useEffect(() => {
    projects &&
      setActiveProjects(
        projects.data.map(prj => prj.attributes).filter(prj => prj.show_status),
      );
  }, [projects]);

  return (
    <div className="px-8 flex-1 overflow-y-auto">
      <DashBoardSection>
        {!isLoading ? (
          <div className="flex flex-wrap gap-4">
            {activeProjects.map(project => (
              <DashboardOverviewCard project={project} key={project.id} />
            ))}
          </div>
        ) : (
          <LoadingData />
        )}
      </DashBoardSection>
    </div>
  );
}
