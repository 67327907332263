import React from "react";
import { useParams } from "react-router-dom";
import Dashboard from "components/dashboard/Dashboard";
import DateFiltersProvider from "contexts/DateFiltersContext";
import StatusesProvider from "contexts/StatusesContext";

export default function ProjectStatusPage() {
  const { projectId } = useParams();

  return (
    <DateFiltersProvider>
      <StatusesProvider>
        <Dashboard projectId={projectId} />
      </StatusesProvider>
    </DateFiltersProvider>
  );
}
