import React from "react";
import { useUserQuery } from "../../../queries/icSnapshotQueries";
import LoadingData from "../../snapshot/Loading";
import UserCard from "components/UserCard";

export default function UserHeader({ userId, title }) {
  const { data: user, isLoading: isLoadingUser } = useUserQuery(userId);

  return (
    <>
      {!isLoadingUser ? (
        <div className="flex justify-start items-center space-x-2">
          <UserCard user={user} size="SM" />
          <div className="text-2xl font-bold text-white mx-3">{` - ${title}`}</div>
        </div>
      ) : (
        <LoadingData />
      )}
    </>
  );
}
