import React, { useContext, useEffect, useState } from "react";
import DashboardCard from "./DashboardCard";
import DashboardCardTitle from "./DashboardCardTitle";
import DashboardHeader from "./dashboardHeader/DashboardHeader";
import DashBoardSection from "./DashBoardSection";
import CommitmentsList from "./CommitmentsList";
import RisksList from "./RisksList";
import CommitmentForm from "./CommitmentForm";
import RiskForm from "./RiskForm";
import { useProject } from "hooks/useProject";
import { DateFiltersContext } from "contexts/DateFiltersContext";
import useResourcesQuery from "queries/resourcesQueries";
import getQuarterParamsByDate from "helpers/getQuarterParamsByDate";
import Filter from "components/Filter";

export default function Dashboard({ projectId }) {
  const [isCommitmentFormOpen, setIsCommitmentFormOpen] = useState(false);
  const [isRiskFormOpen, setIsRiskFormOpen] = useState(false);
  const [commitmentType, setCommitmentType] = useState("");
  const { changeResolution, dateFilters } = useContext(DateFiltersContext);

  useEffect(() => {
    changeResolution("weekly");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: project } = useProject();
  const { data: risks } = useResourcesQuery("risks", {
    ...dateFilters,
    projectId,
  });
  const { data: status } = useResourcesQuery("project_status", {
    ...dateFilters,
    projectId,
  });

  const { data: commitments } = useResourcesQuery("commitments", {
    ...dateFilters,
    projectId,
  });

  const [quarterFrom, quarterTo, kpiRangeLabel] = getQuarterParamsByDate(
    dateFilters.from,
  );

  const { data: stats } = useResourcesQuery("stats", {
    ...dateFilters,
    projectId,
    from: quarterFrom,
    to: quarterTo,
  });

  const statValues = stats?.data;

  const fetchedRisks = risks?.data || [];
  const fetchedStatus = status || [];
  const deployments = commitments?.data.completed || [];
  const currentCommitments = commitments?.data.current_commitments || [];
  const upcomingCommitments = commitments?.data.upcoming_commitments || [];

  const handleAddCommitment = type => {
    setCommitmentType(type);
    setIsCommitmentFormOpen(true);
  };

  const renderBody = project ? (
    <>
      <DashboardHeader
        project={project}
        statValues={statValues}
        status={fetchedStatus[0]?.name.toLowerCase() || ""}
        kpiRangeLabel={kpiRangeLabel}
        projectId={projectId}
      />

      <div className="pb-4">
        <DashBoardSection
          title="Weekly Status"
          titleComponent={<Filter showResolutions={false} />}
        >
          <div className="flex space-x-4">
            <DashboardCard
              title="🚀 Last Week Deployments"
              showAddIcon={true}
              onClick={() => handleAddCommitment("deployment")}
            >
              <CommitmentsList
                items={deployments}
                emptyMessage="No deployments found for last week."
                projectId={projectId}
                dateFilters={dateFilters}
                dataTestId="deploymentsList"
              />
            </DashboardCard>
            <DashboardCard
              title="🤝 Current Week Commitments"
              showAddIcon={true}
              onClick={() => handleAddCommitment("current")}
            >
              <div className="mb-5">
                <CommitmentsList
                  items={currentCommitments}
                  emptyMessage="No commitments set for this week."
                  projectId={projectId}
                  dateFilters={dateFilters}
                  dataTestId="currentCommitmentsList"
                />
              </div>
              <DashboardCardTitle title="🗓 Upcoming Weeks Commitments" />
              <CommitmentsList
                items={upcomingCommitments}
                emptyMessage="No upcoming weeks commitments found."
                dataTestId="upcommingCommitmentsList"
              />
            </DashboardCard>
            <DashboardCard
              title="Risks"
              showAddIcon={true}
              onClick={() => setIsRiskFormOpen(true)}
              expandable={true}
              showAlertIcon={fetchedRisks.length != 0}
              alertIconTooltip="Registered Risks"
            >
              <RisksList
                items={fetchedRisks}
                projectId={projectId}
                dateFilters={dateFilters}
              />
            </DashboardCard>
            <CommitmentForm
              open={isCommitmentFormOpen}
              setOpen={setIsCommitmentFormOpen}
              commitmentType={commitmentType}
              projectId={projectId}
              dateFilters={dateFilters}
            />
            <RiskForm
              open={isRiskFormOpen}
              setOpen={setIsRiskFormOpen}
              projectId={projectId}
              dateFilters={dateFilters}
            />
            ;
          </div>
        </DashBoardSection>
      </div>
    </>
  ) : null;
  return <main className="flex-1">{renderBody}</main>;
}
