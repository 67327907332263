import React from "react";
import Downshift from "downshift";
import { Stack, Inline, noop } from "@ableco/baseline";
import Scrollable from "components/Scrollable";
import classNames from "classnames";

const DEFAULT_DROPDOWN_WIDTH = "186px";

const DropdownMenu = ({
  onSelect,
  children,
  maxHeight,
  items,
  width = DEFAULT_DROPDOWN_WIDTH,
  showTip = false,
  topLeftOrientation = false,
  displayTop = false,
  displayRight = false,
  header = null,
  extraClassName,
  textClassName,
}) => {
  const menuClassName = classNames(
    "bg-white px-1 py-3 shadow-dropdown",
    extraClassName,
  );
  const colorText = textClassName ? textClassName : "text-black";
  return (
    <Downshift
      onSelect={onSelect ? item => onSelect(item) : noop}
      itemToString={item => (item ? item.label : null)}
    >
      {({
        isOpen,
        toggleMenu,
        getItemProps,
        getMenuProps,
        openMenu,
        closeMenu,
      }) => (
        <div className="relative">
          {React.cloneElement(children, {
            isOpen: isOpen,
            toggleMenu: toggleMenu,
            openMenu: openMenu,
            closeMenu: closeMenu,
          })}
          {isOpen && items.length > 0 && (
            <div
              className="absolute z-40 rounded border border-black"
              style={{
                top: displayRight ? "-8px" : !displayTop && "calc(100% + 16px)",
                bottom: displayTop && "55px",
                width,
                right: displayRight ? "-280px" : !displayTop && "-4px",
              }}
            >
              {header}
              {showTip && (
                <div
                  className={classNames(
                    "absolute h-3 overflow-hidden pt-4 px-4",
                    topLeftOrientation ? "p-4" : "",
                  )}
                  style={{
                    left: topLeftOrientation && "-32px",
                    right: !topLeftOrientation && "18px",
                    top: topLeftOrientation ? "4px" : "-16px",
                  }}
                >
                  <div
                    className={classNames(
                      "absolute w-4 h-4 bg-white shadow-dropdown",
                      extraClassName,
                    )}
                    style={{
                      top: "14px",
                      left: !topLeftOrientation && "50%",
                      right: topLeftOrientation && "-18px",
                      transform: "rotate(45deg) translate(-50%, 0)",
                    }}
                  />
                </div>
              )}
              <Scrollable
                as="ul"
                maxHeight={maxHeight}
                className={menuClassName}
                {...getMenuProps({
                  refKey: "innerRef",
                })}
              >
                {items.map((item, index) =>
                  item.label ? (
                    <li
                      key={index}
                      className="px-2 hover:bg-neutral-700 rounded-md w-full"
                    >
                      <Stack
                        as={onSelect ? "button" : "div"}
                        className={[
                          "text-left py-2  w-full font-medium pl-2",
                          item.active ? "text-purple-base" : colorText,
                          item.color == "alert"
                            ? "hover:text-alert-base"
                            : "hover:text-purple-base",
                        ]}
                        style={{ fontSize: "16px" }}
                        disabled={item.disabled}
                        {...getItemProps({ item, key: index })}
                      >
                        <Inline>
                          {item.icon}
                          {item.label}
                        </Inline>
                      </Stack>
                    </li>
                  ) : null,
                )}
              </Scrollable>
            </div>
          )}
        </div>
      )}
    </Downshift>
  );
};

export default DropdownMenu;
