import React from "react";
import { Frame } from "@ableco/baseline";
import PipelinePerJobChart from "components/charts/recruitment/PipelinePerJobChart";

export default function PipelinePerJob() {
  return (
    <Frame className="mb-10">
      <PipelinePerJobChart />
    </Frame>
  );
}
