import React, { useMemo, useState } from "react";
import { BarChart } from "components/charts/Charts";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
} from "recharts";
import MetricChartContainer from "components/MetricChartContainer";
import CustomFilters from "components/CustomFilters";
import JobMappingSelector from "./JobMappingSelector";
import PipelineDetailsTable from "./PipelineDetailsTable";
import { renderCustomBarLabel } from "helpers/customBarLabel";
import { Inline } from "@ableco/baseline";
import { useMetricsQuery } from "hooks/useMetricsQuery";
import { uniq, sortBy, indexOf } from "lodash";
import {
  BAR_COLORS,
  RECRUITMENT_STAGES_ORDER,
  RECRUITMENT_LOCATIONS,
} from "helpers/constants";

export default function PipelinePerJobChart({ minimal = false }) {
  const [locationFilter, setLocationFilter] = useState("all");
  const [jobIds, setJobsIds] = useState("");

  const handleFilterChange = by => {
    setLocationFilter(by);
  };

  const { data: metrics, isLoading } = useMetricsQuery(
    "recruitment/current_pipeline",
    {
      by: locationFilter,
      job_ids: jobIds,
    },
  );
  const fetchedData = metrics?.data || [];
  const detaildData = metrics?.detail || [];
  const metaData = metrics?.meta || {};

  const sortedData = useMemo(
    () =>
      sortBy(fetchedData, item =>
        indexOf(RECRUITMENT_STAGES_ORDER, item.category),
      ),
    [fetchedData],
  );

  const barItems = sortedData.reduce(
    (acc, current) =>
      uniq([
        ...acc,
        ...Object.keys(current).filter(
          key => !["total", "category"].includes(key),
        ),
      ]),
    [],
  );

  const renderChart = (
    <BarChart layout="vertical" data={sortedData} height={minimal && 280}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis type="number" tickCount="6" />
      <YAxis dataKey="category" type="category" width={minimal ? 0 : 200} />
      <Tooltip cursor={{ fill: "transparent" }} />
      {!minimal && <Legend />}
      {barItems.map((barItem, index) => (
        <Bar
          key={index}
          dataKey={barItem}
          name={barItem}
          stackId="group"
          fill={BAR_COLORS[index]}
          barSize={20}
        >
          {index === barItems.length - 1 && (
            <LabelList
              dataKey="total"
              horizontal
              content={renderCustomBarLabel}
            />
          )}
        </Bar>
      ))}
    </BarChart>
  );

  return (
    <MetricChartContainer
      data={sortedData}
      isLoading={isLoading}
      title={!minimal && "Current Pipeline per Job"}
      subtitle={
        metaData.last_fetched_date && (
          <span className="text-neutral-200 text-sm font-medium text-center">{`Processed ${metaData.last_fetched_date}`}</span>
        )
      }
      minimal={minimal}
      filterComponent={
        <Inline>
          <CustomFilters
            options={RECRUITMENT_LOCATIONS}
            filterValue={locationFilter}
            onFilterChange={handleFilterChange}
          />
          <JobMappingSelector onChangeJobIds={setJobsIds} />
        </Inline>
      }
    >
      {renderChart}
      <PipelineDetailsTable data={detaildData} />
    </MetricChartContainer>
  );
}
