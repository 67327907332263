import React from "react";
import { Frame } from "@ableco/baseline";
import DateFiltersProvider from "contexts/DateFiltersContext";
import Filter from "components/Filter";
import PipelineStageUpdatesChart from "components/charts/recruitment/PipelineStageUpdatesChart";

export default function PipelineStageUpdates() {
  return (
    <DateFiltersProvider>
      <Filter filters="wm" defaultResolution="weekly" />
      <Frame className="mb-10">
        <PipelineStageUpdatesChart />
      </Frame>
    </DateFiltersProvider>
  );
}
