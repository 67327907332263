import buildURL from "helpers/buildURL";
import fetchJSON from "helpers/fetchJSON";

const createStoryTracker = body => {
  return fetchJSON(
    buildURL({
      path: `/api/story_trackers`,
      filter: {},
    }),
    "POST",
    body,
  );
};

const editStoryTracker = body => {
  return fetchJSON(
    buildURL({
      path: `/api/story_trackers/${body.id}`,
      filter: {},
    }),
    "PATCH",
    body,
  );
};

const deleteStoryTracker = trackerId => {
  return fetchJSON(
    buildURL({
      path: `/api/story_trackers/${trackerId}`,
      filter: {},
    }),
    "DELETE",
  );
};

function useStoryTrackersQuery() {
  return {
    createStoryTracker,
    deleteStoryTracker,
    editStoryTracker,
  };
}

export default useStoryTrackersQuery;
