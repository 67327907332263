import React from "react";
import RefetchBambootData from "./refetchData/RefetchBambooData";
import RefetchCoreData from "./refetchData/RefetchCoreData";
import RefetchGreenhouseData from "./refetchData/RefetchGreenhouseData";
import RefetchProjectData from "./refetchData/RefetchProjectData";
import RefetchPullRequestsData from "./refetchData/RefetchPullRequestData";
import RefetchUncaughtExceptionsData from "./refetchData/RefetchUncaughtExceptionsData";

export default function DataFetching() {
  return (
    <div className="p-8">
      <div className="rounded-lg border border-gray-500 p-4 mb-4 space-y-3">
        <RefetchProjectData />
        <RefetchProjectData title="Pivotal" projectId={11} />
        <RefetchBambootData />
        <RefetchGreenhouseData />
        <RefetchUncaughtExceptionsData />
        <RefetchPullRequestsData />
        <RefetchCoreData />
      </div>
    </div>
  );
}
