import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { ComposedChart } from "components/charts/Charts";
import {
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { DateFiltersContext } from "contexts/DateFiltersContext";
import MetricChartContainer from "components/MetricChartContainer";
import LinkableAxisTick from "components/charts/LinkableAxisTick";
import AccordionSection from "components/AccordionSection";
import TableWithPagination from "components/TableWithPagination";
import { TableLink } from "components/TableData";
import { useMetricsQuery } from "hooks/useMetricsQuery";

const detailsColumns = [
  {
    label: "Pitch",
    render: ({ item }) => {
      const value = {
        text: item.label,
        url: item.url,
      };
      return <TableLink value={value} />;
    },
  },
  {
    label: "Scheduled Time",
    name: "scheduled_time",
  },
  { label: "Actual Time", name: "total_time" },
  { label: "Deviation (%)", name: "deviation_percentage" },
];

function tooltipFormatter(value, label) {
  const returnValue = `${value < 0 ? 0 : value} days`;
  return [returnValue, label];
}

export default function PitchDefinitionTimeChart() {
  const { projectId, userId } = useParams();
  const { dateFilters } = useContext(DateFiltersContext);
  const { data: metrics, isLoading } = useMetricsQuery(
    "pitch_definition_time",
    {
      ...dateFilters,
      projectId,
      userId,
    },
  );
  const fetchedData = metrics?.data || [];

  const renderDescription = (
    <>
      <p>
        This chart shows{" "}
        <strong>
          the time invested in the definition phase for each pitch.
        </strong>
      </p>{" "}
      <p className="pt-2">
        It also shows a line chart to represent the scheduled end time,{" "}
        <strong>
          if a line chart is above a bar it means that a pitch's development
          phase related was delivered ahead of time.
        </strong>
      </p>
      <p className="pt-2">
        <strong>Note:</strong> All calculations are made based on bussiness
        days.
      </p>
    </>
  );

  const renderChart = (
    <ComposedChart data={fetchedData}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="label" tick={<LinkableAxisTick data={fetchedData} />} />
      <YAxis />
      <Tooltip cursor={{ fill: "transparent" }} formatter={tooltipFormatter} />
      <Legend wrapperStyle={{ position: "relative" }} />
      <Bar
        dataKey="definition"
        name="Definition"
        fill="#3498db"
        maxBarSize={48}
      />
      <Line
        dataKey="scheduled_time"
        name="Scheduled Time"
        type="monotone"
        stroke="#c0392b"
        strokeWidth={3}
      />
    </ComposedChart>
  );

  const renderTableDetails = (
    <AccordionSection title="Details">
      <TableWithPagination columns={detailsColumns} data={fetchedData} />
    </AccordionSection>
  );

  return (
    <MetricChartContainer
      data={fetchedData}
      isLoading={isLoading}
      title="Pitch Definition Time"
      description={renderDescription}
    >
      {renderChart}
      {renderTableDetails}
    </MetricChartContainer>
  );
}
