import React from "react";
import { Link } from "react-router-dom";

const tailwindToHex = {
  green: {
    base: "#008000",
    dark: "#066106",
  },
  blue: {
    base: "#262aff",
    dark: "#0d10b8",
  },
  orange: {
    base: "#e88127",
    dark: "#ba5f0f",
  },
  alert: {
    base: "#b71717",
    dark: "#f23b51",
  },
};

export default function SnapshotCell({
  storyId,
  color,
  col,
  colSpan,
  children,
  innerClassName = "",
  className = "",
  url,
  isCurrent = false,
}) {
  const bgColor = color ? `bg-${color}` : "";
  const hexColor = tailwindToHex[color?.split("-")[0]];
  const colStart = `col-start-${col}`;
  const colSpanCls = `col-span-${colSpan || 1}`;
  return (
    <div
      className={`${colSpanCls} ${colStart} px-2 pt-1  ${className}  min-w-max`}
      data-tip
      data-for={storyId}
    >
      {url ? (
        <Link
          to={{
            pathname: url,
          }}
          target="_blank"
        >
          <div
            style={{
              background: isCurrent
                ? `repeating-linear-gradient(45deg, ${hexColor?.dark}, ${hexColor?.dark} 10px, ${hexColor?.base} 10px, ${hexColor?.base} 20px)`
                : "",
            }}
            className={`${innerClassName} w-full min-w-max h-5 flex justify-center items-center rounded ${
              isCurrent ? "" : bgColor
            }`}
          >
            {children}
          </div>
        </Link>
      ) : (
        <div
          className={`${bgColor} ${innerClassName} w-full min-w-max h-5 flex justify-center items-center rounded`}
        >
          {children}
        </div>
      )}
    </div>
  );
}
