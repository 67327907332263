import React from "react";
import { Flex, Frame } from "@ableco/baseline";
import BlockContainer from "components/BlockContainer";
import BlockContainerTitle from "components/BlockContainerTitle";
import EmptyChart from "components/charts/EmptyChart";

export default function MetricChartContainer({
  title,
  subtitle,
  description,
  children,
  data,
  isLoading = false,
  minimal,
  resolution,
  filterComponent = null,
}) {
  let content = children;

  if (isLoading) {
    content = (
      <Flex
        className="w-full"
        direction="horizontal"
        distribution="center"
        className="text-neutral-100 p-4"
      >
        <span className="text-2xl">Loading...</span>
      </Flex>
    );
  } else if (!data || data.length === 0) {
    content = <EmptyChart resolution={resolution} />;
  }

  return (
    <BlockContainer minimal={minimal}>
      <Frame className="mb-6">
        <BlockContainerTitle
          title={title}
          description={description}
          subtitle={subtitle}
        />
      </Frame>
      {filterComponent}
      {content}
    </BlockContainer>
  );
}
