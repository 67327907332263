import buildURL from "helpers/buildURL";
import fetchJSON from "helpers/fetchJSON";

const updateIssue = (issueId, body) => {
  return fetchJSON(
    buildURL({
      path: `/api/issues/${issueId}`,
      filter: {},
    }),
    "PATCH",
    body,
  );
};

function useIssuesQuery() {
  return { updateIssue };
}

export default useIssuesQuery;
