import React, { useContext } from "react";
import { useParams } from "react-router";
import { DateFiltersContext } from "../contexts/DateFiltersContext";
import { useProjectSnapshot } from "../queries/projectSnapshotQueries";
import Snapshot from "../components/snapshot/Snapshot";

export default function ProjectSnapshot() {
  const { projectId } = useParams();
  const { dateFilters } = useContext(DateFiltersContext);
  const { from, to } = dateFilters;
  const { data: snapshotData, isLoading: isLoading } = useProjectSnapshot(
    projectId,
    from,
    to,
  );

  return (
    <Snapshot
      snapshotData={snapshotData}
      isLoading={isLoading}
      isProject={true}
    />
  );
}
