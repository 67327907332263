import React, { useMemo, useState } from "react";
import { BarChart } from "components/charts/Charts";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
} from "recharts";
import MetricChartContainer from "components/MetricChartContainer";
import JobMappingSelector from "./JobMappingSelector";
import RelativeDateSelector from "./RelativeDateSelector";
import { renderCustomBarLabel } from "helpers/customBarLabel";
import { useMetricsQuery } from "hooks/useMetricsQuery";
import { uniq, sortBy, indexOf } from "lodash";
import { BAR_COLORS, RECRUITMENT_STAGES_ORDER } from "helpers/constants";

export default function PassThroughRateChart({ minimal = false }) {
  const [jobIds, setJobsIds] = useState("");
  const [relativeDate, setRelativeDate] = useState("");

  const { data: metrics, isLoading } = useMetricsQuery(
    "recruitment/pass_through_rate",
    {
      by: relativeDate,
      job_ids: jobIds,
    },
  );
  const fetchedData = metrics?.data || [];
  const { total_candidates, total_hires, conversion_rate } =
    metrics?.meta || {};

  const sortedData = useMemo(
    () =>
      sortBy(fetchedData, item =>
        indexOf(RECRUITMENT_STAGES_ORDER, item.stage),
      ),
    [fetchedData],
  );

  const barItems = sortedData.reduce(
    (acc, current) =>
      uniq([
        ...acc,
        ...Object.keys(current).filter(
          key => !["total", "stage"].includes(key),
        ),
      ]),
    [],
  );

  const renderChart = (
    <BarChart layout="vertical" data={sortedData} height={minimal && 280}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis type="number" tickCount="6" />
      <YAxis dataKey="stage" type="category" width={minimal ? 0 : 200} />
      <Tooltip cursor={{ fill: "transparent" }} />
      {!minimal && <Legend />}
      {barItems.map((barItem, index) => (
        <Bar
          key={index}
          dataKey={barItem}
          name={barItem}
          stackId="group"
          fill={BAR_COLORS[index]}
          maxBarSize={20}
        >
          {index === barItems.length - 1 && (
            <LabelList
              dataKey="total"
              horizontal
              content={renderCustomBarLabel}
            />
          )}
        </Bar>
      ))}
    </BarChart>
  );

  return (
    <MetricChartContainer
      data={sortedData}
      isLoading={isLoading}
      title={!minimal && "Pass-through Rates"}
      minimal={minimal}
      filterComponent={
        <div className="flex">
          <RelativeDateSelector onChangeDate={setRelativeDate} />
          <JobMappingSelector onChangeJobIds={setJobsIds} />
        </div>
      }
    >
      <p className="text-white">
        <span className="font-bold">Total candidates: </span>
        <span>{total_candidates}</span>
      </p>
      <p className="text-white">
        <span className="font-bold">Total hires: </span>
        <span>{total_hires}</span>
      </p>
      <p className="text-white">
        <span className="font-bold">Conversion rate: </span>
        <span>{`${conversion_rate}%`}</span>
      </p>
      {renderChart}
    </MetricChartContainer>
  );
}
