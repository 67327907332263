import React, { useContext } from "react";
import { useParams, useLocation } from "react-router-dom";
import { BarChart } from "components/charts/Charts";
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import { DateFiltersContext } from "contexts/DateFiltersContext";
import MetricChartContainer from "components/MetricChartContainer";
import ChartSummaryValues from "./ChartSummaryValues";
import { useMetricsQuery } from "hooks/v2/useMetricsQuery";
import labelDate from "helpers/labelDate";
import sumArrayOfObjects from "helpers/sumArrayOfObjects";

export default function ProjectThroughputChart() {
  const { projectId, userId } = useParams();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const resolution = query.get("resolution");

  const { dateFilters } = useContext(DateFiltersContext);
  const { data: metrics } = useMetricsQuery("project_throughput", {
    ...dateFilters,
    resolution,
    projectId,
    userId,
  });

  const fetchedData = metrics?.data || [];
  const summaryData = sumArrayOfObjects(fetchedData);

  const renderChartSummary = (
    <ChartSummaryValues
      chartSummary={[
        {
          label: "Pitches",
          value: summaryData["pitch"] || 0,
        },
        {
          label: "Tweaks",
          value: summaryData["tweak"] || 0,
        },
        {
          label: "Chores",
          value: summaryData["chore"] || 0,
        },
        {
          label: "Bugs",
          value: summaryData["bug"] || 0,
        },
      ]}
    />
  );

  const renderDescription = (
    <p className="pb-2">
      This chart shows{" "}
      <strong>
        the work deployed by project type (bugs, chores, tweaks or pitches)
        grouped by periods of time
      </strong>
      .
    </p>
  );

  const renderChart = (
    <BarChart data={fetchedData}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="accepted_at"
        tickFormatter={value => labelDate(value, resolution)}
      />
      <XAxis
        dataKey="project_name"
        xAxisId="project_name"
        allowDuplicatedCategory={false}
      />
      <YAxis />
      <Tooltip cursor={{ fill: "transparent" }} />
      <Legend wrapperStyle={{ position: "relative" }} />
      <Bar
        dataKey="bug"
        name="Bug"
        stackId="project"
        maxBarSize={48}
        fill="#c0392b"
      />
      <Bar
        dataKey="pitch"
        name="Pitch"
        stackId="project"
        maxBarSize={48}
        fill="#9b59b6"
      />
      <Bar
        dataKey="tweak"
        name="Tweak"
        stackId="project"
        maxBarSize={48}
        fill="#1abc9c"
      />
      <Bar
        dataKey="chore"
        name="Chore"
        stackId="project"
        maxBarSize={48}
        fill="#e67e22"
      />
      <Bar
        dataKey="story"
        name="Story"
        stackId="project"
        maxBarSize={48}
        fill="#3498db"
      />
    </BarChart>
  );

  return (
    <MetricChartContainer
      data={fetchedData}
      title="Project Throughput"
      description={renderDescription}
    >
      {renderChartSummary}
      {renderChart}
    </MetricChartContainer>
  );
}
