import { differenceInWeeks, endOfWeek, format } from "date-fns";
import startOfWeek from "date-fns/startOfWeek";

function formatDate(stringDate) {
  return format(new Date(stringDate), "MM/dd");
}
function startWeekFormat(started_at) {
  return format(
    startOfWeek(new Date(started_at), { weekStartsOn: 1 }),
    "MM/dd",
  );
}
function dateFormatter(strDate) {
  const date = new Date(`${strDate} 00:00:00`);
  return format(date, "MMM dd, yyyy");
}
function colSpan(started_at, completed_at) {
  return (
    differenceInWeeks(
      startOfWeek(new Date(completed_at), { weekStartsOn: 1 }),
      startOfWeek(new Date(started_at), { weekStartsOn: 1 }),
    ) + 1
  );
}

//Recursively find the next story to show next to the current one
function getSnapshotRow(groupByWeek2Data, keys, story, row, index) {
  if (index + story.colSpan < keys.length) {
    let nStory, i;
    for (i = index + story.colSpan; i < keys.length; i++) {
      //find the next row of stories after the current one
      const nStories = groupByWeek2Data[keys[i]];
      nStory = nStories?.find(
        el => el.startingWeek >= keys[i] && story.endingWeek < el.startingWeek,
      );

      if (nStory) break;
    }

    if (nStory) {
      //this attribute is used to render a story in the correct position
      nStory.startingCol = i + 1;
      row.push(nStory);

      //remove this story from the data so that it's not considered again
      groupByWeek2Data[keys[i]] = groupByWeek2Data[keys[i]]?.filter(
        el => el !== nStory,
      );

      //find the next story in the row
      return getSnapshotRow(groupByWeek2Data, keys, nStory, row, i);
    }
  }
}

function groupByWeekSameRow(weeks, stories) {
  const data = groupByWeek(weeks, stories);
  let groupByWeekOrdered = {};
  const groupByWeek2 = JSON.parse(JSON.stringify(data));
  const keys = Object.keys(groupByWeek2);

  keys.forEach((key, index) => {
    if (!groupByWeek2[key]) return;

    const items = groupByWeek2[key];
    groupByWeekOrdered[key] = [];

    items?.forEach(item => {
      const row = [item];
      getSnapshotRow(groupByWeek2, keys, item, row, index);
      groupByWeekOrdered[key].push(row);
    });
  });
  return groupByWeekOrdered;
}

function groupByWeek(weeks, stories) {
  let groupByWeek = {};
  const checkedIds = [];
  weeks.forEach((week, index) => {
    const filteredStories = stories
      ?.filter(
        story =>
          story.startingWeek <= week &&
          story.endingWeek >= week &&
          !checkedIds.includes(story.id),
      )
      .sort(
        (a, b) =>
          b.colSpan - a.colSpan || a.startingWeek.localeCompare(b.startingWeek),
      );
    groupByWeek[week] = filteredStories;
    filteredStories?.forEach(el => {
      checkedIds.push(el.id);
      el.startingCol = index + 1;
    });
  });

  return groupByWeek;
}

function dataDigest(stories, firstWeek) {
  return stories?.map(story => ({
    id: story.data.id,
    flows: story.data.flows,
    project_name: story.project?.name,
    name: story.data.name,
    started_at: story.data.started_date,
    ended_at: story.data.schedule_date,
    completed_at: story.data.completed_date,
    startingWeek:
      new Date(story.data.started_date) < firstWeek
        ? format(firstWeek, "MM/dd")
        : startWeekFormat(story.data.started_date),
    endingWeek: startWeekFormat(story.data.completed_date),
    colSpan: colSpan(
      new Date(story.data.started_date) < firstWeek
        ? firstWeek
        : story.data.started_date,
      story.data.completed_date || story.data.schedule_date,
    ),
    pr: story.data.pr || null,
    overdue_days: story.data.overdue_days || null,
    url: story.data.url,
    assignees: story.data.assignees || null,
    user: {
      name: story.data.user?.name || null,
      avatar_url: story.data.user?.avatar_url || null,
    },
  }));
}

function isStartEndSameMonth(date) {
  return (
    format(startOfWeek(date, { weekStartsOn: 1 }), "MMM") ==
    format(endOfWeek(date, { weekStartsOn: 1 }), "MMM")
  );
}

export {
  formatDate,
  startWeekFormat,
  dateFormatter,
  groupByWeek,
  dataDigest,
  isStartEndSameMonth,
  colSpan,
  groupByWeekSameRow,
};
