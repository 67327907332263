import React from "react";
import { formatDate } from "./SnashotCommons";
import ClockIcon from "icons/clock-regular.svg";
import RuningMan from "icons/running-solid.svg";
import Flag from "icons/flag.svg";
import { groupBy } from "lodash";
import { Avatar } from "@ableco/baseline";
import { format } from "date-fns";

function StoryTooltipCard({ story, isOverdue }) {
  const renderDueDate = (
    <span className="text-black">{`Due date: ${format(
      new Date(story.ended_at),
      "MM/dd",
    )}`}</span>
  );

  return (
    <div>
      <strong className="text-black">{story.project_name}</strong>
      <p className="text-black">{story.name}</p>
      <p className="text-black">
        {formatDate(story.started_at)}-
        {formatDate(story.completed_at || story.ended_at)}
      </p>
      {isOverdue ? (
        <>
          {renderDueDate}
          <div className="flex flex-wrap gap-1 items-center text-black">
            <span>
              {`Overdue: ${`${story.overdue_days} day(s)` || "No data"}`}
            </span>
            {story.completed_at ? (
              <ClockIcon />
            ) : (
              <span className="text-alert-base">
                <Flag />
              </span>
            )}
          </div>
        </>
      ) : (
        <>
          {story.completed_at ? (
            <div className="flex flex-wrap gap-1 items-center text-black">
              <span>{`Early deliver: ${`${story.overdue_days || 0} day(s)` ||
                "No data"}`}</span>

              <RuningMan />
            </div>
          ) : (
            <div className="flex flex-wrap gap-1 items-center text-black">
              {renderDueDate}
              <RuningMan />
            </div>
          )}
        </>
      )}
      {story.assignees?.length > 0 ? (
        <>
          <p className="text-black">Assignees:</p>
          <ul>
            {story.assignees?.map((user, ind) => (
              <li
                key={ind}
                className="flex items-center justify-start text-black space-x-1"
              >
                <span>-</span>
                <Avatar
                  source={user.avatar_url}
                  width={15}
                  height={15}
                  name={user.name}
                />
                <span>{user.name}</span>
              </li>
            ))}
          </ul>
        </>
      ) : null}
      {story.flows ? (
        <>
          <p className="text-black">Flows:</p>
          <ul>
            {story.flows?.map(el => (
              <li
                key={Math.random()}
                className="text-black"
              >{`- ${el.name}`}</li>
            ))}
          </ul>
        </>
      ) : null}
    </div>
  );
}
function CrsTooltipCard({ codeReviews, currentKey }) {
  const groupedByProjectName = groupBy(
    codeReviews[currentKey],
    el => el.project_name,
  );
  return Object.keys(groupedByProjectName).map(el => (
    <div key={Math.random()}>
      <strong className="text-black">{el}</strong>
      <div>
        {Object.entries(
          groupBy(groupedByProjectName[el], el1 => el1.pr || "No Data"),
        ).map(vals => (
          <p
            key={Math.random()}
            className="text-black"
          >{`${vals[0]} (${vals[1].length})`}</p>
        ))}
      </div>
    </div>
  ));
}
export { StoryTooltipCard, CrsTooltipCard };
