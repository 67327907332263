import React from "react";
import AddIcon from "icons/list_add.svg";
import ExclamationIcon from "icons/exclamation.svg";
import ReactTooltip from "react-tooltip";

export default function DashboardCardTitle({
  title,
  subtitle,
  showAddIcon = false,
  onClick,
  showAlertIcon = false,
  alertIconTooltip = "",
}) {
  return (
    <div className="mb-5">
      <div className="relative flex items-center">
        <h3 className="flex-1 text-xl leading-6 font-medium text-primary-light truncate">
          {title}
        </h3>
        {showAlertIcon && (
          <>
            <ExclamationIcon
              data-tip
              data-for="alert"
              className="text-indicator-yellow fill-current mr-1"
            />
            {alertIconTooltip && (
              <ReactTooltip id="alert" backgroundColor="#FFFFFF">
                <span className="text-black">{alertIconTooltip}</span>
              </ReactTooltip>
            )}
          </>
        )}

        {showAddIcon && (
          <AddIcon
            data-testid="addIcon"
            className="text-neutral-200 fill-current flex-shrink-0 cursor-pointer"
            onClick={onClick}
          />
        )}
      </div>

      {subtitle && (
        <p className="mt-4 max-w-2xl mb-4 text-base text-neutral-100">
          {subtitle}
        </p>
      )}
    </div>
  );
}
