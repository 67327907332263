import React, { useEffect, useState } from "react";
import Select from "react-select";

const RELATIVE_DATES = [
  {
    label: "Last 30 days",
    value: "last_thirty_days",
  },
  {
    label: "Last 180 days",
    value: "last_one_hundred_eighty_days",
  },
];

export default function RelativeDateSelector({ onChangeDate }) {
  const [selectedDate, setSelectedDate] = useState();

  const handleDateChange = itemSelected => {
    setSelectedDate(itemSelected);
    onChangeDate(itemSelected.value);
  };

  useEffect(() => {
    setSelectedDate(RELATIVE_DATES[0]);
    onChangeDate(RELATIVE_DATES[0].value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Select
      className="w-3/12 mr-5 mb-6"
      value={selectedDate}
      onChange={handleDateChange}
      options={RELATIVE_DATES}
    />
  );
}
