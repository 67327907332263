import React from "react";
import AccordionSection from "components/AccordionSection";
import TableWithPagination from "components/TableWithPagination";

const detailsColumns = [
  { label: "Current Stage", name: "stage" },
  { label: "Last Stage Change", name: "last_stage_change" },
  {
    label: "Job",
    render: ({ item }) => (
      <span className="truncate block w-48">{item.job_name}</span>
    ),
  },
  {
    label: "Candidate",
    render: ({ item }) => (
      <span>{`${item.first_name || ""} ${item.last_name || ""}`}</span>
    ),
  },
  { label: "Last Interview", name: "last_scheduled_interview" },
  { label: "Last Interviewer", name: "last_interviewer" },
  { label: "Next Interview", name: "next_scheduled_interview" },
  { label: "Next Interviewer", name: "next_interviewer" },
];

export default function PipelineDetailsTable({ data }) {
  return (
    <AccordionSection title={`Details (${data.length} items)`}>
      <TableWithPagination
        columns={detailsColumns}
        data={data}
        rowsPerPage={15}
      />
    </AccordionSection>
  );
}
