export default async function fetchJSON(
  url,
  method = "GET",
  body = {},
  json = true,
) {
  let response;
  if (method === "GET") {
    response = await fetch(url, { method });
  } else {
    const token = document.querySelector("[name=csrf-token]").content;

    response = await fetch(url, {
      method,
      body: JSON.stringify(body),
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": "application/json",
      },
    });
  }
  if (json) return await response.json();
  return "";
}
