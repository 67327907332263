import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Button, Text, Color, ButtonState } from "@ableco/baseline";
import { createRefetchJob, checkRefetchJob } from "queries/refetchJobQueries";
import Spinner from "components/Spinner";
import SyncIcon from "icons/sync-arrows.svg";

export default function RefetchButton({
  projectId,
  className,
  from,
  to,
  job = "refetch_job",
}) {
  const [isRunning, setIsRunning] = useState(true);

  useQuery(["Refetch", projectId, job], () => checkRefetchJob(projectId, job), {
    onSuccess: ({ lock }) => {
      setIsRunning(lock);
    },
    refetchInterval: 4000,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
    enabled: isRunning,
  });

  const { mutateAsync: requestRefetchJob } = useMutation(createRefetchJob, {
    onSuccess: () => setIsRunning(true),
  });

  return (
    <Button
      state={isRunning ? ButtonState.Loading : ButtonState.Normal}
      className={[
        "rounded-md",
        "inline-flex",
        "border-none",
        "items-center",
        isRunning ? null : "hover:bg-neutral-700",
        "bg-neutral-800",
        className,
      ]}
      onClick={() => requestRefetchJob({ projectId, from, to, route: job })}
    >
      {isRunning ? (
        <Text
          color={Color.Neutral700}
          className="inline-flex items-center py-1"
        >
          <Spinner /> Refetching
        </Text>
      ) : (
        <div className="flex items-center space-x-1">
          <SyncIcon className="fill-current text-neutral-200" />
          <Text
            color={Color.Neutral100}
            className="inline-flex items-center py-1"
          >
            Refetch
          </Text>
        </div>
      )}
    </Button>
  );
}
