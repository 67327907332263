import React, { useState } from "react";
import StoryTracker from "./StoryTracker";
import StoryTrackerForm from "./StoryTrackerForm";
import AddIcon from "icons/list_add.svg";
import { isEmpty } from "lodash";

export default function StoryTrackersList({ trackers, projectId }) {
  const [isStoryTrackerFormOpen, setStoryTrackerFormOpen] = useState(false);

  return (
    <>
      <div className="flex space-x-4 items-center mb-2">
        <h3 className="text-md text-primary-light font-bold">Story Trackers</h3>
        <AddIcon
          className="text-neutral-200 fill-current flex-shrink-0 cursor-pointer"
          onClick={() => setStoryTrackerFormOpen(true)}
        />
      </div>
      {isEmpty(trackers) ? (
        <p className="text-neutral-100 text-sm">No trackers found.</p>
      ) : (
        <>
          <div className="w-full text-neutral-200 text-xs border-b-2 border-gray-500 mb-2">
            <span className="w-1/4 inline-block">NAME</span>
            <span className="w-1/4 inline-block">TRACKER</span>
            <span className="w-1/4 inline-block">TRACKER ID</span>
            <span className="w-1/4 inline-block">ARCHIVED?</span>
          </div>
          <div className="space-y-2">
            {trackers.map(tracker => (
              <StoryTracker
                key={`tracker-${tracker.id}`}
                tracker={tracker}
                asRow
              />
            ))}
          </div>
        </>
      )}
      <StoryTrackerForm
        open={isStoryTrackerFormOpen}
        setOpen={setStoryTrackerFormOpen}
        projectId={projectId}
      />
    </>
  );
}
