import buildURL from "helpers/buildURL";
import fetchJSON from "helpers/fetchJSON";

const createProject = body => {
  return fetchJSON(
    buildURL({
      path: `/api/projects`,
      filter: {},
    }),
    "POST",
    body,
  );
};

const editProject = body => {
  return fetchJSON(
    buildURL({
      path: `/api/projects/${body.id}`,
      filter: {},
    }),
    "PATCH",
    body,
  );
};

function projectQueries() {
  return {
    editProject,
    createProject,
  };
}

export default projectQueries;
