import React, { useContext } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import { LineChart } from "components/charts/Charts";
import { Flex, Text, Color, TextWeight } from "@ableco/baseline";
import { DateFiltersContext } from "contexts/DateFiltersContext";
import MetricChartContainer from "components/MetricChartContainer";
import useMetricsQuery from "hooks/v2/useMetricsQuery";
import labelDate from "helpers/labelDate";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length !== 0) {
    const {
      start_date,
      end_date,
      total_users,
      unique_users_commited,
      coding_day_avg,
    } = payload[0].payload;

    return (
      <div
        style={{
          borderColor: "rgb(204, 204, 204)",
        }}
        className="p-3 bg-white border"
      >
        <p>{end_date === null ? start_date : `${start_date} - ${end_date}`}</p>
        <p>{`Coding Days from Users : ${unique_users_commited}`}</p>
        <p>{`Total Users Considered : ${total_users}`}</p>
        <p>{`${payload[0].name} : ${coding_day_avg}`}</p>
      </div>
    );
  }

  return null;
};

export default function CodingDaysPerWeekChart() {
  const { projectId, userId } = useParams();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const resolution = query.get("resolution");

  const { dateFilters } = useContext(DateFiltersContext);
  const { data: metrics, isLoading } = useMetricsQuery("coding_days_per_week", {
    ...dateFilters,
    resolution,
    projectId,
    userId,
  });
  const renderDescription = (
    <p className="pb-2">
      This chart shows{" "}
      <strong>
        the average time per week where team members as a whole contributed with
        at least one commit each
      </strong>
      .
    </p>
  );

  const renderTotals = (
    <Flex className="pr-8" distribution="end">
      <Text
        className="font-lato"
        color={Color.Neutral100}
        weight={TextWeight.Light}
      >
        Coding Days mean:{" "}
        <span className="text-green-light">{metrics?.coding_day_mean} </span>
      </Text>
    </Flex>
  );

  const data = metrics?.data || [];

  const renderChart = (
    <LineChart
      data={data}
      margin={{
        top: 10,
        right: 30,
        left: 0,
        bottom: 40,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="start_date"
        tickFormatter={value => labelDate(value, resolution)}
      />
      <YAxis />
      <Tooltip content={<CustomTooltip />} cursor={{ fill: "transparent" }} />
      <Legend wrapperStyle={{ position: "relative" }} />
      <Line
        dataKey="coding_day_avg"
        name="Coding Days Average"
        fill="#c0392b"
        type="monotone"
        strokeWidth={3}
      />
    </LineChart>
  );

  return (
    <MetricChartContainer
      data={data}
      isLoading={isLoading}
      title="Coding Days"
      description={renderDescription}
    >
      {renderTotals}
      {renderChart}
    </MetricChartContainer>
  );
}
