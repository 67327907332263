import React from "react";

function LegendEle({ color, type }) {
  return (
    <div className="flex flex-wrap gap-2 items-center">
      <div className={`rounded-full h-3 w-3 bg-${color}`}></div>
      <span className="text-white">{type}</span>
    </div>
  );
}
export default function Legend() {
  const data = [
    { color: "orange-base", type: "Pitch" },
    { color: "green-base", type: "Tweak" },
    { color: "blue-base", type: "Chore" },
    { color: "alert-base", type: "Bug" },
    { color: "blue-light", type: "Code Review" },
  ];
  return (
    <div className="flex flex-wrap gap-2 w-full p-2 justify-center">
      {data.map((el, index) => (
        <LegendEle key={index} color={el.color} type={el.type} />
      ))}
    </div>
  );
}
