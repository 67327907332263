import React from "react";
import { TableLink, TableSmallText } from "components/TableData";
import { capitalize } from "lodash";
import { format } from "date-fns";
import ReactTooltip from "react-tooltip";
import { Avatar } from "@ableco/baseline";

function formatDate(stringDate) {
  return format(new Date(stringDate), "MM/dd");
}
const ratingValues = [
  { label: "✅", value: 1, title: "Approved" },
  { label: "👀", value: 2, title: "Reviewed" },
  { label: "👍", value: 3, title: "Good" },
  { label: "👍👍", value: 4, title: "Great" },
];

const basicPrColumns = [
  {
    label: "PR",
    render: ({ item }) => {
      const value = {
        text: item.data.pr,
        url: item.data.url,
      };
      return <TableLink value={value} />;
    },
  },
  {
    label: "Draft hours",
    render: ({ item }) => {
      const value = item.data.pr_to_ready;
      return <span className="text-neutral-100">{value}</span>;
    },
  },
  {
    label: "Cr hours",
    render: ({ item }) => {
      const value = item.data.ready_to_review || 0;
      return <span className="text-neutral-100">{value}</span>;
    },
  },
  {
    label: "Merge hours",
    render: ({ item }) => {
      const value = item.data.approval_to_merge || 0;
      return <span className="text-neutral-100">{value}</span>;
    },
  },
];

const detailsPRsColumns = [
  {
    label: "Repo",
    accessor: item => item.data.repo,
  },
  ...basicPrColumns,
];

const projectPRsColumns = [
  {
    label: "User",
    render: item => {
      const user = item.item.data.user;
      return (
        <div>
          <Avatar
            data-tip
            data-for={user.name}
            source={user.avatar_url}
            name={user.name}
            width={25}
            heiht={25}
          />
          <ReactTooltip id={user.name} backgroundColor="#FFFFFF">
            <div className="text-black">{user.name}</div>
          </ReactTooltip>
        </div>
      );
    },
  },
  ...basicPrColumns,
];

const basicBugColumns = [
  {
    label: "Id",
    accessor: item => item.data.id,
  },
  {
    label: "Bug",
    render: ({ item }) => {
      const value = {
        text: item.data.name,
        url: item.data.url,
      };
      return <TableLink value={value} />;
    },
  },
  {
    label: "Type",
    render: ({ item }) => {
      const value = capitalize(item.type);
      return <span className="text-neutral-100">{value}</span>;
    },
  },
  {
    label: "Priority",
    render: ({ item }) => {
      const value = capitalize(item.data.bug_priority);
      return <span className="text-neutral-100">{value}</span>;
    },
  },
  {
    label: "TTF",
    accessor: item => item.data?.actual_days,
  },
];

const detailsBugColumns = [
  {
    label: "Project",
    accessor: item => item.project?.name,
  },
  ...basicBugColumns,
];

const projectDetailsBugColumns = [
  {
    label: "Assignees",
    accessor: item => item.data.assignees,
    render: ({ value }) => {
      return (
        <div className="flex items-center justify-start">
          {value.map((user, index) => (
            <div key={index}>
              <Avatar
                data-tip
                data-for={user.name}
                source={user.avatar_url}
                name={user.name}
                width={25}
                heiht={25}
              />
              <ReactTooltip id={user.name} backgroundColor="#FFFFFF">
                <div className="text-black">{user.name}</div>
              </ReactTooltip>
            </div>
          ))}
        </div>
      );
    },
  },
  ...basicBugColumns,
];

const basicCRColumns = [
  {
    label: "PR",
    accessor: item => item.data.pr || "No data",
    render: ({ item }) => {
      const value = {
        text: item.data.pr,
        url: item.data.url,
      };
      return <TableLink value={value} />;
    },
  },
  {
    label: "Action",
    render: ({ item }) => {
      const icon = ratingValues.find(el => el.value == item.data?.status);
      return <span className="text-neutral-100">{icon?.label}</span>;
    },
  },
  {
    label: "Data Comments",
    accessor: item => item.data.comments,
  },
];

const detailsCRColumns = [
  {
    label: "Repo",
    accessor: item => item.data.repo,
  },
  ...basicCRColumns,
];

const projectCRColumns = [
  {
    label: "User",
    render: item => {
      const user = item.item.data.user;
      return (
        <div>
          <Avatar
            data-tip
            data-for={user.name}
            source={user.avatar_url}
            name={user.name}
            width={25}
            heiht={25}
          />
          <ReactTooltip id={user.name} backgroundColor="#FFFFFF">
            <div className="text-black">{user.name}</div>
          </ReactTooltip>
        </div>
      );
    },
  },

  ...basicCRColumns,
];

const basicDetailsColumns = [
  {
    label: "Id",
    accessor: item => item.data.id,
  },
  {
    label: "Story",
    render: ({ item }) => {
      const value = {
        text: item.data.flow || item.data.name,
        url: item.data.url,
      };
      return <TableLink value={value} />;
    },
  },
  {
    label: "Type",
    render: ({ item }) => {
      const value = item.type[0].toUpperCase() + item.type.slice(1);
      return <span className="text-neutral-100">{value}</span>;
    },
  },
  {
    label: "Date",
    render: ({ item }) => {
      const value = formatDate(item.data.started_date);
      return <TableSmallText value={value} />;
    },
  },
  {
    label: "Scheduled Time",
    accessor: item => item.data?.schedule_days,
  },
  {
    label: "Actual Time",
    accessor: item => item.data?.actual_days,
  },
  { label: "Issues", accessor: item => item.data?.issues_count || 0 },
];

const detailsColumns = [
  {
    label: "Project",
    accessor: item => item.project?.name,
  },
  ...basicDetailsColumns,
];

const projectDetailsColumns = [
  {
    label: "Assignees",
    accessor: item => item.data.assignees,
    render: ({ value }) => {
      return (
        <div className="flex items-center justify-start">
          {value.map((user, index) => (
            <div key={index}>
              <Avatar
                data-tip
                data-for={user.name}
                source={user.avatar_url}
                name={user.name}
                width={25}
                heiht={25}
              />
              <ReactTooltip id={user.name} backgroundColor="#FFFFFF">
                <div className="text-black">{user.name}</div>
              </ReactTooltip>
            </div>
          ))}
        </div>
      );
    },
  },
  ...basicDetailsColumns,
];

export {
  detailsColumns,
  detailsPRsColumns,
  detailsCRColumns,
  detailsBugColumns,
  projectDetailsBugColumns,
  projectDetailsColumns,
  projectPRsColumns,
  projectCRColumns,
};
