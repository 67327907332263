import React from "react";
import { Flex } from "@ableco/baseline";
import { useLocation } from "react-router-dom";

export default function EmptyChart({ messageType, resolution }) {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const resolutionValue = query.get("resolution") || resolution;
  const resolutions = {
    weekly: "week",
    monthly: "month",
    quarterly: "quarter",
    yearly: "year",
  };

  const emptyMessage = {
    noDataMessage: `These metrics don't have data for this ${resolutions[resolutionValue]} yet`,
    noFindMessage: `We didn't find any items for those terms during ${resolutions[resolutionValue]} yet.`,
  };

  return (
    <Flex
      className="w-full"
      direction="horizontal"
      distribution="center"
      className="text-neutral-100 p-4"
    >
      <span className="text-2xl">{emptyMessage[messageType]}</span>
    </Flex>
  );
}

EmptyChart.defaultProps = {
  messageType: "noDataMessage",
};
