import React from "react";
import { useHistory } from "react-router-dom";
import {
  Avatar,
  Inline,
  Frame,
  Color,
  Text,
  Touchable,
} from "@ableco/baseline";
import DropdownMenu from "components/DropdownMenu";
import useDOMState from "hooks/useDOMState";

const MENU_ITEMS = [
  {
    label: "Profile",
    value: "profile",
  },
  {
    label: "Logout",
    value: "logout",
  },
];

function Button({ user, toggleMenu, isOpen, showName }) {
  return (
    <Touchable
      className={["py-1 px-3 rounded", isOpen ? "bg-neutral-700" : ""]}
      style={{ width: showName && "215px" }}
      onClick={toggleMenu}
    >
      <Inline>
        <Avatar
          source={user.avatar_url}
          name={user.name}
          width="40"
          height="40"
        />
        {showName && (
          <Frame className="ml-4">
            <Text color={Color.Neutral100}>{user.name}</Text>
          </Frame>
        )}
      </Inline>
    </Touchable>
  );
}

export default function ProfileButton({ showName = true }) {
  const history = useHistory();
  const [{ currentUser }] = useDOMState("root", {});
  const { level } = currentUser;

  return (
    <DropdownMenu
      extraClassName={"bg-neutral-800"}
      textClassName={"text-neutral-200"}
      items={
        level === "admin"
          ? [
              {
                label: "Admin Settings",
                value: "settings",
              },
              ...MENU_ITEMS,
            ]
          : MENU_ITEMS
      }
      displayTop={true}
      onSelect={item => {
        switch (item.value) {
          case "profile":
            history.push(`/users/${currentUser.id}`);
            break;
          case "settings":
            history.push("/admin/settings");
            break;
          case "logout":
            location.href = "/logout";
            break;
        }
      }}
    >
      <Button user={currentUser} showName={showName} />
    </DropdownMenu>
  );
}
