import { useQuery } from "react-query";
import { format } from "date-fns";
import buildURL from "helpers/buildURL";
import fetchJSON from "helpers/fetchJSON";

function fetchMetric(
  metricType,
  { projectId, userId, from, to, resolution, ...rest },
) {
  let filter = {};
  if (from) {
    filter = {
      from: format(from, "yyyy-MM-dd"),
      to: format(to, "yyyy-MM-dd"),
      resolution,
    };
  }

  if (projectId) {
    filter.project_id = projectId;
  }

  if (userId) {
    filter.user_id = userId;
  }
  return fetchJSON(
    buildURL({
      path: `/api/metrics/${metricType}`,
      filter: { ...filter, ...rest },
    }),
  );
}

function fetchReport(reportType, { searchTerm, from, to, resolution }) {
  const filter = {
    from: format(from, "yyyy-MM-dd"),
    to: format(to, "yyyy-MM-dd"),
    resolution,
  };

  if (searchTerm) {
    filter.terms = searchTerm;
  }

  return fetchJSON(buildURL({ path: `/api/reports/${reportType}`, filter }));
}

function useMetricsQuery(metricType, filters) {
  const query = useQuery(
    [`/api/metrics/${metricType}`, filters],
    () => fetchMetric(metricType, filters),
    { refetchOnWindowFocus: false },
  );

  return query;
}

function useReportsQuery(reportType, filters) {
  const query = useQuery([`/api/reports/${reportType}`, filters], () =>
    fetchReport(reportType, filters),
  );
  return query;
}

export { useMetricsQuery, useReportsQuery };
