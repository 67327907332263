import { parse, format, endOfWeek } from "date-fns";

function labelDate(date, resolution) {
  try {
    const dateObject = parse(date, "yyyy-MM-dd", new Date());

    switch (resolution) {
      case "yearly":
        return format(dateObject, "MMM");
      case "quarterly":
        return `${format(dateObject, "MMM d")} - ${format(
          endOfWeek(dateObject),
          "MMM d",
        )}`;
      case "monthly":
        return format(dateObject, "d");
      case "weekly":
        return format(dateObject, "EEE d");
    }
  } catch (error) {
    return null;
  }
}

export default labelDate;
