import React, { useContext } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";

import MetricChartContainer from "components/MetricChartContainer";
import { BarChart } from "components/charts/Charts";
import { DateFiltersContext } from "contexts/DateFiltersContext";
import useMetricsQuery from "hooks/v2/useMetricsQuery";
import labelDate from "helpers/labelDate";
import ChartSummaryValues from "./ChartSummaryValues";

export default function PullRequestSizeByDateChart() {
  const { projectId, userId } = useParams();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const resolution = query.get("resolution");

  const { dateFilters } = useContext(DateFiltersContext);
  const { data: metrics, isLoading } = useMetricsQuery(
    "pull_request_size_by_date",
    {
      ...dateFilters,
      resolution,
      projectId,
      userId,
    },
  );

  const fetchedData = metrics?.data || [];
  const renderDescription = (
    <p className="pb-2">
      This chart shows{" "}
      <strong>
        the number of lines added and deleted by pull requests grouped by
        periods of time.
      </strong>
    </p>
  );

  const renderChartSummary = fetchedData && (
    <ChartSummaryValues
      chartSummary={[
        { label: "Total lines added", value: metrics?.lines_added_total },
        { label: "Total lines deleted", value: metrics?.lines_deleted_total },
      ]}
    />
  );

  const renderChart = (
    <BarChart data={fetchedData}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="closed_at"
        tickFormatter={value => labelDate(value, resolution)}
      />
      <YAxis />
      <Tooltip cursor={{ fill: "transparent" }} />
      <Legend wrapperStyle={{ position: "relative" }} />
      <Bar
        dataKey="lines_added"
        name="Lines added"
        stackId="pull_request_size"
        maxBarSize={48}
        fill="#2D8337"
      />
      <Bar
        dataKey="lines_deleted"
        name="Lines deleted"
        stackId="pull_request_size"
        maxBarSize={48}
        fill="#C6222F"
      />
    </BarChart>
  );

  return (
    <MetricChartContainer
      data={fetchedData}
      isLoading={isLoading}
      description={renderDescription}
      title="Pull Request Size By Date"
    >
      {renderChartSummary}
      {renderChart}
    </MetricChartContainer>
  );
}
