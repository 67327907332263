import React from "react";
import { Link } from "react-router-dom";
import LogoIcon from "icons/logo.svg";

const SIZES = {
  LG: {
    width: "170",
    height: "48",
  },
  MD: {
    width: "140",
    height: "40",
  },
  base: {
    width: "85",
    height: "24",
  },
};

export default function Logo({ size = "base", to = "/" }) {
  const { width, height } = SIZES[size];
  return (
    <Link to={to}>
      <LogoIcon width={width} height={height} />
    </Link>
  );
}
