import React from "react";
import { Link } from "react-router-dom";
import {
  Frame,
  Heading,
  TextWeight,
  Text,
  Flex,
  Avatar,
  Color,
  TextSize,
} from "@ableco/baseline";
import { LOGO_SIZES } from "helpers/constants";

export default function UserCard({ user = {}, size = "Base", to = "" }) {
  const Wrap = to !== "" ? Link : Frame;

  return (
    <Wrap to={to}>
      <Flex alignment="center">
        <Avatar
          source={user.avatar_url}
          name={user.name}
          width={LOGO_SIZES[size].width}
          height={LOGO_SIZES[size].height}
        />
        <Frame className={size === "LG" ? "ml-8" : "ml-4"}>
          <Heading level={2}>
            <Text
              weight={TextWeight.Bold}
              size={size === "LG" ? TextSize.XL4 : TextSize.XL2}
              color={Color.Neutral100}
              className={size === "LG" ? "mb-4" : ""}
            >
              {user.name}
            </Text>
          </Heading>
        </Frame>
      </Flex>
    </Wrap>
  );
}
