import React, { useState } from "react";
import { BarChart } from "components/charts/Charts";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
} from "recharts";
import MetricChartContainer from "components/MetricChartContainer";
import LinkableAxisTick from "components/charts/LinkableAxisTick";
import ProspectGroupSelector from "./ProspectGroupSelector";
import { renderCustomBarLabel } from "helpers/customBarLabel";
import { useMetricsQuery } from "hooks/useMetricsQuery";
import { BAR_COLORS } from "helpers/constants";
import { uniq } from "lodash";

function tooltipFormatter(value, label) {
  if (label === "Scheduled Time") return [];
  const returnValue = `${value < 0 ? 0 : value}`;
  return [returnValue, label];
}

export default function CrmProspectingChart({ minimal = false }) {
  const [prospectGroup, setProspectGroup] = useState("");
  const { data: metrics, isLoading } = useMetricsQuery(
    "recruitment/crm_prospecting",
    {
      by: prospectGroup.value,
    },
  );
  const fetchedData = metrics || [];
  const barItems = fetchedData.reduce(
    (acc, current) =>
      uniq([...acc, ...Object.keys(current).filter(key => key !== "label")]),
    [],
  );

  const renderChart = (
    <BarChart
      data={fetchedData}
      height={minimal && 280}
      margin={{ top: 20, right: 30, left: 0, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="label" tick={<LinkableAxisTick data={fetchedData} />} />
      <YAxis />
      <Tooltip cursor={{ fill: "transparent" }} formatter={tooltipFormatter} />
      {!minimal && <Legend />}
      {barItems.map((barItem, index) => (
        <Bar
          key={index}
          dataKey={barItem}
          name={barItem}
          fill={BAR_COLORS[index]}
          maxBarSize={48}
        >
          <LabelList dataKey={barItem} content={renderCustomBarLabel} />
        </Bar>
      ))}
    </BarChart>
  );

  return (
    <MetricChartContainer
      data={fetchedData}
      isLoading={isLoading}
      title={!minimal && "CRM Prospect Pools"}
      minimal={minimal}
      filterComponent={
        <ProspectGroupSelector onChangeGroup={setProspectGroup} />
      }
    >
      {renderChart}
    </MetricChartContainer>
  );
}
