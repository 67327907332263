import React from "react";

export default function DashBoardSection({ title, titleComponent, children }) {
  return (
    <div className="mb-12 mt-4">
      <div className="flex items-center">
        {title && (
          <h2
            style={{ minWidth: "175px" }}
            className="text-xl leading-6 font-medium text-neutral-100 mb-6 mr-6"
          >
            {title}
          </h2>
        )}
        {titleComponent}
      </div>
      {children}
    </div>
  );
}
