import React, { useEffect, useState } from "react";
import DefaultLogo from "components/DefaultLogo";
import { Avatar } from "@ableco/baseline";
import { LOGO_SIZES } from "helpers/constants";
import DevopsLogo from "images/logo-devops-l.png";
import UptogetherLogo from "images/logo-uptogether-l.png";

export default function ProjectLogo({ project, size = "MD" }) {
  const [source, setSource] = useState(`${project?.logo_path}`);

  useEffect(() => {
    const logoSource = `${project?.logo_path}`;
    setSource(logoSource);
  }, [project]);

  if (project?.name === "DevOps") {
    return (
      <img
        src={DevopsLogo}
        width={LOGO_SIZES[size].width}
        height={LOGO_SIZES[size].height}
        alt={project?.name}
        className="rounded-full"
      />
    );
  }

  if (project?.name === "UpTogether") {
    return (
      <img
        src={UptogetherLogo}
        width={LOGO_SIZES[size].width}
        height={LOGO_SIZES[size].height}
        alt={project?.name}
        className="rounded-full"
      />
    );
  }

  return source ? (
    <Avatar
      source={source}
      onError={() => setSource(null)}
      name={project?.name}
      width={LOGO_SIZES[size].width}
      height={LOGO_SIZES[size].height}
    />
  ) : (
    <DefaultLogo name={project?.name?.charAt(0)} size={size} />
  );
}
