function sumArrayOfObjects(data) {
  return data.reduce((label, value) => {
    for (const [labelName, labelCount] of Object.entries(value)) {
      if (!label[labelName]) {
        label[labelName] = 0;
      }

      label[labelName] += labelCount;
    }

    return label;
  }, {});
}

export default sumArrayOfObjects;
