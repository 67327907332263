import { useQuery } from "react-query";
import { format } from "date-fns";
import buildURL from "helpers/buildURL";
import fetchJSON from "helpers/fetchJSON";

function fetchResource(
  resourceType,
  { projectId, userId, from, to, resolution },
) {
  const filter = {
    from: from && format(from, "yyyy-MM-dd"),
    to: to && format(to, "yyyy-MM-dd"),
    resolution,
  };

  let apiResource = "";
  let resourceId = "";

  if (projectId) {
    apiResource = "projects";
    resourceId = projectId;
  }

  if (userId) {
    apiResource = "users";
    resourceId = userId;
  }

  return fetchJSON(
    buildURL({
      path: `/api/${apiResource}/${resourceId}/${resourceType}`,
      filter,
    }),
  );
}

function useResourcesQuery(resourceType, filters) {
  // using '/**/**/' in the query array keys as we don't have apiResource and resourceId at this moment
  // but will be extracted in the 'fetchResource' function
  const query = useQuery([`/api/**/**/${resourceType}`, filters], () =>
    fetchResource(resourceType, filters),
  );

  return query;
}

export default useResourcesQuery;
