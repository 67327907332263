import React from "react";
import { Frame } from "@ableco/baseline";
import DateFiltersProvider from "contexts/DateFiltersContext";
import CurrentTeamMetricsSummaryChart from "components/charts/CurrentTeamMetricsSummaryChart";
import Filter from "components/Filter";

function CurrentTeamMetrics() {
  return (
    <DateFiltersProvider>
      <Filter />
      <Frame className="mb-10">
        <CurrentTeamMetricsSummaryChart />
      </Frame>
    </DateFiltersProvider>
  );
}

export default CurrentTeamMetrics;
