import { startOfMonth, endOfMonth } from "date-fns";
import {
  WeekCalculation,
  WeekGrouping,
  LastDayOfWeek,
  LastMonthOfYear,
  RetailCalendarFactory,
} from "retail-calendar";

export default function dateRange445({ resolution, date = new Date() }) {
  const calendar445 = new RetailCalendarFactory(
    {
      weekCalculation: WeekCalculation.LastDayNearestEOM,
      weekGrouping: WeekGrouping.Group445,
      lastDayOfWeek: LastDayOfWeek.Saturday,
      lastMonthOfYear: LastMonthOfYear.January,
      restated: false,
    },
    date.getFullYear(),
  );

  const currentWeek = calendar445.weeks.find(
    week => week.gregorianStartDate <= date && week.gregorianEndDate >= date,
  );
  const firstWeekOfYear = calendar445.weeks[0];
  const lastWeekOfYear = calendar445.weeks[calendar445.weeks.length - 1];
  const firstWeekOfQuarter = calendar445.weeks
    .filter(week => week.quarterOfYear === (currentWeek?.quarterOfYear || 1))
    .shift();
  const lastWeekOfQuarter = calendar445.weeks
    .filter(week => week.quarterOfYear === (currentWeek?.quarterOfYear || 1))
    .pop();

  switch (resolution) {
    case "yearly":
      return [
        firstWeekOfYear.gregorianStartDate,
        lastWeekOfYear.gregorianEndDate,
      ];
    case "quarterly":
      return [
        firstWeekOfQuarter.gregorianStartDate,
        lastWeekOfQuarter.gregorianEndDate,
      ];
    case "monthly":
      return [startOfMonth(date), endOfMonth(date)];
    case "weekly":
      return [currentWeek.gregorianStartDate, currentWeek.gregorianEndDate];
  }
}
