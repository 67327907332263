import React from "react";

export default function LabelValue({ label, value, wrapperClassName }) {
  return (
    <div className={wrapperClassName}>
      {label && (
        <dt className="text-sm font-medium text-neutral-200">{label}</dt>
      )}
      <dd className="mt-1 text-sm text-white">
        {value != null ? value : "--"}
      </dd>
    </div>
  );
}
