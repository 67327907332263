import buildURL from "helpers/buildURL";
import fetchJSON from "helpers/fetchJSON";

const createProjectStatus = body => {
  return fetchJSON(
    buildURL({
      path: `/api/project_statuses`,
      filter: {},
    }),
    "POST",
    body,
  );
};

const updateProjectStatus = body => {
  return fetchJSON(
    buildURL({
      path: `/api/project_statuses/${body.id}`,
      filter: {},
    }),
    "PATCH",
    body,
  );
};

function useProjectStatusesQuery() {
  return { createProjectStatus, updateProjectStatus };
}

export default useProjectStatusesQuery;
