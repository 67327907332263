import React from "react";
import { Button, Text, Color } from "@ableco/baseline";

export default function FilterButton({
  className,
  active,
  disabled,
  onClick,
  children,
  filterItemClassName = "bg-neutral-800",
  filterItemHoverClassName = "hover:bg-neutral-700",
}) {
  return (
    <Button
      corners="square"
      disabled={disabled}
      className={[
        "border-none",
        disabled ? null : filterItemHoverClassName,
        active ? "bg-primary" : filterItemClassName,
        disabled ? "cursor-not-allowed" : null,
        className,
      ]}
      onClick={onClick}
    >
      <Text
        color={disabled ? Color.Neutral700 : Color.Neutral100}
        className="py-1"
        style={{ display: "block" }}
      >
        {children}
      </Text>
    </Button>
  );
}
