import React, { useState, useEffect } from "react";
import Modal from "components/Modal";
import Button from "components/FilterButton";
import useRisksQuery from "hooks/v2/useRisksQuery";
import { useMutation, useQueryClient } from "react-query";
import { isEmpty } from "lodash";

function invalidateQueries(queryClient, dateFilters, projectId) {
  queryClient.invalidateQueries([
    "/api/**/**/risks",
    { ...dateFilters, projectId },
  ]);
}

export default function RiskForm({
  open,
  setOpen,
  dateFilters,
  projectId,
  isEditing = false,
  risk,
}) {
  const [riskDescription, setRiskDescription] = useState("");

  const queryClient = useQueryClient();
  const { createRisk, updateRisk } = useRisksQuery();

  const { mutateAsync: addRisk } = useMutation(createRisk, {
    onSuccess: () => invalidateQueries(queryClient, dateFilters, projectId),
  });

  const { mutateAsync: editRisk } = useMutation(updateRisk, {
    onSuccess: () => invalidateQueries(queryClient, dateFilters, projectId),
  });

  useEffect(() => {
    if (isEditing) {
      setRiskDescription(risk.description);
    }
  }, [risk, isEditing]);

  const handleOpenForm = isOpen => {
    setOpen(isOpen);

    if (!isOpen && !isEditing) {
      setRiskDescription("");
    }
  };

  const handleSubmit = () => {
    const payload = {
      id: risk?.id,
      project_id: projectId,
      week_on: dateFilters.from,
      description: riskDescription,
    };

    if (!isEditing) {
      addRisk(payload).then(() => {
        handleOpenForm(false);
      });
    } else {
      editRisk(payload).then(() => {
        handleOpenForm(false);
      });
    }
  };

  const formContent = (
    <div>
      <label className="block text-primary-light font-medium">Risk</label>
      <input
        type="text"
        className="focus:outline-none rounded-md w-full my-2 p-2"
        placeholder="Add a risk"
        onChange={({ target }) => setRiskDescription(target.value)}
        value={riskDescription}
      />
    </div>
  );

  const formActions = (
    <div className="flex justify-between">
      <Button
        className="h-10 rounded-md w-full sm:w-auto"
        onClick={() => handleOpenForm(false)}
      >
        Cancel
      </Button>
      <Button
        disabled={isEmpty(riskDescription)}
        className="h-10 rounded-md w-full sm:w-auto"
        active={!isEmpty(riskDescription)}
        onClick={handleSubmit}
      >
        Save
      </Button>
    </div>
  );
  return (
    <Modal
      title="Add Risk"
      subtitle="Add an identified risk for the current week."
      content={formContent}
      footer={formActions}
      open={open}
      setOpen={handleOpenForm}
    />
  );
}
