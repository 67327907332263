import React, { useState } from "react";
import LabelValue from "components/LabelValue";
import DropdownMenu from "components/DropdownMenu";
import ConfirmationModal from "components/ConfirmationModal";
import StoryTrackerForm from "./StoryTrackerForm";
import useStoryTrackersQuery from "queries/useStoryTrackersQuery";
import MenuIcon from "icons/menu.svg";
import { useLocation } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { capitalize } from "lodash";

const menuItems = [
  {
    label: "Edit",
    value: "edit",
  },
  {
    label: "Delete",
    value: "delete",
  },
];

function MenuButton({ toggleMenu }) {
  return (
    <MenuIcon
      className="text-neutral-200 fill-current flex-shrink-0 cursor-pointer"
      onClick={toggleMenu}
    />
  );
}

function invalidateQueries(queryClient, projectId, currentLocation) {
  if (currentLocation === "/admin/settings")
    queryClient.invalidateQueries("projects");
  else
    queryClient.invalidateQueries(["/api/**/**/story_trackers", { projectId }]);
}

export default function StoryTracker({ tracker, asRow = false }) {
  const {
    id,
    name,
    tracker_type: type,
    tracker_id,
    archived,
    project_id: projectId,
  } = tracker;

  const [isStoryTrackerFormOpen, setStoryTrackerFormOpen] = useState(false);
  const [isDeleteConfirmationOpen, SetDeleteConfirmationOpen] = useState(false);
  const { deleteStoryTracker } = useStoryTrackersQuery();

  const location = useLocation();

  const queryClient = useQueryClient();
  const { mutateAsync: removeTracker } = useMutation(deleteStoryTracker, {
    onSuccess: () =>
      invalidateQueries(queryClient, projectId.toString(), location.pathname),
  });

  return (
    <dl
      className={
        "flex justify-between " +
        (asRow ? "" : "p-4 rounded-lg border border-gray-500")
      }
    >
      <LabelValue
        label={!asRow && "Name"}
        value={name}
        wrapperClassName="w-1/4"
      />
      <LabelValue
        label={!asRow && "Tracker"}
        value={capitalize(type)}
        wrapperClassName="w-1/4"
      />
      <LabelValue
        label={!asRow && "Tracker ID"}
        value={tracker_id}
        wrapperClassName="w-1/4"
      />
      <div className="w-1/4 flex justify-between items-center">
        <LabelValue
          label={!asRow && "Archived?"}
          value={archived ? "Yes" : "No"}
        />

        <DropdownMenu
          items={menuItems}
          width="110px"
          extraClassName={"bg-neutral-800"}
          textClassName={"text-neutral-200"}
          onSelect={item => {
            switch (item.value) {
              case "edit":
                setStoryTrackerFormOpen(true);
                break;
              case "delete":
                SetDeleteConfirmationOpen(true);
                break;
            }
          }}
        >
          <MenuButton />
        </DropdownMenu>
      </div>

      <StoryTrackerForm
        open={isStoryTrackerFormOpen}
        setOpen={setStoryTrackerFormOpen}
        projectId={projectId.toString()}
        tracker={tracker}
        isEditing
      />

      <ConfirmationModal
        title="Delete Story Tracker"
        prompt={
          <span>
            Are you sure you want to delete <strong>{name}</strong> tracker?
          </span>
        }
        open={isDeleteConfirmationOpen}
        setOpen={SetDeleteConfirmationOpen}
        onConfirm={() => removeTracker(id)}
      />
    </dl>
  );
}
