import React, { useState } from "react";
import { TableData } from "components/TableData";
import ReactPaginate from "react-paginate";

export default function TableWithPagination({
  columns,
  data,
  rowsPerPage = 10,
  ...props
}) {
  const [currentPage, setCurrentPage] = useState(0);

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  const offset = currentPage * rowsPerPage;
  const currentPageData = data.slice(offset, offset + rowsPerPage);
  const pageCount = Math.ceil(data.length / rowsPerPage);
  const classNames = {
    previousNextLink: "cursor-inherit py-2 px-4 block rounded-md",
    pages: "mr-2 mb-2 rounded-md bg-white hover:bg-neutral-100 text-black",
  };

  return (
    <>
      <TableData columns={columns} data={currentPageData} td={props.td} />
      <ReactPaginate
        previousLabel="Previous"
        nextLabel="Next"
        pageCount={pageCount}
        onPageChange={handlePageClick}
        containerClassName="flex pt-5"
        activeClassName="text-white"
        pageLinkClassName="py-2 px-4 block rounded-md"
        pageClassName={classNames.pages}
        previousClassName={classNames.pages}
        nextClassName={classNames.pages}
        activeLinkClassName="bg-primary-base text-white"
        previousLinkClassName={classNames.previousNextLink}
        nextLinkClassName={classNames.previousNextLink}
        disabledClassName="cursor-not-allowed pointer-events-none bg-neutral-200"
      />
    </>
  );
}
