import React from "react";
import DataFetching from "./DataFetching";
import Projects from "./Projects";
import Recruitment from "./Recruitment";

export default function AdminSettings({ selectedTab }) {
  return (
    <div className="rounded-lg bg-nord-background w-full">
      {selectedTab === "projects" && <Projects />}
      {selectedTab === "recruitment" && <Recruitment />}
      {selectedTab === "data_fetching" && <DataFetching />}
    </div>
  );
}
