import React, { useCallback, useContext, useEffect, useState } from "react";
import LoadingData from "components/LoadingData";
import ProjectForm from "components/ProjectForm";
import Button from "components/FilterButton";
import Checkbox from "components/Checkbox";
import ProjectSettingsCard from "components/settings/ProjectSettingsCard";
import SearchIcon from "icons/search.svg";
import { ProjectsContext } from "contexts/ProjectsContext";
import { debounce } from "lodash";

function SearchInput({ onChange }) {
  return (
    <div className="relative w-80 flex items-center text-gray-400 focus-within:text-gray-600 pb-2 mb-2 border-b-2 border-gray-500">
      <SearchIcon className="text-neutral-200 fill-current flex-shrink-0" />

      <input
        className="block w-full pl-8 pr-3 py-2 bg-transparent border-transparent text-white placeholder-gray-500 focus:outline-none"
        placeholder="Search projects"
        type="search"
        onChange={({ target }) => onChange(target.value)}
        autoFocus
      />
    </div>
  );
}

export default function Projects() {
  const [isProjectFormOpen, setProjectFormOpen] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [showArchived, setShowArchived] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const { projects, isLoadingProjects } = useContext(ProjectsContext);

  const updateProjectList = useCallback(() => {
    const updatedList = showArchived
      ? projects.filter(p => p.name.toLowerCase().includes(searchQuery))
      : projects.filter(
          p => p.name.toLowerCase().includes(searchQuery) && !p.archived,
        );

    setProjectList(updatedList);
  }, [projects, searchQuery, showArchived]);

  const handleQueryChange = debounce(value => {
    setSearchQuery(value.toLowerCase());
  }, 1000);

  useEffect(() => {
    updateProjectList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showArchived, searchQuery]);

  useEffect(() => {
    if (!isLoadingProjects) {
      setProjectList(projects);
      updateProjectList();
    }
  }, [projects, isLoadingProjects, updateProjectList]);

  return (
    <div className="px-8 pb-8 pt-4">
      <div className="flex justify-between mb-2">
        <div className="flex items-center space-x-4 ">
          <SearchInput onChange={handleQueryChange} />
          <Checkbox
            checked={showArchived}
            prompt={
              <span className="text-sm text-primary-light">
                Show archived projects?
              </span>
            }
            onChange={({ target }) => setShowArchived(target.checked)}
          />
        </div>
        <Button
          className="h-10 rounded-md"
          onClick={() => setProjectFormOpen(true)}
        >
          Add Project
        </Button>
      </div>

      {isLoadingProjects ? (
        <LoadingData />
      ) : (
        projectList.map((project, index) => (
          <ProjectSettingsCard key={index} project={project} />
        ))
      )}
      <ProjectForm open={isProjectFormOpen} setOpen={setProjectFormOpen} />
    </div>
  );
}
