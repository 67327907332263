import React from "react";
import { Frame } from "@ableco/baseline";
import {
  BarChart as RechartsBarChart,
  LineChart as RechartsLineChart,
  ComposedChart as RechartsComposedChart,
  ResponsiveContainer,
} from "recharts";

function withChartWrapper(ChartComponent) {
  return function(props) {
    const { height } = props;

    const defaultHeight = 520;
    const maxHeight = 624;
    const minHeight = 200;
    let customHeight = 0;
    if (!height) {
      customHeight = defaultHeight;
    } else if (height >= maxHeight) {
      customHeight = maxHeight;
    } else if (height <= minHeight) {
      customHeight = minHeight;
    } else {
      customHeight = height;
    }

    const defaultProps = {
      margin: { top: 10, right: 30, left: 0, bottom: 40 },
    };
    const customProps = { ...defaultProps, ...props };

    return (
      <Frame style={{ width: "100%", height: customHeight }}>
        <ResponsiveContainer>
          <ChartComponent {...customProps} />
        </ResponsiveContainer>
      </Frame>
    );
  };
}

const BarChart = withChartWrapper(RechartsBarChart);
const LineChart = withChartWrapper(RechartsLineChart);
const ComposedChart = withChartWrapper(RechartsComposedChart);

export { BarChart, LineChart, ComposedChart };
