import React from "react";
import { Frame } from "@ableco/baseline";
import classNames from "classnames";

export default function BlockContainer({ children, minimal = false }) {
  const containerClassName = classNames("rounded-md bg-neutral-800", {
    "p-8": !minimal,
  });

  return <Frame className={containerClassName}>{children}</Frame>;
}
