import React, { useContext } from "react";
import { BarChart } from "components/charts/Charts";
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import { format } from "date-fns";
import { any, pathOr, prop } from "ramda";
import MetricChartContainer from "components/MetricChartContainer";
import { DateFiltersContext } from "contexts/DateFiltersContext";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import labelDate from "helpers/labelDate";
import { COLOR_SET } from "utils/color";

function groupExceptionsByDate(groups, exceptions) {
  const data = groups.reduce((grouped, item) => {
    const { exception_type, date, count } = item;
    const prev = pathOr({ date }, [date], grouped);
    const row = {};
    if (exceptions.includes(exception_type)) {
      row[exception_type] = count;
    } else {
      row["other"] = pathOr(0, ["other"], prev) + count;
    }

    grouped[date] = {
      ...prev,
      ...row,
    };
    return grouped;
  }, {});
  return Object.values(data);
}

const anyOther = any(prop("other"));

async function fetchUncaughtExceptions(id, filters) {
  const from = format(filters.from, "yyyy-MM-dd");
  const to = format(filters.to, "yyyy-MM-dd");
  const params = new URLSearchParams({ ...filters, from, to }).toString();
  const response = await fetch(
    `/api/projects/${id}/metrics/uncaught_exceptions?${params}`,
  );
  const { groups, exceptions } = await response.json();
  const date_counts = groupExceptionsByDate(groups, exceptions);
  if (anyOther(date_counts)) exceptions.push("other");
  return {
    date_counts,
    exceptions,
  };
}

export default function UncaughtExceptionsChart() {
  const { dateFilters } = useContext(DateFiltersContext);
  const { projectId } = useParams();
  const { data: metrics, isLoading } = useQuery(
    [`projects/${projectId}/UncaughtExceptions`, dateFilters],
    () => fetchUncaughtExceptions(projectId, dateFilters),
    { refetchOnWindowFocus: false },
  );

  const { exceptions = [], date_counts = [] } = metrics || {};

  const renderChart = (
    <BarChart data={date_counts}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="date"
        tickFormatter={value => labelDate(value, dateFilters.resolution)}
      />
      <YAxis />
      <Tooltip cursor={{ fill: "transparent" }} />
      <Legend />
      {exceptions.map((exception, idx) => (
        <Bar
          dataKey={exception}
          stackId="unique"
          fill={COLOR_SET[idx]}
          key={idx}
        />
      ))}
    </BarChart>
  );

  const renderDescription = (
    <p className="pb-2">
      This chart shows{" "}
      <strong>
        the incidents reported by Sentry in a production environment grouped by
        periods of time
      </strong>
      .
    </p>
  );

  return (
    <MetricChartContainer
      data={metrics?.exceptions}
      isLoading={isLoading}
      title="Uncaught Exceptions"
      description={renderDescription}
    >
      {renderChart}
    </MetricChartContainer>
  );
}
