import buildURL from "helpers/buildURL";
import fetchJSON from "helpers/fetchJSON";

const createPullRequestTracker = body => {
  return fetchJSON(
    buildURL({
      path: `/api/pull_request_trackers`,
      filter: {},
    }),
    "POST",
    body,
  );
};

const editPullRequestTracker = body => {
  return fetchJSON(
    buildURL({
      path: `/api/pull_request_trackers/${body.id}`,
      filter: {},
    }),
    "PATCH",
    body,
  );
};

const deletePullRequestTracker = trackerId => {
  return fetchJSON(
    buildURL({
      path: `/api/pull_request_trackers/${trackerId}`,
      filter: {},
    }),
    "DELETE",
  );
};

function pullRequestTrackerQueries() {
  return {
    createPullRequestTracker,
    deletePullRequestTracker,
    editPullRequestTracker,
  };
}

export default pullRequestTrackerQueries;
