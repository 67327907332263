const groupBy = (collection = [], key) =>
  collection.reduce((result, item) => {
    const groupKey = typeof key === "string" ? item[key] : key(item);

    return {
      ...result,
      [groupKey]: [...(result[groupKey] || []), item],
    };
  }, {});

export default groupBy;
