import React from "react";

export default function FormLabel({ children, required = false }) {
  return (
    <label className="block text-primary-light font-medium mb-1">
      {children}
      {required && <span className="text-alert-base"> *</span>}
    </label>
  );
}
