import React, { useState } from "react";
import classNames from "classnames";
import { Button, Text, Textbox } from "@ableco/baseline";

export default function Rating({
  arrayValues,
  status,
  commentStatus,
  onUpdateComment,
  isEditable,
}) {
  const [showComment, setShowComment] = useState(false);
  const [comment, setComment] = useState("");
  const [optionSelected, setOptionSelected] = useState({});

  const handleChangeComment = ({ target: { value } }) => {
    setComment(value);
  };
  const handleClickItem = item => {
    setComment(status === item.value ? commentStatus : "");
    setOptionSelected(item);
    setShowComment(true);
  };
  const handleUpdateComment = () => {
    const params = { status: optionSelected.value, comment_status: comment };
    onUpdateComment(params);
  };
  const handleCancelComment = () => {
    setShowComment(false);
  };

  return (
    <div className="ui-rating">
      {!showComment &&
        arrayValues.map((item, index) => {
          const { label, value, title } = item;
          const itemClassName = classNames("RatingItem", {
            editable: isEditable,
            selected: value === status,
          });
          const titleIcon =
            value === status ? `${title}: ${commentStatus || ""}` : title;
          return (
            <span
              className={itemClassName}
              key={index}
              title={titleIcon}
              onClick={() => {
                isEditable && handleClickItem(item);
              }}
            >
              {label}
            </span>
          );
        })}
      {showComment && (
        <div>
          <span className="mr-4">{optionSelected.label}</span>
          <Textbox
            mode="single"
            className="mr-4 p-1"
            style={{ color: "black" }}
            placeholder="Write a comment"
            value={comment}
            onChange={handleChangeComment}
          />
          <Button
            color="primary"
            className="mr-4"
            size="xs"
            onClick={handleUpdateComment}
          >
            <Text color="white">Save</Text>
          </Button>
          <Button color="secondary" size="xs">
            <Text color="white" onClick={handleCancelComment}>
              Cancel
            </Text>
          </Button>
        </div>
      )}
    </div>
  );
}

Rating.defaultProps = {
  arrayValues: [
    { label: "⭐", value: 1, title: "Rating 1" },
    { label: "⭐", value: 2, title: "Rating 2" },
    { label: "⭐", value: 3, title: "Rating 3" },
    { label: "⭐", value: 4, title: "Rating 4" },
    { label: "⭐", value: 5, title: "Rating 5" },
  ],
  isEditable: true,
};
