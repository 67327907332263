import React from "react";
import { Color, Corners, Frame } from "@ableco/baseline";

export default function MetricChartFormula({ children }) {
  return (
    <p>
      <strong className="pb-1 block">Formula:</strong>
      <Frame
        className="p-2 bg-opacity-60 font-monaco text-xs"
        bg={Color.Neutral700}
        border={Color.Neutral700}
        corners={Corners.SmallRounded}
        as="span"
      >
        {children}
      </Frame>
    </p>
  );
}
