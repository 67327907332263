import React from "react";
import Toggle from "components/Toggle";
import LoadingData from "components/LoadingData";
import projectQueries from "queries/projectQueries";
import { useProject } from "hooks/useProject";
import { useMutation, useQueryClient } from "react-query";
import RefetchProjectData from "../admin_settings/refetchData/RefetchProjectData";
import RefetchPullRequestsData from "../admin_settings/refetchData/RefetchPullRequestData";

export default function Other({ projectId }) {
  const { data: project, isLoading } = useProject();

  const queryClient = useQueryClient();
  const { editProject } = projectQueries();

  const { mutateAsync: updateProject } = useMutation(editProject, {
    onSuccess: () => {
      queryClient.invalidateQueries(`projects/${projectId}`);
      queryClient.invalidateQueries("projects");
    },
    onError: error => alert(error),
  });

  const onChangeVisibility = value => {
    const payload = {
      id: project.id,
      show_status: value,
    };

    updateProject(payload);
  };

  const onChangeArchived = value => {
    const payload = {
      id: project.id,
      archived: value,
    };

    updateProject(payload);
  };

  return (
    <div className="px-8 pt-4 pb-8">
      <h3 className="text-white text-xl font-bold mb-7">Other</h3>
      <div className="rounded-lg border border-gray-500 p-4 mb-4 space-y-3">
        <RefetchProjectData projectId={projectId} />
        <RefetchPullRequestsData projectId={projectId} />
      </div>

      <div className="rounded-lg border border-gray-500 p-4 mb-4">
        <h4 className="text-neutral-100 text-lg font-bold mb-4">
          Project Details
        </h4>
        {isLoading ? (
          <LoadingData />
        ) : (
          <div>
            <Toggle
              wrapperClassName="mb-4"
              prompt="Show/Hide"
              defaultValue={project?.show_status}
              onChange={onChangeVisibility}
            />
            <Toggle
              prompt="Do you want to archive this project?"
              defaultValue={project?.archived}
              onChange={onChangeArchived}
            />
          </div>
        )}
      </div>
    </div>
  );
}
