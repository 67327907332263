import React from "react";
import { Frame } from "@ableco/baseline";
import Filter from "components/Filter";
import DateFiltersProvider from "contexts/DateFiltersContext";
import PullRequestCycleTimeTotalChart from "../components/charts/PullRequestCycleTimeTotalChart";
import PullRequestsByTeamChart from "../components/charts/PullRequestsByTeamChart";
import PullRequestSizeByDateChart from "../components/charts/PullRequestSizeByDateChart";
import FirstCodeReviewTimeChart from "../components/charts/FirstCodeReviewTimeChart";
import FirstCodeReviewTimeByDateChart from "../components/charts/FirstCodeReviewTimeByDateChart";

function ContributionsChart() {
  return (
    <DateFiltersProvider>
      <Filter />
      <Frame className="mb-10">
        <PullRequestCycleTimeTotalChart />
      </Frame>
      <Frame className="mb-10">
        <PullRequestsByTeamChart />
      </Frame>
      <Frame className="mb-10">
        <PullRequestSizeByDateChart />
      </Frame>
      <Frame className="mb-10">
        <FirstCodeReviewTimeChart />
      </Frame>
      <Frame className="mb-10">
        <FirstCodeReviewTimeByDateChart />
      </Frame>
    </DateFiltersProvider>
  );
}

export default ContributionsChart;
