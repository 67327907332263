import fetchJSON from "helpers/fetchJSON";
import buildURL from "helpers/buildURL";

const createRefetchJob = project => {
  const { projectId, from, to, route = "" } = project;

  let params = { source: "fino", projectId };
  // Handling Mighty
  if (Number.parseInt(projectId) === 11) {
    params = { ...params, source: "pivotal" };
  }

  if (!!from && !!to) {
    params = { ...params, from, to };
  }

  if (route === "refetch_greenhouse_job" && !!from) {
    params = { ...params, from };
  }

  return fetchJSON(
    buildURL({
      path: `/api/${route}`,
      filter: params,
    }),
    "POST",
    {},
    false,
  );
};

const checkRefetchJob = (projectId, route = "") => {
  let params = {};
  if (projectId) params["projectId"] = projectId;

  const source = Number.parseInt(projectId) === 11 ? "pivotal" : "fino";
  params = { ...params, source };
  return fetchJSON(
    buildURL({
      path: `/api/${route}`,
      filter: params,
    }),
    "GET",
  );
};

export { createRefetchJob, checkRefetchJob };
