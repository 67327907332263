import React from "react";
import {
  Touchable,
  Text,
  Color,
  Heading,
  TextSize,
  Flex,
  Frame,
  useToggle,
} from "@ableco/baseline";
import CloseIcon from "icons/close.svg";
import InfoIcon from "icons/info.svg";

export default function BlockContainerTitle({ title, description, subtitle }) {
  const [isOpen, toggle] = useToggle(false);
  const iconClassName = "text-neutral-100 fill-current w-4 h-4";

  return (
    <>
      <Heading level={2} className="mb-6">
        <Text size={TextSize.XL3} color={Color.Neutral100}>
          <Flex
            className="w-full"
            direction="horizontal"
            distribution="between"
          >
            <span>{title}</span>
            <span>{subtitle}</span>
          </Flex>
          {description && (
            <Touchable
              onClick={toggle}
              className="ml-4 text-white align-middle rounded-full border border-neutral-100 p-1"
            >
              {isOpen ? (
                <CloseIcon className={iconClassName} />
              ) : (
                <InfoIcon className={iconClassName} />
              )}
            </Touchable>
          )}
        </Text>
      </Heading>
      {isOpen ? (
        <Frame
          className="font-lato text-neutral-100 font-light leading-normal"
          style={{ maxWidth: "50%" }}
        >
          {description}
        </Frame>
      ) : null}
    </>
  );
}
