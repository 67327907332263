import React, { Fragment, useEffect, useState } from "react";
import { Listbox } from "@headlessui/react";
import ArrowIcon from "icons/arrow.svg";

export default function ListboxPicker({
  onSelect,
  items,
  preSelectedOption,
  WrapperClassName,
  showPickerIcon = true,
  disabled = false,
  fixedSelectedText = "",
  blackTheme = false,
}) {
  const [selectedItem, setSelectedItem] = useState(items[0]);

  const handleSelect = option => {
    setSelectedItem(option);
    onSelect(option);
  };

  useEffect(() => {
    setSelectedItem(preSelectedOption || items[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preSelectedOption]);

  return (
    <div
      data-testid="listboxPicker"
      className={
        "rounded-md relative " +
        WrapperClassName +
        (blackTheme ? " bg-black text-neutral-100" : " bg-white text-black")
      }
    >
      <Listbox value={selectedItem} onChange={handleSelect} disabled={disabled}>
        <Listbox.Button className="relative w-full text-left focus:outline-none py-2 px-2">
          <div
            className={
              "flex items-center pl-0.5 " +
              (selectedItem?.text ? "justify-between" : "justify-center ml-0.5")
            }
          >
            {!!fixedSelectedText ? (
              <span className="text-gray-500 font-medium">
                {fixedSelectedText}
              </span>
            ) : (
              <span className="truncate">{selectedItem?.text}</span>
            )}
            {selectedItem?.icon}
          </div>
          {showPickerIcon && (
            <span className="absolute inset-y-0 right-0 flex items-center pr-1 pointer-events-none">
              <ArrowIcon className="text-gray-500 w-4 h-4 -rotate-90 transform mr-1" />
            </span>
          )}
        </Listbox.Button>

        <Listbox.Options
          className={
            "absolute w-full py-1 mt-1 overflow-auto text-base  rounded-md shadow-lg max-h-60 border border-neutral-700 " +
            (blackTheme ? "bg-black" : "bg-white")
          }
        >
          {items.map(item => {
            const { value, text, icon } = item;
            return (
              <Listbox.Option
                className={({ active }) =>
                  "cursor-default select-none relative p-2 flex items-center justify-between " +
                  (active ? "bg-neutral-200" : null)
                }
                key={value}
                value={item}
                as={Fragment}
              >
                {({ selected }) => (
                  <li>
                    <span
                      className={
                        "block truncate " +
                        (selected ? "font-medium" : "font-normal")
                      }
                    >
                      {text}
                    </span>
                    {icon && icon}
                  </li>
                )}
              </Listbox.Option>
            );
          })}
        </Listbox.Options>
      </Listbox>
    </div>
  );
}
