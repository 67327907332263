import buildURL from "helpers/buildURL";
import fetchJSON from "helpers/fetchJSON";

const createCommitmentUpdate = body => {
  return fetchJSON(
    buildURL({
      path: `/api/commitment_updates`,
      filter: {},
    }),
    "POST",
    body,
  );
};

const editCommitmentUpdate = body => {
  return fetchJSON(
    buildURL({
      path: `/api/commitment_updates/${body.id}`,
      filter: {},
    }),
    "PATCH",
    body,
  );
};

const deleteCommitmentUpdate = updateId => {
  return fetchJSON(
    buildURL({
      path: `/api/commitment_updates/${updateId}`,
      filter: {},
    }),
    "DELETE",
  );
};

function useCommitmentsQuery() {
  return {
    createCommitmentUpdate,
    deleteCommitmentUpdate,
    editCommitmentUpdate,
  };
}

export default useCommitmentsQuery;
