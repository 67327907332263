import React from "react";
import PullRequestsRefetchButton from "../../dashboard/refetchButton/PullRequestsRefetchButton";
import RefetchData from "./RefetchData";

export default function RefetchPullRequestsData({ projectId }) {
  return (
    <RefetchData
      projectId={projectId}
      title="Pull Request"
      RefetchButton={PullRequestsRefetchButton}
    />
  );
}
