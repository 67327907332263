import React, { useState } from "react";
import Trackers from "./Trackers";
import Metrics from "./Metrics";
import OtherSettings from "./OtherSettings";

const tabs = [
  { name: "Trackers", value: "trackers" },
  { name: "Metrics", value: "metrics" },
  { name: "Other", value: "other" },
];

export default function ProjectSettings({ projectId }) {
  const [selectedTab, setSelectedTab] = useState(tabs[0].value);

  return (
    <div className="rounded-lg bg-nord-background w-full">
      <nav className="flex space-x-2 p-4">
        {tabs.map((tab, index) => {
          const { name, value } = tab;
          return (
            <a
              key={index}
              onClick={() => setSelectedTab(value)}
              className={
                "text-white px-3 py-2 border-b-2 cursor-pointer " +
                (selectedTab === value
                  ? "border-primary-base"
                  : "border-transparent hover:border-neutral-700")
              }
            >
              {name}
            </a>
          );
        })}
      </nav>
      {selectedTab === "trackers" && <Trackers projectId={projectId} />}
      {selectedTab === "metrics" && <Metrics projectId={projectId} />}
      {selectedTab === "other" && <OtherSettings projectId={projectId} />}
    </div>
  );
}
