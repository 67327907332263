import React, { useContext } from "react";
import { DateFiltersContext } from "contexts/DateFiltersContext";
import { useParams } from "react-router-dom";
import { Text, TextSize, Color } from "@ableco/baseline";
import { BarChart } from "components/charts/Charts";
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import MetricChartContainer from "components/MetricChartContainer";
import AccordionSection from "components/AccordionSection";
import TableWithPagination from "components/TableWithPagination";
import ChartSummaryValues from "./ChartSummaryValues";
import { TableSmallText, TableLink } from "components/TableData";
import useMetricsQuery from "hooks/v2/useMetricsQuery";
import useResourcesQuery from "queries/resourcesQueries";
import dateFormat from "helpers/dateFormat";
import groupBy from "helpers/groupBy";
import { format, parse, addDays } from "date-fns";

const tableColumns = [
  {
    label: "Title",
    name: "navigable_title",
    render: ({ value }) => <TableLink value={value} />,
  },
  {
    label: "Owner",
    name: "owner",
  },
  {
    label: "Submitted at",
    name: "submitted_at",
    render: ({ value }) => <TableSmallText value={dateFormat(value)} />,
  },
  {
    label: "Closed at",
    name: "closed_at",
    render: ({ value }) => <TableSmallText value={dateFormat(value)} />,
  },
  {
    label: "Time open",
    name: "time_open",
  },
];

function formatXAxis(date, resolution) {
  switch (resolution) {
    case "weekly":
      return format(date, "EEE d");
    case "quarterly":
      const from = format(date, "MMM d");
      const to = format(addDays(date, 6), "MMM d");
      return `${from} - ${to}`;
    case "yearly":
      return format(date, "MMM");
    default:
      return format(date, "d");
  }
}

function formatTooltip(date, resolution) {
  switch (resolution) {
    case "weekly":
      return format(date, "EEE MMM do, y");
    case "quarterly":
      const from = format(date, "MMM d");
      const to = format(addDays(date, 6), "MMM d");
      return `Week: ${from} - ${to}`;
    case "yearly":
      return format(date, "MMMM, y");
    default:
      return format(date, "MMM do, y");
  }
}

export default function PullRequestsByTeamChart() {
  const { dateFilters } = useContext(DateFiltersContext);
  const { projectId } = useParams();
  const { data: metrics, isLoading } = useMetricsQuery("team_pull_requests", {
    ...dateFilters,
    projectId,
  });
  const { data: pullRequests } = useResourcesQuery("pull_requests", {
    ...dateFilters,
    projectId,
  });

  const fetchedData = metrics?.data || [];
  const totalPRs = metrics?.meta.total_prs || null;
  const resolution = dateFilters.resolution;

  const renderChartSummary = totalPRs && (
    <ChartSummaryValues
      chartSummary={[{ label: "Total PRs", value: totalPRs }]}
    />
  );

  const renderChart = (
    <BarChart data={!!totalPRs && fetchedData}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="name"
        type="category"
        tickFormatter={tickItem => {
          const tickDate = parse(tickItem, "yyyy-MM-dd", new Date());
          return formatXAxis(tickDate, resolution);
        }}
      />
      <YAxis type="number" />
      <Tooltip
        cursor={{ fill: "transparent" }}
        labelFormatter={name => {
          const tooltipDate = parse(name, "yyyy-MM-dd", new Date());
          return formatTooltip(tooltipDate, resolution);
        }}
      />
      <Legend />
      <Bar dataKey="count" name="Authored PRs" fill="#ecb43d" maxBarSize={48} />
    </BarChart>
  );

  let pullRequestGroups = groupBy(
    pullRequests?.data.map(pr => pr.attributes),
    result => result["repo_name"],
  );

  const renderPullRequestsTables = Object.keys(pullRequestGroups)?.map(
    repo_name => {
      return (
        <AccordionSection key={repo_name} title={repo_name}>
          <TableWithPagination
            columns={tableColumns}
            data={pullRequestGroups[repo_name]}
          />
        </AccordionSection>
      );
    },
  );

  const renderDescription = (
    <p>This graph shows how many pull requests were closed each day.</p>
  );

  return (
    <MetricChartContainer
      data={!!totalPRs && fetchedData}
      isLoading={isLoading}
      title="Pull Requests by Team"
      description={renderDescription}
    >
      {renderChartSummary}
      {renderChart}
      <Text size={TextSize.XL} color={Color.Neutral100}>
        Included pull requests
      </Text>
      {renderPullRequestsTables}
    </MetricChartContainer>
  );
}
