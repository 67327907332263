import {
  getQuarter,
  subWeeks,
  subMonths,
  subQuarters,
  subYears,
  addDays,
  addWeeks,
  addMonths,
  addQuarters,
  addYears,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfQuarter,
  endOfQuarter,
  startOfYear,
  endOfYear,
  isBefore,
  isEqual,
} from "date-fns";
import dateRange445 from "./calendar445";

//first day to count 445 calendar
const january31 = new Date(2021, 0, 31);

export function dateRange({ resolution, date = new Date() }) {
  const shouldUse445Calendar =
    date.getFullYear() >= 2021 && ["quarterly", "yearly"].includes(resolution);

  if (shouldUse445Calendar) {
    return dateRange445({ resolution, date });
  }

  switch (resolution) {
    case "yearly":
      if (date.getFullYear() === 2020) {
        return [startOfYear(date), addDays(endOfYear(date), 30)];
      }

      return [startOfYear(date), endOfYear(date)];
    case "quarterly":
      return getQuarterlyRangeByDate(date);
    case "monthly":
      return [startOfMonth(date), endOfMonth(date)];
    case "weekly":
      return [startOfWeek(date, { weekStartsOn: 0 }), endOfWeek(date)];
  }
}

function getQuarterlyRangeByDate(date) {
  if (date.getFullYear() === 2020 && getQuarter(date) === 4) {
    return [startOfQuarter(date), addDays(endOfQuarter(date), 30)];
  }

  return [startOfQuarter(date), endOfQuarter(date)];
}

export function currentDateRange({ resolution }) {
  const date = new Date();

  return dateRange({ resolution, date });
}

export function previousDateRange({ from, resolution }) {
  let date = from;

  switch (resolution) {
    case "yearly":
      date = subYears(date, 1);
      break;
    case "quarterly":
      if (isBefore(january31, date)) date = subWeeks(date, 13);
      else date = subQuarters(date, 1);
      break;
    case "monthly":
      date = subMonths(date, 1);
      break;
    case "weekly":
      date = subWeeks(date, 1);
      break;
  }

  return dateRange({ resolution, date });
}

export function nextDateRange({ from, resolution }) {
  let date = from;

  switch (resolution) {
    case "yearly":
      date = addYears(date, 1);
      break;
    case "quarterly":
      if (isBefore(january31, date) || isEqual(january31, date))
        date = addWeeks(date, 14);
      else date = addQuarters(date, 1);
      break;
    case "monthly":
      date = addMonths(date, 1);
      break;
    case "weekly":
      date = addWeeks(date, 1);
      break;
  }

  return dateRange({ resolution, date });
}
