import React from "react";
import UncaughtExceptionsRefetchButton from "../../dashboard/refetchButton/UncaughtExceptionsButton";
import RefetchData from "./RefetchData";

export default function RefetchUncaughtExceptionsData({ projectId = null }) {
  return (
    <RefetchData
      projectId={projectId}
      title="Uncaught Exceptions"
      RefetchButton={UncaughtExceptionsRefetchButton}
    />
  );
}
