import React from "react";
import ReactTooltip from "react-tooltip";
import IndicatorIcon from "icons/circle.svg";
import InfoCircleIcon from "icons/info-circle.svg";

export default function StatCard({ stat, className }) {
  const {
    firstValue,
    firstUnit,
    secondValue,
    secondUnit,
    thresholdColor,
    name,
    tooltipContent,
  } = stat;
  return (
    <div
      className={`${className} flex flex-col rounded-lg bg-neutral-800 pt-4 pb-2`}
      style={{ width: "175px" }}
    >
      <div className="flex pb-2 px-2">
        <div
          style={{ minHeight: "40px" }}
          className="flex-1 text-neutral-200 text-sm font-medium text-center mr-1"
        >
          {name}
        </div>
        {
          <InfoCircleIcon
            data-tip
            data-for={name}
            className="fill-current text-neutral-200 focus:outline-none"
          />
        }

        {tooltipContent && (
          <ReactTooltip id={name} backgroundColor="#FFFFFF">
            {tooltipContent}
          </ReactTooltip>
        )}
      </div>

      <div className="flex-1 flex items-start py-2 m-auto text-center">
        <div className="text-white font-semibold">
          <span className="text-2xl mr-1 text-neutral-100">
            {firstValue == null ? "-" : firstValue}
            <span className="text-sm ml-0.5">
              {firstValue != null && firstUnit}
            </span>
          </span>
          {secondValue != null && (
            <span className="text-2xl mr-1 text-neutral-100">
              {`/ ${secondValue}`}
              <span className="text-sm ml-0.5">{secondUnit}</span>
            </span>
          )}
        </div>
      </div>

      <div style={{ minHeight: "16px" }} className="indicator px-2">
        {firstValue != null && thresholdColor && (
          <IndicatorIcon
            width="16px"
            height="16px"
            className={`text-indicator-${thresholdColor} fill-current ml-auto`}
          />
        )}
      </div>
    </div>
  );
}
