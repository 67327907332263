import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { BarChart } from "components/charts/Charts";

import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import { DateFiltersContext } from "contexts/DateFiltersContext";
import MetricChartContainer from "components/MetricChartContainer";
import LinkableAxisTick from "components/charts/LinkableAxisTick";
import useMetricsQuery from "hooks/v2/useMetricsQuery";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length !== 0) {
    return (
      <div
        style={{
          borderColor: "rgb(204, 204, 204)",
        }}
        className="p-3 bg-white border"
      >
        <p style={{ color: "#000000" }}>
          {payload[0].payload.pr_tracker} - #{`${payload[0].payload.pr_number}`}
        </p>
        <p
          style={{ color: "#2D8337" }}
        >{`${payload[0].name} : ${payload[0].value}`}</p>
        <p
          style={{ color: "#C6222F" }}
        >{`${payload[1].name} : ${payload[1].value}`}</p>
      </div>
    );
  }

  return null;
};

export default function PullRequestSizeChart() {
  const { projectId, userId } = useParams();
  const { dateFilters } = useContext(DateFiltersContext);
  const { data: metrics, isLoading } = useMetricsQuery("pull_request_size", {
    ...dateFilters,
    projectId,
    userId,
  });
  const fetchedData = metrics?.data.filter(d => d.lines_added !== null) || [];
  const renderDescription = (
    <p className="pb-2">
      This chart shows{" "}
      <strong>the number of lines added and deleted per pull request.</strong>
    </p>
  );

  const renderChart = (
    <BarChart data={fetchedData}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="pr_number"
        tick={<LinkableAxisTick data={fetchedData} />}
      />
      <YAxis />
      <Tooltip content={<CustomTooltip />} cursor={{ fill: "transparent" }} />
      <Legend wrapperStyle={{ position: "relative" }} />
      <Bar
        dataKey="lines_added"
        name="Lines added"
        stackId="pull_request_size"
        fill="#2D8337"
        maxBarSize={48}
      />
      <Bar
        dataKey="lines_deleted"
        name="Lines deleted"
        stackId="pull_request_size"
        fill="#C6222F"
        maxBarSize={48}
      />
    </BarChart>
  );

  return (
    <MetricChartContainer
      data={fetchedData}
      isLoading={isLoading}
      description={renderDescription}
      title="Pull Request Size"
    >
      {renderChart}
    </MetricChartContainer>
  );
}
