import React from "react";
import { Frame } from "@ableco/baseline";
import Filter from "components/Filter";
import DateFiltersProvider from "contexts/DateFiltersContext";
import PitchCycleTimeChart from "components/charts/PitchCycleTimeChart";
import PitchDefinitionTimeChart from "components/charts/PitchDefinitionTimeChart";
import ProjectsDeliveredOnTimeChart from "components/charts/ProjectsDeliveredOnTimeChart";
import ProjectDeliveryDeviationAverageChart from "components/charts/ProjectDeliveryDeviationAverageChart";
import ProjectThroughputChart from "components/charts/ProjectThroughputChart";

function PerformanceChart() {
  return (
    <DateFiltersProvider>
      <Filter />
      <Frame className="mb-10">
        <PitchCycleTimeChart />
      </Frame>
      <Frame className="mb-10">
        <PitchDefinitionTimeChart />
      </Frame>
      <Frame className="mb-10">
        <ProjectsDeliveredOnTimeChart />
      </Frame>
      <Frame className="mb-10">
        <ProjectDeliveryDeviationAverageChart />
      </Frame>
      <Frame className="mb-10">
        <ProjectThroughputChart />
      </Frame>
    </DateFiltersProvider>
  );
}

export default PerformanceChart;
