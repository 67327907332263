import React, { useState, useEffect } from "react";
import Modal from "components/Modal";
import Button from "components/FilterButton";
import useCommitmentsQuery from "hooks/v2/useCommitmentsQuery";
import { useMutation, useQueryClient } from "react-query";
import { isEmpty } from "lodash";

const formDescription = {
  deployment: "Fill out the form to add an update for Last Week Deployments.",
  current: "Fill out the form to add an update for Current Week Commitments.",
};

function invalidateQueries(queryClient, dateFilters, projectId) {
  queryClient.invalidateQueries([
    "/api/**/**/commitments",
    { ...dateFilters, projectId },
  ]);
}

export default function CommitmentForm({
  open,
  setOpen,
  commitmentType,
  dateFilters,
  projectId,
  isEditing = false,
  commitment,
}) {
  const [updateTitle, setUpdateTitle] = useState("");
  const [updateUrl, setUpdateUrl] = useState("");
  const [validUrl, setValidUrl] = useState(true);
  const urlFormat = /(^https?:\/{2})/;

  const queryClient = useQueryClient();
  const {
    createCommitmentUpdate,
    editCommitmentUpdate,
  } = useCommitmentsQuery();

  const { mutateAsync: addUpdate } = useMutation(createCommitmentUpdate, {
    onSuccess: () => invalidateQueries(queryClient, dateFilters, projectId),
  });

  const { mutateAsync: editUpdate } = useMutation(editCommitmentUpdate, {
    onSuccess: () => invalidateQueries(queryClient, dateFilters, projectId),
  });

  useEffect(() => {
    if (isEditing) {
      setUpdateTitle(commitment.title);
      setUpdateUrl(commitment?.url);
    }
  }, [commitment, isEditing]);

  useEffect(() => {
    setValidUrl(!isEmpty(updateUrl) && !updateUrl.match(urlFormat));
  }, [updateUrl, urlFormat]);

  const handleOpenForm = isOpen => {
    setOpen(isOpen);

    if (!isOpen && !isEditing) {
      setUpdateTitle("");
      setUpdateUrl("");
    }
  };

  const handleSubmit = () => {
    const payload = {
      id: commitment?.id,
      project_id: projectId,
      week_on: dateFilters.from,
      title: updateTitle,
      url: updateUrl,
      update_type: commitmentType,
    };

    if (isEditing) {
      editUpdate(payload).then(() => {
        handleOpenForm(false);
      });
    } else {
      addUpdate(payload).then(() => {
        handleOpenForm(false);
      });
    }
  };

  const formContent = (
    <div>
      <label className="block text-primary-light font-medium">Title</label>
      <input
        type="text"
        className="focus:outline-none rounded-md w-full my-2 p-2"
        placeholder="Enter title"
        onChange={({ target }) => setUpdateTitle(target.value)}
        value={updateTitle}
      />
      <label className="block pt-2 text-primary-light font-medium">URL</label>
      <input
        type="text"
        className="focus:outline-none rounded-md w-full my-2 p-2"
        placeholder="e.g. https://fino.able.co/products/99/projects/9999"
        onChange={({ target }) => setUpdateUrl(target.value.trim())}
        value={updateUrl}
      />
      {validUrl && (
        <p className="text-sm text-alert-light">Enter a valid URL</p>
      )}
    </div>
  );

  const formActions = (
    <div className="flex justify-between">
      <Button
        className="h-10 rounded-md w-full sm:w-auto"
        onClick={() => handleOpenForm(false)}
      >
        Cancel
      </Button>
      <Button
        disabled={isEmpty(updateTitle) || validUrl}
        className="h-10 rounded-md w-full sm:w-auto"
        active={!isEmpty(updateTitle) && !validUrl}
        onClick={handleSubmit}
      >
        Save
      </Button>
    </div>
  );
  return (
    <Modal
      title="Add Update"
      subtitle={formDescription[commitmentType]}
      content={formContent}
      footer={formActions}
      open={open}
      setOpen={handleOpenForm}
    />
  );
}
