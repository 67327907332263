import React from "react";
import StatCard from "../StatCard";
import ProjectRefetchButton from "components/dashboard/refetchButton/ProjectRefetchButton";
import classNames from "classnames";
import { buildStatsInfo } from "./config";
import { isEmpty } from "lodash";

function QuarterMetrics({ kpiRangeLabel, statValues, projectId, project }) {
  const stats = buildStatsInfo(statValues, project);
  return (
    <>
      <div className="flex mb-4 justify-between">
        <div
          className={classNames("flex items-center text-neutral-100", {
            hidden: isEmpty(stats),
          })}
        >
          <h2
            style={{ minWidth: "175px" }}
            className="text-xl leading-6 font-medium mr-6"
          >
            Quarter Metrics
          </h2>
          <div className="rounded-md bg-neutral-800 mr-5 py-2 px-6 text-center">
            {kpiRangeLabel}
          </div>
        </div>
        <ProjectRefetchButton
          className={isEmpty(stats) ? "ml-auto" : ""}
          projectId={projectId}
        />
      </div>

      <div className="flex flex-wrap">
        {stats.map((stat, index) => (
          <StatCard key={index} stat={stat} className="mb-2 mr-2" />
        ))}
      </div>
    </>
  );
}

export default function DashboardHeader({
  project = {},
  statValues = {},
  kpiRangeLabel,
  projectId,
}) {
  return (
    <div className="border-b border-neutral-700">
      <QuarterMetrics
        project={project}
        kpiRangeLabel={kpiRangeLabel}
        statValues={statValues}
        projectId={projectId}
      />
    </div>
  );
}
