import React from "react";

function LinkableAxisTick(props) {
  const { x, y, fill, textAnchor, payload, data } = props;

  if (data[payload.index]?.url) {
    return (
      <a
        href={data[payload.index]?.url}
        target="_blank"
        className="hover:underline"
      >
        <text x={x} y={y} dy={16} textAnchor={textAnchor} fill={fill}>
          {payload.value}
        </text>
      </a>
    );
  }

  return (
    <text x={x} y={y} dy={16} textAnchor={textAnchor} fill={fill}>
      {payload.value}
    </text>
  );
}

export default LinkableAxisTick;
