import React from "react";
import DeleteIcon from "icons/delete.svg";
import CancelIcon from "icons/cancel.svg";

export default function InlineDeleteConfirmation({ onCancel, onDelete }) {
  return (
    <div className="flex items-center">
      <CancelIcon
        className="text-neutral-200 fill-current flex-shrink-0 cursor-pointer ml-1.5"
        data-testid="cancelDelete"
        onClick={onCancel}
      />
      <DeleteIcon
        className="text-alert-light fill-current flex-shrink-0 cursor-pointer ml-1.5"
        data-testid="confirmDelete"
        onClick={onDelete}
      />
    </div>
  );
}
