import React, { useContext, useMemo } from "react";
import classNames from "classnames";
import ProfileButton from "components/ProfileButton";
import Logo from "components/Logo";
import LogoMetricsIcon from "icons/logo-metrics-icon.svg";
import { ProjectsContext } from "contexts/ProjectsContext";
import { buildMenus } from "./config";
import Menu from "components/menu/Menu";
import useDOMState from "hooks/useDOMState";
import { Inline } from "@ableco/baseline";

function Sidebar({ isExpanded, setIsSidebarExpanded, setNavigationList }) {
  const { projects } = useContext(ProjectsContext);
  const [{ currentUser }] = useDOMState("root", {});
  const menus = useMemo(() => buildMenus(projects, currentUser), [
    projects,
    currentUser,
  ]);
  const sidebarClassName = classNames(
    "ui-sidebar",
    "bg-neutral-800 flex-shrink-0 flex flex-col border-r",
    "transition-all duration-300 p-2 pr-1",
    {
      expanded: isExpanded,
      collapsed: !isExpanded,
      "w-64": isExpanded,
      "w-14": !isExpanded,
    },
  );

  return (
    <aside className={sidebarClassName}>
      <div className="my-5">
        <Inline>
          {isExpanded ? (
            <Logo size="MD" />
          ) : (
            <LogoMetricsIcon width="43" height="40" />
          )}
        </Inline>
      </div>
      <nav
        data-testid="sidebarNavigation"
        className="flex-1 flex flex-col text-neutral-100 overflow-y-auto pb-4"
      >
        <Menu
          items={menus}
          showMenuText={isExpanded}
          setNavigationList={setNavigationList}
          setIsSidebarExpanded={setIsSidebarExpanded}
        />
      </nav>
      <ProfileButton showName={isExpanded} />
    </aside>
  );
}

export default Sidebar;
