import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { BarChart } from "components/charts/Charts";
import {
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { DateFiltersContext } from "contexts/DateFiltersContext";
import MetricChartContainer from "components/MetricChartContainer";
import ChartSummaryValues from "./ChartSummaryValues";
import useMetricsQuery from "hooks/v2/useMetricsQuery";
import MetricChartFormula from "components/charts/MetricChartFormula";

const COLORS = {
  bug: "#c0392b",
  pitch: "#9b59b6",
  tweak: "#1abc9c",
  chore: "#e67e22",
  story: "#3498db",
};

function tooltipFormatter(value, label) {
  const duration = `${value.toFixed(2)} days`;
  return [duration, label];
}

export default function ProjectDeliveryDeviationAverageChart() {
  const { projectId, userId } = useParams();
  const { dateFilters } = useContext(DateFiltersContext);
  const { data: metrics, isLoading } = useMetricsQuery(
    "project_delivery_deviation_average",
    {
      ...dateFilters,
      projectId,
      userId,
    },
  );

  const fetchedData = metrics?.data || [];
  const renderDescription = (
    <>
      <p className="pb-2">
        This chart shows{" "}
        <strong>
          the average deviation time per type of project (bugs, chores, tweaks
          or pitches)
        </strong>
        .
      </p>

      <MetricChartFormula>
        {"average(business_days_between(scheduled_end_date, deployed_at))"}
      </MetricChartFormula>

      <p className="pt-4">
        <strong>Note:</strong> All calculations are made based on bussiness
        days.
      </p>
    </>
  );

  const renderChartSummary = (
    <ChartSummaryValues
      chartSummary={fetchedData.map(entry => ({
        label: `${entry.label} Deviation Average`,
        value: `${entry.value.toFixed(2)} days`,
      }))}
    />
  );

  const renderChart = (
    <BarChart data={fetchedData}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="label" />
      <YAxis />
      <Tooltip cursor={{ fill: "transparent" }} formatter={tooltipFormatter} />
      <Legend wrapperStyle={{ position: "relative" }} />
      <Bar
        dataKey="value"
        name="Deviation Average"
        fill="#7f8c8d"
        maxBarSize={48}
      >
        {fetchedData.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={COLORS[entry.label.toLowerCase()]}
          />
        ))}
      </Bar>
    </BarChart>
  );

  return (
    <MetricChartContainer
      data={fetchedData}
      isLoading={isLoading}
      title="Project Delivery Deviation Average"
      description={renderDescription}
    >
      {renderChartSummary}
      {renderChart}
    </MetricChartContainer>
  );
}
