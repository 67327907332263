import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";

import { BarChart } from "components/charts/Charts";
import { DateFiltersContext } from "contexts/DateFiltersContext";
import MetricChartContainer from "components/MetricChartContainer";
import LinkableAxisTick from "components/charts/LinkableAxisTick";
import { useMetricsQuery } from "hooks/useMetricsQuery";
import ChartSummaryValues from "./ChartSummaryValues";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length !== 0) {
    return (
      <div
        style={{
          borderColor: "rgb(204, 204, 204)",
        }}
        className="p-3 bg-white border"
      >
        <p style={{ color: "#000000" }}>
          {payload[0].payload.pr_tracker} - #{`${payload[0].payload.pr_number}`}
        </p>
        <p
          style={{ color: "#2E71A6" }}
        >{`${payload[0].name} : ${payload[0].payload.first_code_review_time_words}`}</p>
      </div>
    );
  }

  return null;
};

export default function FirstCodeReviewTimeChart() {
  const { projectId, userId } = useParams();
  const { dateFilters } = useContext(DateFiltersContext);
  const { data: metrics, isLoading } = useMetricsQuery(
    "first_code_review_time",
    {
      ...dateFilters,
      projectId,
      userId,
    },
  );
  const fetchedData = metrics?.data || [];
  const mean = `${metrics?.first_code_review_time_mean?.toFixed(2)} days`;
  const standardDeviation = `${metrics?.first_code_review_time_standard_deviation?.toFixed(
    2,
  )} days`;
  const renderDescription = (
    <p className="pb-2">
      This chart shows{" "}
      <strong>
        the time passed since a pull request was created until it was code
        reviewed for first time
      </strong>
      .
    </p>
  );

  const renderChartSummary = fetchedData && (
    <ChartSummaryValues
      chartSummary={[
        { label: "Average", value: mean },
        { label: "Standard Deviation", value: standardDeviation },
      ]}
    />
  );

  const renderChart = (
    <BarChart data={fetchedData.filter(d => d.lines_added !== null) || []}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="pr_number"
        tick={
          <LinkableAxisTick
            data={fetchedData.filter(d => d.lines_added !== null) || []}
          />
        }
      />
      <YAxis />
      <Tooltip content={<CustomTooltip />} cursor={{ fill: "transparent" }} />
      <Legend wrapperStyle={{ position: "relative" }} />
      <Bar
        dataKey="first_code_review_time"
        name="Time to first code review"
        stackId="first_code_review_time"
        fill="#2E71A6"
        maxBarSize={48}
      />
    </BarChart>
  );

  return (
    <MetricChartContainer
      data={fetchedData}
      isLoading={isLoading}
      title="Time to First Code Review"
      description={renderDescription}
    >
      {renderChartSummary}
      {renderChart}
    </MetricChartContainer>
  );
}
