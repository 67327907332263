import React, { useContext, useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { BarChart } from "components/charts/Charts";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  Legend,
} from "recharts";
import { Flex, Frame } from "@ableco/baseline";
import { startCase, camelCase, isEmpty } from "lodash";
import { DateFiltersContext } from "contexts/DateFiltersContext";
import MetricChartContainer from "components/MetricChartContainer";
import Checkbox from "components/Checkbox";
import { useMetricsQuery } from "hooks/v2/useMetricsQuery";

const metricsColors = {
  stories: "#9471f7",
  points: "#5aa371",
  pullRequests: "#bf9130",
  codeReviews: "#0294d7",
};
const yAxisSettings = {
  ticksThreshold: 13,
  heightRatio: 40,
};

function CustomYAxis(props) {
  let text = props.payload.value;
  if (text.length > 25) text = text.slice(0, 21) + "...";

  return (
    <g transform={`translate(${props.x},${props.y})`}>
      <text x={0} y={0} textAnchor="end" fill="#666">
        {text}
      </text>
    </g>
  );
}

export default function CurrentTeamMetricsSummaryChart() {
  const [team, setTeam] = useState(null);
  const [chartHeight, setChartHeight] = useState(null);

  const { projectId } = useParams();
  const { dateFilters } = useContext(DateFiltersContext);
  const { data: metrics, isLoading } = useMetricsQuery(
    "current_team_metrics_summary",
    {
      ...dateFilters,
      projectId,
    },
  );

  const fetchedData = metrics?.data || [];
  const renderDescription = (
    <p>
      This chart how much time and work developers are investing in a software
      project.{" "}
      <strong>
        It is a comparative chart that illustrates how the workload is
        distributed among team members.
      </strong>{" "}
      It provides a more in-depth look at the activities of each member of the
      development team and looks for improvement opportunities.
    </p>
  );

  useEffect(() => {
    setTeam(metrics?.team);
  }, [metrics]);

  const chartData = useMemo(() => {
    if (team) {
      const selectedTeam = team.map(eng => (eng.checked ? eng.name : null));
      return fetchedData.filter(data =>
        selectedTeam.includes(data.engineer_name),
      );
    }
  }, [team, fetchedData]);

  const metricsTotals = useMemo(() => {
    if (chartData) {
      const accumulatedValues = chartData.reduce(function(accumulator, item) {
        Object.keys(item).forEach(function(key) {
          accumulator[key] = (accumulator[key] || 0) + item[key];
        });
        return accumulator;
      }, {});

      return {
        stories:
          accumulatedValues.stories +
          accumulatedValues.bugs +
          accumulatedValues.chores +
          accumulatedValues.tweaks +
          accumulatedValues.pitches,
        points: accumulatedValues.points,
        pullRequests: accumulatedValues.pull_requests,
        codeReviews: accumulatedValues.code_reviews,
      };
    }
  }, [chartData]);

  useEffect(() => {
    if (chartData?.length > yAxisSettings.ticksThreshold) {
      setChartHeight(chartData.length * yAxisSettings.heightRatio);
    }
  }, [chartData]);

  const metricsAverages = useMemo(() => {
    if (!isEmpty(chartData)) {
      return {
        stories: (metricsTotals.stories / chartData.length).toFixed(2),
        points: (metricsTotals.points / chartData.length).toFixed(2),
        pullRequests: (metricsTotals.pullRequests / chartData.length).toFixed(
          2,
        ),
        codeReviews: (metricsTotals.codeReviews / chartData.length).toFixed(2),
      };
    }
    return {
      stories: 0,
      points: 0,
      pullRequests: 0,
      codeReviews: 0,
    };
  }, [chartData, metricsTotals]);

  const handleCheckboxChange = event => {
    const { name, checked } = event.target;

    setTeam(
      team.map(eng => (eng.name === name ? { ...eng, checked: checked } : eng)),
    );
  };

  const renderTeam = team?.map((eng, index) => (
    <Frame key={index} as="article" className="w-full md:w-1/2 lg:w-1/3 mb-6">
      <Checkbox
        name={eng.name}
        prompt={eng.name}
        checked={eng.checked}
        onChange={handleCheckboxChange}
      />
    </Frame>
  ));

  const renderChart = (
    <BarChart layout="vertical" data={chartData} height={chartHeight}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis type="number" tickCount="6" />
      <YAxis
        dataKey="engineer_name"
        type="category"
        width={isEmpty(chartData) ? 60 : 200}
        tick={CustomYAxis}
      />
      <Tooltip cursor={{ fill: "transparent" }} />
      <Legend
        verticalAlign="top"
        wrapperStyle={{
          paddingBottom: "14px",
        }}
        payload={Object.entries(metricsAverages)?.map(([key, value]) => ({
          type: "rect",
          value: `${startCase(camelCase(key))} Avg: ${value}`,
          color: metricsColors[key],
        }))}
      />
      <Bar
        dataKey="stories"
        name="Stories"
        stackId="stories_group"
        fill={metricsColors["stories"]}
      />
      <Bar
        dataKey="bugs"
        name="Bugs"
        stackId="stories_group"
        fill={metricsColors["stories"]}
      />
      <Bar
        dataKey="chores"
        name="Chores"
        stackId="stories_group"
        fill={metricsColors["stories"]}
      />
      <Bar
        dataKey="tweaks"
        name="Tweaks"
        stackId="stories_group"
        fill={metricsColors["stories"]}
      />
      <Bar
        dataKey="pitches"
        name="Pitches"
        stackId="stories_group"
        fill={metricsColors["stories"]}
      />
      <Bar dataKey="points" name="Points" fill={metricsColors["points"]} />
      <Bar
        dataKey="pull_requests"
        name="Pull Requests"
        fill={metricsColors["pullRequests"]}
      />
      <Bar
        dataKey="code_reviews"
        name="Code Reviews"
        fill={metricsColors["codeReviews"]}
      />
      {Object.entries(metricsAverages)?.map(([key, value]) => (
        <ReferenceLine
          key={key}
          x={value}
          stroke={metricsColors[key]}
          strokeDasharray="5 5"
          strokeWidth="4"
        />
      ))}
    </BarChart>
  );
  return (
    <MetricChartContainer
      data={metrics?.data}
      isLoading={isLoading}
      title="Current Team Metrics Summary"
      description={renderDescription}
    >
      {renderChart}
      <Flex as="section" wrap>
        {renderTeam}
      </Flex>
    </MetricChartContainer>
  );
}
