import React from "react";
import { Touchable, Frame, useToggle } from "@ableco/baseline";
import ArrowIcon from "icons/arrow.svg";

export default function AccordionSection({
  title,
  children,
  openByDefault = false,
}) {
  const [isOpen, toggle] = useToggle(openByDefault);

  return (
    <Frame>
      <Touchable
        onClick={toggle}
        className="text-neutral-100 flex p-1 items-center"
      >
        {isOpen ? (
          <>
            <ArrowIcon className="w-4 h-4 -rotate-90 transform mr-1" />
            {title}
          </>
        ) : (
          <>
            <ArrowIcon className="w-4 h-4 rotate-180 transform mr-1" />
            {title}
          </>
        )}
      </Touchable>
      {isOpen ? children : null}
    </Frame>
  );
}
