import React, { useContext, useState } from "react";
import { ComposedChart } from "components/charts/Charts";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  LabelList,
} from "recharts";
import MetricChartContainer from "components/MetricChartContainer";
import { useMetricsQuery } from "hooks/useMetricsQuery";
import { DateFiltersContext } from "contexts/DateFiltersContext";
import { renderCustomBarLabel } from "helpers/customBarLabel";
import JobMappingSelector from "./JobMappingSelector";

export default function ApplicantCycleTimeChart({ minimal = false }) {
  const [jobIds, setJobsIds] = useState("");
  const { dateFilters } = useContext(DateFiltersContext);
  const { data: metrics, isLoading } = useMetricsQuery(
    "recruitment/applicant_cycle_time",
    {
      ...dateFilters,
      job_ids: jobIds,
    },
  );

  const fetchedData = metrics?.data || [];
  const { avg_days, standard_deviation, total_candidates } =
    metrics?.meta || {};

  const renderChart = (
    <ComposedChart
      data={fetchedData}
      height={minimal && 280}
      margin={{ top: 20, right: 30, left: 0, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="days" type="category" />
      <YAxis type="number" />
      <Tooltip cursor={{ fill: "transparent" }} />
      {!minimal && <Legend />}
      <Bar
        dataKey="candidates"
        name="# candidates"
        fill="#9370f7"
        maxBarSize={48}
      >
        <LabelList dataKey="candidates" content={renderCustomBarLabel} />
      </Bar>
      <Line
        dataKey="distribution"
        name="Distribution"
        type="monotone"
        stroke="#c0392b"
        strokeWidth={3}
      />
    </ComposedChart>
  );
  return (
    <MetricChartContainer
      data={fetchedData}
      isLoading={isLoading}
      title={!minimal && "Applicant Cycle Time"}
      minimal={minimal}
      resolution={dateFilters.resolution}
      filterComponent={<JobMappingSelector onChangeJobIds={setJobsIds} />}
    >
      <p className="text-white">
        <span className="font-bold">Average days: </span>
        <span>{avg_days}</span>
      </p>
      <p className="text-white">
        <span className="font-bold">Standard deviation: </span>
        <span>{standard_deviation}</span>
      </p>
      <p className="text-white">
        <span className="font-bold">Total candidates: </span>
        <span>{total_candidates}</span>
      </p>
      {renderChart}
    </MetricChartContainer>
  );
}
