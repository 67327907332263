import React, { useState, Fragment } from "react";
import { Inline } from "@ableco/baseline";
import { Link, useLocation } from "react-router-dom";
import OverviewLogo from "icons/overview.svg";
import ArrowIcon from "icons/arrow.svg";
import classNames from "classnames";
import ProjectLogo from "components/ProjectLogo";

function MenuPopover({
  menu,
  showMenuText,
  currentPath,
  setIsSidebarExpanded,
  isExpanded,
  handleChangeExpanded,
}) {
  const menuItems = menu.items.map(menuItem => ({
    label: menuItem.title,
    value: menuItem,
    icon: <OverviewLogo className="mr-4" />,
  }));
  const avatarIcon = (
    <ProjectLogo
      project={{ logo_path: menu.icon_url, name: menu.title }}
      size="XS"
    />
  );
  const menuIcon = menu.componentIcon ? <menu.componentIcon /> : avatarIcon;
  const isActive = currentPath.includes(`/project/${menu.id}/`);
  return (
    <>
      <li
        className={`cursor-pointer ${
          isActive ? "bg-primary-base rounded-md" : ""
        }`}
        onClick={() => {
          handleChangeExpanded(menu);
          if (!showMenuText) setIsSidebarExpanded(true);
        }}
      >
        <div className={`flex flex-wrap items-center justify-between w-full`}>
          {isExpanded ? (
            <>
              <div className="flex flex-wrap gap-2 items-center">
                {menuIcon}
                {showMenuText && menu.title.slice(0, 20)}
              </div>
              {showMenuText && (
                <ArrowIcon className="w-4 h-4 -rotate-90 transform mr-1" />
              )}
            </>
          ) : (
            <>
              <div className="flex flex-wrap gap-2 items-center">
                {menuIcon}
                {showMenuText && menu.title.slice(0, 20)}
              </div>
              {showMenuText && (
                <ArrowIcon className="w-4 h-4 rotate-180 transform mr-1" />
              )}
            </>
          )}
        </div>
      </li>
      {isExpanded && showMenuText && (
        <ul className="ml-4 mr-2 mt-2">
          {menuItems.map((item, index) => (
            <Link key={index} to={item.value.path}>
              <li className="py-1 hover:underline rounded-md">
                <span className="flex flex-wrap">
                  {item.icon}
                  {item.label}
                </span>
              </li>
            </Link>
          ))}
        </ul>
      )}
    </>
  );
}

function MenuItem({ menu, onClick, showMenuText, currentPath, children }) {
  const ComponentItem = menu.path ? Link : Fragment;
  const componentItemProps = {};
  if (menu.path) {
    componentItemProps["to"] = menu.path;
  }
  const menuItemClassName = classNames("ui-menuitem cursor-pointer", {
    selected: menu.path && currentPath === menu.path,
  });

  const avatarIcon = (
    <ProjectLogo
      project={{ logo_path: menu.icon_url, name: menu.title }}
      size="XS"
    />
  );

  const menuIcon = menu.componentIcon ? <menu.componentIcon /> : avatarIcon;

  return (
    <li className={menuItemClassName} onClick={onClick}>
      <ComponentItem {...componentItemProps}>
        <Inline className="flex justify-between">
          <div className="flex items-center w-full">
            <div className="menu-icon">{menuIcon}</div>
            {showMenuText && (
              <span className="ml-3 truncate">{menu.title}</span>
            )}
          </div>
          <div>{showMenuText && children}</div>
        </Inline>
      </ComponentItem>
    </li>
  );
}

function MenuGroup({
  menu,
  showMenuText,
  currentPath,
  isExpanded,
  handleChangeExpanded,
  setIsSidebarExpanded,
}) {
  const subItems = menu.items;
  const initialMenuState = {};
  subItems.forEach(item => {
    initialMenuState[item.title] = false;
  });
  const [menuState, setMenuState] = useState(initialMenuState);
  const handleChangeState = item => {
    setMenuState({
      ...initialMenuState,
      [item.title]: showMenuText ? !menuState[item.title] : true,
    });
  };
  return (
    <>
      <MenuItem
        menu={menu}
        showMenuText={showMenuText}
        onClick={() => {
          handleChangeExpanded(menu);
        }}
        currentPath={currentPath}
      >
        <ArrowIcon
          className={
            "text-neutral-200 w-6 h-6 cursor-pointer ml-2 transition transform " +
            (isExpanded ? "-rotate-90" : "rotate-180")
          }
        />
      </MenuItem>
      {isExpanded && (
        <ul className="ui-menugroup list-none">
          {menu.items.map((menuItem, index) => {
            if (!menuItem.items || !menuItem.showOptions) {
              return (
                <MenuItem
                  key={index}
                  menu={menuItem}
                  showMenuText={showMenuText}
                  currentPath={currentPath}
                />
              );
            } else {
              return (
                <MenuPopover
                  key={index}
                  menu={menuItem}
                  showMenuText={showMenuText}
                  currentPath={currentPath}
                  setIsSidebarExpanded={setIsSidebarExpanded}
                  isExpanded={menuState[menuItem.title]}
                  handleChangeExpanded={handleChangeState}
                />
              );
            }
          })}
        </ul>
      )}
    </>
  );
}

export default function Menu({
  items,
  showMenuText = true,
  setIsSidebarExpanded,
}) {
  const { pathname } = useLocation();
  const initialMenuState = {};
  items.forEach(item => {
    initialMenuState[item.title] = false;
  });
  const [menuState, setMenuState] = useState(initialMenuState);
  const handleChangeState = item => {
    setMenuState({
      ...initialMenuState,
      [item.title]: !menuState[item.title],
    });
  };
  return (
    <ul className="ui-menu">
      {items.map((menu, index) => {
        if (!menu.items || !menu.showOptions) {
          return (
            <MenuItem
              key={index}
              menu={menu}
              showMenuText={showMenuText}
              currentPath={pathname}
            />
          );
        } else {
          return (
            <MenuGroup
              key={index}
              menu={menu}
              showMenuText={showMenuText}
              currentPath={pathname}
              isExpanded={menuState[menu.title]}
              handleChangeExpanded={handleChangeState}
              setIsSidebarExpanded={setIsSidebarExpanded}
            />
          );
        }
      })}
    </ul>
  );
}
