import React, { useContext } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Flex, Inline } from "@ableco/baseline";
import { DateFiltersContext } from "contexts/DateFiltersContext";
import { TableLink } from "components/TableData";
import { BarChart } from "components/charts/Charts";
import AccordionSection from "components/AccordionSection";
import TableWithPagination from "components/TableWithPagination";

import useMetricsQuery from "hooks/v2/useMetricsQuery";
import MetricChartContainer from "components/MetricChartContainer";
import { formatDuration } from "date-fns";
import daysToDuration from "helpers/daysToDuration";

import labelDate from "helpers/labelDate";
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";

const detailsColumns = [
  {
    label: "Bug",
    render: ({ item }) => {
      const value = {
        text: item.label,
        url: item.url,
      };
      return <TableLink value={value} />;
    },
  },
  { label: "Type", name: "type" },
  { label: "Priority", name: "priority" },
  {
    label: "Closed",
    render: ({ item }) => {
      return (
        <input type="checkbox" disabled readOnly checked={item.is_closed} />
      );
    },
  },
  {
    label: "TTF",
    render: ({ item }) => {
      if (!item.is_closed) {
        return <i>WIP</i>;
      }
      return (
        <span>
          {item.fixed_time > 0 ? (
            formatDuration(daysToDuration(item.fixed_time))
          ) : (
            <span>0 days</span>
          )}
        </span>
      );
    },
  },
];

export default function BugsReportedChart() {
  const { projectId, userId } = useParams();
  const { dateFilters } = useContext(DateFiltersContext);

  const { data: metrics, isLoading } = useMetricsQuery("bugs_reported", {
    ...dateFilters,
    projectId,
    userId,
  });
  const dataChart = metrics?.data_chart || [];
  const data = metrics?.data || [];
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const resolution = query.get("resolution");
  const renderChart = (
    <BarChart data={dataChart}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="created_in_tracker_at"
        tickFormatter={value => labelDate(value, resolution)}
      />
      <YAxis />
      <Tooltip cursor={{ fill: "transparent" }} />
      <Legend wrapperStyle={{ position: "relative" }} />
      <Bar
        dataKey="unknown"
        name="Unknown"
        stackId="project"
        maxBarSize={48}
        fill="#5f68ef"
      />
      <Bar
        dataKey="copy"
        name="Copy"
        stackId="project"
        maxBarSize={48}
        fill="#da713e"
      />
      <Bar
        dataKey="ux_ui"
        name="UI/UX"
        stackId="project"
        maxBarSize={48}
        fill="#9d4ec9"
      />
      <Bar
        dataKey="functional"
        name="Functional"
        stackId="project"
        maxBarSize={48}
        fill="#4da2dc"
      />
      <Bar
        dataKey="data"
        name="Data"
        stackId="project"
        maxBarSize={48}
        fill="#a8bb51"
      />
      <Bar
        dataKey="edge_case"
        name="Edge Case"
        stackId="project"
        maxBarSize={48}
        fill="#60c2c2"
      />
      <Bar
        dataKey="performance"
        name="Performance"
        stackId="project"
        maxBarSize={48}
        fill="#D9F7BE"
      />
      <Bar
        dataKey="security"
        name="Security"
        stackId="project"
        maxBarSize={48}
        fill="#A8071A"
      />
    </BarChart>
  );

  const renderSummary = (
    <div className="summary">
      <Flex
        className="w-full"
        direction="horizontal"
        distribution="end"
        className="text-neutral-100"
      >
        <Inline className="px-4" space={2}>
          <span className="text-xs">
            Total:{" "}
            <span className="text-base text-green-400">
              {metrics?.total_bug}
            </span>
          </span>
          <span className="text-xs">
            Open:{" "}
            <span className="text-base text-green-400">
              {metrics?.total_open}
            </span>
          </span>
          <span className="text-xs">
            Closed:{" "}
            <span className="text-base text-green-400">
              {metrics?.total_closed}
            </span>
          </span>
        </Inline>
      </Flex>
      <Flex
        className="w-full"
        direction="horizontal"
        distribution="between"
        className="text-neutral-100"
        distribution="end"
      >
        <Inline className="px-4" space={2}>
          <span className="text-xs">
            Average:{" "}
            <span className="text-base text-green-400">
              {metrics?.mean && formatDuration(daysToDuration(metrics?.mean))}
            </span>
          </span>
          <span className="text-xs">
            Standard deviation:{" "}
            <span className="text-base text-green-400">
              {metrics?.standard_deviation &&
                formatDuration(daysToDuration(metrics?.standard_deviation))}
            </span>
          </span>
        </Inline>
      </Flex>
    </div>
  );

  const renderTableDetails = (
    <AccordionSection title="Details">
      <TableWithPagination columns={detailsColumns} data={data} />
    </AccordionSection>
  );
  const renderDescription = (
    <p className="pb-2">
      The Bugs Reported chart shows{" "}
      <strong>
        the bugs reported per type (Copy, UX/UI, Functional, and etc.) grouped
        by periods of time
      </strong>
      .
    </p>
  );

  return (
    <MetricChartContainer
      data={data}
      isLoading={isLoading}
      title="Bugs Reported"
      description={renderDescription}
    >
      {renderSummary}
      {renderChart}
      {renderTableDetails}
    </MetricChartContainer>
  );
}
