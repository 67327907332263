import React from "react";
import { Frame } from "@ableco/baseline";
import DateFiltersProvider from "contexts/DateFiltersContext";
import Filter from "components/Filter";
import HistoricalInterviewChart from "components/charts/recruitment/HistoricalInterviewChart";

export default function HistoricalInterview() {
  return (
    <DateFiltersProvider>
      <Filter filters="mqy" defaultResolution="monthly" />
      <Frame className="mb-10">
        <HistoricalInterviewChart />
      </Frame>
    </DateFiltersProvider>
  );
}
