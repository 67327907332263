import React, { useState } from "react";
import LabelValue from "components/LabelValue";
import DropdownMenu from "components/DropdownMenu";
import ConfirmationModal from "components/ConfirmationModal";
import PullRequestTrackerForm from "./PullRequestTrackerForm";
import pullRequestTrackerQueries from "queries/pullRequestTrackerQueries";
import MenuIcon from "icons/menu.svg";
import { useLocation } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { capitalize } from "lodash";

const menuItems = [
  {
    label: "Edit",
    value: "edit",
  },
  {
    label: "Delete",
    value: "delete",
  },
];

function MenuButton({ toggleMenu }) {
  return (
    <MenuIcon
      className="text-neutral-200 fill-current flex-shrink-0 cursor-pointer"
      onClick={toggleMenu}
    />
  );
}

function invalidateQueries(queryClient, projectId, currentLocation) {
  if (currentLocation === "/admin/settings")
    queryClient.invalidateQueries("projects");
  else
    queryClient.invalidateQueries([
      "/api/**/**/pull_request_trackers",
      { projectId },
    ]);
}

export default function PullRequestTracker({ tracker, asRow = false }) {
  const {
    id,
    project_id: projectId,
    repo_id: name,
    repo_type: type,
    default_branch: branch,
    integration_ids,
  } = tracker;

  const [isStoryTrackerFormOpen, setStoryTrackerFormOpen] = useState(false);
  const [isDeleteConfirmationOpen, SetDeleteConfirmationOpen] = useState(false);
  const { deletePullRequestTracker } = pullRequestTrackerQueries();

  const location = useLocation();

  const queryClient = useQueryClient();
  const { mutateAsync: removeTracker } = useMutation(deletePullRequestTracker, {
    onSuccess: () =>
      invalidateQueries(queryClient, projectId.toString(), location.pathname),
  });

  return (
    <dl
      className={
        "flex justify-between " +
        (asRow ? "" : "p-4 rounded-lg border border-gray-500")
      }
    >
      <LabelValue
        label={!asRow && "Name"}
        value={name}
        wrapperClassName="w-1/5"
      />
      <div className="flex flex-1">
        <LabelValue
          label={!asRow && "Tracker"}
          value={capitalize(type)}
          wrapperClassName="w-1/2"
        />
        <LabelValue
          label={!asRow && "Default Branch"}
          value={branch}
          wrapperClassName="w-1/2"
        />
      </div>
      <LabelValue
        label={!asRow && "Code Climate ID"}
        value={integration_ids?.codeclimate_id}
        wrapperClassName="w-1/4"
      />
      <div className="w-1/4 flex justify-between items-center">
        <LabelValue
          label={!asRow && "Heroku App"}
          value={integration_ids?.heroku_app_name}
        />
        <DropdownMenu
          items={menuItems}
          width="110px"
          extraClassName={"bg-neutral-800"}
          textClassName={"text-neutral-200"}
          onSelect={item => {
            switch (item.value) {
              case "edit":
                setStoryTrackerFormOpen(true);
                break;
              case "delete":
                SetDeleteConfirmationOpen(true);
                break;
            }
          }}
        >
          <MenuButton />
        </DropdownMenu>
      </div>

      <PullRequestTrackerForm
        open={isStoryTrackerFormOpen}
        setOpen={setStoryTrackerFormOpen}
        projectId={projectId.toString()}
        tracker={tracker}
        isEditing
      />

      <ConfirmationModal
        title="Delete Pull Request Tracker"
        prompt={
          <span>
            Are you sure you want to delete <strong>{name}</strong> tracker?
          </span>
        }
        open={isDeleteConfirmationOpen}
        setOpen={SetDeleteConfirmationOpen}
        onConfirm={() => removeTracker(id)}
      />
    </dl>
  );
}
