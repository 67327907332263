// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import React from "react";
import { render } from "react-dom";
import { QueryClient } from "react-query";
import Application from "views/app";
import "styles/application.css";

const queryClient = new QueryClient();

render(
  <Application queryClient={queryClient} />,
  document.querySelector("#root"),
);
