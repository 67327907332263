import React from "react";
import CoreRefetchButton from "../../dashboard/refetchButton/CoreRefetchButton";
import RefetchData from "./RefetchData";

export default function RefetchCoreData({ projectId = null }) {
  return (
    <RefetchData
      projectId={projectId}
      title="Core"
      RefetchButton={CoreRefetchButton}
    />
  );
}
