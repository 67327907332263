import React, { useEffect, useState } from "react";
import Button from "components/FilterButton";
import LoadingData from "components/LoadingData";
import Metric from "./Metric";
import MetricForm from "./MetricForm";
import { useProject } from "hooks/useProject";
import { METRICS_TYPES } from "helpers/constants";
import ReactTooltip from "react-tooltip";

export default function Metrics({ projectId }) {
  const [isMetricFormOpen, setMetricFormOpen] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const { data: project, isLoading } = useProject();

  useEffect(() => {
    if (project?.status_metrics) {
      setButtonDisabled(
        METRICS_TYPES.length === Object.keys(project.status_metrics).length,
      );
    }
  }, [project]);

  return (
    <div className="px-8 pt-4 pb-8">
      <div className="flex justify-between mb-4">
        <h3 className="text-white text-xl font-bold">Metrics</h3>
        <div data-tip data-for="addButton">
          <Button
            disabled={isButtonDisabled}
            className="h-10 rounded-md w-auto"
            onClick={() => setMetricFormOpen(true)}
          >
            Add Metric
          </Button>
          {isButtonDisabled && (
            <ReactTooltip id="addButton" backgroundColor="#FFFFFF">
              <p className="text-black">
                All available Metrics had been added.
              </p>
            </ReactTooltip>
          )}
        </div>
      </div>
      {isLoading ? (
        <LoadingData />
      ) : (
        Object.entries(project.status_metrics).map(([key, value]) => (
          <Metric
            key={key}
            metricKey={key}
            details={value}
            projectId={projectId}
            project={project}
          />
        ))
      )}
      <MetricForm
        open={isMetricFormOpen}
        setOpen={setMetricFormOpen}
        projectId={projectId}
        project={project}
      />
    </div>
  );
}
