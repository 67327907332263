import React, { useState, useEffect } from "react";
import { useMetricsQuery } from "hooks/useMetricsQuery";
import Select, { components } from "react-select";
import { isEmpty, truncate } from "lodash";

const MultiValue = props => {
  const truncatedLabel = truncate(props.children, { length: 20 });
  return (
    <components.MultiValue {...props}>{truncatedLabel}</components.MultiValue>
  );
};

export default function JobMappingSelector({ onChangeJobIds }) {
  const [selectedRoleOption, setSelectedRoleOption] = useState(null);
  const [selectedJobOption, setSelectedJobOption] = useState([]);

  const handleRoleChange = itemSelected => {
    const jobIds = itemSelected.jobs.map(item => item.id);
    onChangeJobIds(jobIds.join(","));
    setSelectedRoleOption(itemSelected);
    setSelectedJobOption([]);
  };

  const handleJobChange = itemsSelected => {
    const jobIds = (isEmpty(itemsSelected)
      ? selectedRoleOption.jobs
      : itemsSelected
    ).map(item => item.id);
    onChangeJobIds(jobIds.join(","));
    setSelectedJobOption(itemsSelected);
  };

  const { data: roleOptions, isLoading: isLoadingRole } = useMetricsQuery(
    "recruitment/job_mapping",
    {},
  );

  useEffect(() => {
    if (!isLoadingRole) handleRoleChange(roleOptions[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingRole]);

  return (
    <div className="flex w-full align-top">
      <Select
        className="w-3/12 mr-5 mb-6"
        value={selectedRoleOption}
        onChange={handleRoleChange}
        options={roleOptions}
        placeholder="Select a Role"
        isLoading={isLoadingRole}
      />
      <Select
        className="w-5/12 mr-5 mb-6"
        value={selectedJobOption}
        onChange={handleJobChange}
        options={selectedRoleOption?.jobs}
        components={{ MultiValue }}
        placeholder="Select a Job"
        isMulti
      />
    </div>
  );
}
