import ProjectStatusPage from "pages/ProjectStatusPage";
import CurrentTeamMetrics from "pages/CurrentTeamMetrics";
import ContributionsChart from "pages/ContributionsChart";
import PerformanceChart from "pages/PerformanceChart";
import QualityAssuranceChart from "pages/QualityAssuranceChart";
import CodingChart from "pages/CodingChart";
import ProjectSettingsPage from "pages/ProjectSettingsPage";
import MyMetricsIcon from "icons/menu-my-metrics.svg";
import ProjectsIcon from "icons/menu-projects.svg";
import ProjectStatusIcon from "icons/menu-project-status.svg";
import MyTeamIcon from "icons/menu-my-team.svg";
import OverviewLogo from "icons/overview.svg";
import ProjectSnapshot from "pages/ProjectSnapshot";

const PROJECT_MENU = [
  {
    title: "Project Status",
    path: "status",
    main: ProjectStatusPage,
  },
  {
    title: "Snapshot",
    path: "snapshot",
    main: ProjectSnapshot,
  },
  {
    title: "Current Team Metrics",
    path: "current_team_metrics",
    main: CurrentTeamMetrics,
  },
  {
    title: "Contributions Chart",
    path: "contributions_chart",
    main: ContributionsChart,
  },
  {
    title: "Performance Chart",
    path: "performance_chart",
    main: PerformanceChart,
  },
  {
    title: "Quality Assurance Chart",
    path: "quality_assurance_chart",
    main: QualityAssuranceChart,
  },
  {
    title: "Coding Chart",
    path: "coding_chart",
    main: CodingChart,
  },
  {
    title: "Settings",
    path: "settings",
    main: ProjectSettingsPage,
    adminOnly: true,
  },
];

function buildMyReportsMenu(reports) {
  const reportMenuTitle = "My Team";
  const reports_menu = reports.map(report => {
    const userSubMenus = [
      {
        title: "Metrics",
        path: `/users/${report.id}`,
        componentIcon: MyMetricsIcon,
        pagePath: ["My metrics"],
      },
      {
        title: "Snapshots",
        path: `/users/${report.id}/snapshot`,
        componentIcon: MyMetricsIcon,
        pagePath: ["My snapshots"],
      },
    ];
    return {
      id: report.id,
      title: report.name,
      items: userSubMenus,
      icon_url: report.avatar_url,
      pagePath: [reportMenuTitle, report.name],
      path: `/users/${report.id}`,
      showOptions: false,
    };
  });
  return {
    title: reportMenuTitle,
    items: reports_menu,
    componentIcon: MyTeamIcon,
    pagePath: [reportMenuTitle],
    showOptions: true,
  };
}

function buildProjectsMenu(projects, level) {
  const menus = projects.map(project => {
    const projectSubmenu = PROJECT_MENU.filter(option =>
      level === "admin" ? true : !option?.adminOnly,
    ).map(({ title, path }) => {
      return {
        title,
        path: `/project/${project.id}/${path}`,
        pagePath: ["Projects", project.name, title],
      };
    });
    return {
      id: project.id,
      title: project.name,
      items: projectSubmenu,
      icon_url: project.logo_path || project.logo_url,
      pagePath: ["Projects", project.name],
      path: `/project/${project.id}/status`,
      showOptions: false,
    };
  });
  return menus;
}

function buildMenus(projects, currentUser) {
  const { level } = currentUser;
  const activeProjects = projects.filter(
    project => !project.archived && project.show_status,
  );
  const archivedProjects = projects.filter(project => project.archived);
  const hiddenProjects = projects.filter(
    project => !project.archived && !project.show_status,
  );
  const menu = [];
  const myMetricsItems = [
    {
      title: "My Metrics",
      path: `/users/${currentUser.id}`,
      componentIcon: MyMetricsIcon,
      pagePath: ["My metrics"],
    },
    {
      title: "My Snapshots",
      path: `/users/${currentUser.id}/snapshot`,
      componentIcon: MyMetricsIcon,
      pagePath: ["My snapshots"],
    },
  ];
  menu.push({
    title: "My Metrics",
    componentIcon: MyMetricsIcon,
    pagePath: ["My Metrics"],
    items: myMetricsItems,
    path: `/users/${currentUser.id}`,
    showOptions: false,
  });

  const projectsMenu = buildProjectsMenu(activeProjects, level);
  menu.push({
    title: "Projects",
    items: [
      {
        title: "Overview",
        path: "/",
        componentIcon: OverviewLogo,
        pagePath: ["Project Status", "Overview"],
      },
      ...projectsMenu,
    ],
    componentIcon: ProjectsIcon,
    pagePath: ["Projects"],
    showOptions: true,
  });

  const hiddenProjectsMenu = buildProjectsMenu(hiddenProjects, level);
  menu.push({
    title: "Hidden Projects",
    items: hiddenProjectsMenu,
    componentIcon: ProjectsIcon,
    pagePath: ["Projects"],
    showOptions: true,
  });

  const archivedProjectsMenu = buildProjectsMenu(archivedProjects, level);
  menu.push({
    title: "Archived Projects",
    items: archivedProjectsMenu,
    componentIcon: ProjectsIcon,
    pagePath: ["Projects"],
    showOptions: true,
  });

  if (currentUser.permissions.includes("view_recruitment_metrics")) {
    menu.push({
      title: "Recruitment",
      componentIcon: ProjectStatusIcon,
      showOptions: true,
      items: [
        {
          componentIcon: OverviewLogo,
          title: "Pipeline per Job",
          pagePath: ["Recruitment", "Current Pipeline per Job"],
          path: "/recruitment/pipeline_per_job",
        },
        {
          componentIcon: OverviewLogo,
          title: "Pipeline Stage Updates",
          pagePath: ["Recruitment", "Pipeline Stage Updates"],
          path: "/recruitment/pipeline_stage_updates",
        },
        {
          componentIcon: OverviewLogo,
          title: "Historical Interview",
          path: "/recruitment/historical_interview",
          pagePath: ["Recruitment", "Historical interview success"],
        },
        {
          componentIcon: OverviewLogo,
          title: "Pass-through Rates",
          pagePath: ["Recruitment", "Pass-through Rates"],
          path: "/recruitment/pass_through_rate",
        },
        {
          componentIcon: OverviewLogo,
          pagePath: ["Recruitment", "Applicant Cycle Time"],
          path: "/recruitment/applicant_cycle_time",
          title: "Applicant Cycle Time",
        },
        {
          componentIcon: OverviewLogo,
          pagePath: ["Recruitment", "Referrals Over time"],
          path: "/recruitment/referrals_over_time",
          title: "Referrals Over Time",
        },
        {
          componentIcon: OverviewLogo,
          pagePath: ["Recruitment", "CRM Prospecting Activity"],
          path: "/recruitment/crm_prospecting_activity",
          title: "CRM Prospecting Activity",
        },
      ],
    });
  }
  if (currentUser.active_direct_reports.length !== 0) {
    menu.push(buildMyReportsMenu(currentUser.active_direct_reports));
  }
  return menu;
}

function flatMenuWithLink(menus) {
  const result = [];
  menus.forEach(menuItem => {
    if (menuItem.path && (!!menuItem.showOptions || !menuItem.items)) {
      result.push(menuItem);
    } else {
      menuItem.items.forEach(menu_l1 => {
        if (menu_l1.path && (!!menu_l1.showOptions || !menu_l1.items)) {
          result.push(menu_l1);
        } else {
          menu_l1.items?.forEach(menu_l2 => {
            if (menu_l2.path) {
              result.push(menu_l2);
            }
          });
        }
      });
    }
  });
  return result;
}

export { flatMenuWithLink, buildMenus, PROJECT_MENU };
