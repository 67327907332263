import React from "react";
import useDOMState from "hooks/useDOMState";
import { Link } from "react-router-dom";
import classNames from "classnames";

export default function Tabs({ entity, resource, currentRoute, tabs }) {
  const [{ currentUser }] = useDOMState("root", {});
  const { level } = currentUser;

  return (
    <div className="flex items-center justify-start space-x-2 p-4 flex-wrap">
      {tabs.map((option, index) => {
        if (option?.adminOnly && level != "admin") return null;

        const path =
          `/${entity}/${resource}` +
          (option.path === "" ? "" : `/${option.path}`);

        return (
          <Link
            key={index}
            className={classNames(
              "text-white px-3 py-2 border-b-2 cursor-pointer",
              {
                "border-primary-base": currentRoute === path,
                "border-transparent hover:border-neutral-700":
                  currentRoute !== path,
              },
            )}
            to={path}
          >
            {option.title}
          </Link>
        );
      })}
    </div>
  );
}
