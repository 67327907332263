import React, { useContext } from "react";
import Snapshot from "components/snapshot/Snapshot";
import { DateFiltersContext } from "../contexts/DateFiltersContext";
import { useParams } from "react-router";
import { useUserStoriesQuery } from "../queries/icSnapshotQueries";

export default function UserSnapshotPage() {
  const { userId } = useParams();
  const { dateFilters } = useContext(DateFiltersContext);
  const { from, to } = dateFilters;
  const { data: stories, isLoading: isLoading } = useUserStoriesQuery(
    userId,
    from,
    to,
  );

  return <Snapshot snapshotData={stories} isLoading={isLoading} />;
}
