import React from "react";
import { Frame } from "@ableco/baseline";
import Filter from "components/Filter";
import DateFiltersProvider from "contexts/DateFiltersContext";
import CommitsChart from "components/charts/CommitsChart";
import CodingDaysPerWeekChart from "components/charts/CodingDaysPerWeekChart";

function CodingChart() {
  return (
    <DateFiltersProvider>
      <Filter />
      <Frame className="mb-10">
        <CommitsChart />
      </Frame>
      <Frame className="mb-10">
        <CodingDaysPerWeekChart />
      </Frame>
    </DateFiltersProvider>
  );
}

export default CodingChart;
