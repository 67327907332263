import React, { useState, useEffect } from "react";
import Modal from "components/Modal";
import ListboxPicker from "components/ListboxPicker";
import Checkbox from "components/Checkbox";
import FormLabel from "components/FormLabel";
import FormFooter from "components/FormFooter";
import useStoryTrackersQuery from "queries/useStoryTrackersQuery";
import { useMutation, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import { isEmpty } from "lodash";

const trackerTypes = [
  { value: 1, text: "Fino" },
  { value: 0, text: "Pivotal" },
];

function invalidateQueries(queryClient, projectId, currentLocation) {
  if (currentLocation === "/admin/settings")
    queryClient.invalidateQueries("projects");
  else
    queryClient.invalidateQueries(["/api/**/**/story_trackers", { projectId }]);
}

export default function StoryTrackerForm({
  open,
  setOpen,
  projectId,
  isEditing = false,
  tracker,
}) {
  const [trackerName, setTrackerName] = useState("");
  const [trackerType, setTrackerType] = useState(trackerTypes[0]);
  const [trackerId, setTrackerId] = useState("");
  const [isArchived, setIsArchived] = useState(false);

  const location = useLocation();

  const queryClient = useQueryClient();
  const { createStoryTracker, editStoryTracker } = useStoryTrackersQuery();

  const { mutateAsync: addTracker } = useMutation(createStoryTracker, {
    onSuccess: () =>
      invalidateQueries(queryClient, projectId, location.pathname),
  });

  const { mutateAsync: editTracker } = useMutation(editStoryTracker, {
    onSuccess: () =>
      invalidateQueries(queryClient, projectId, location.pathname),
  });

  useEffect(() => {
    if (isEditing) {
      const { name, tracker_type, tracker_id, archived } = tracker;
      setTrackerName(name);
      setTrackerId(tracker_id.toString());
      setIsArchived(archived);
      setTrackerType(
        trackerTypes.find(t => t.text.toLowerCase() === tracker_type),
      );
    }
  }, [tracker, isEditing]);

  const handleOpenForm = isOpen => {
    setOpen(isOpen);

    if (!isOpen && !isEditing) {
      setTrackerName("");
      setTrackerId("");
      setIsArchived(false);
    }
  };

  const handleSubmit = () => {
    const payload = {
      id: tracker?.id,
      project_id: projectId,
      name: trackerName,
      tracker_type: trackerType.value,
      tracker_id: trackerId,
      archived: isArchived,
    };

    if (isEditing) {
      editTracker(payload).then(() => {
        handleOpenForm(false);
      });
    } else {
      addTracker(payload).then(() => {
        handleOpenForm(false);
      });
    }
  };

  const formContent = (
    <div>
      <FormLabel required>Name</FormLabel>
      <input
        className="focus:outline-none rounded-md w-full p-2"
        placeholder="Add tracker name"
        onChange={({ target }) => setTrackerName(target.value)}
        value={trackerName}
      />

      <div className="flex my-2">
        <div className="w-3/4 mr-4">
          <FormLabel required>Tracker</FormLabel>
          <ListboxPicker
            onSelect={setTrackerType}
            items={trackerTypes}
            preSelectedOption={trackerType}
          />
        </div>
        <div className="w-1/4 mb-2">
          <FormLabel required>Tracker ID</FormLabel>
          <input
            className="focus:outline-none rounded-md w-full p-2"
            placeholder="e.g. 1432"
            onChange={({ target }) => setTrackerId(target.value)}
            value={trackerId}
          />
        </div>
      </div>
      <Checkbox
        checked={isArchived}
        prompt={
          <span className="text-sm text-primary-light">
            Is this tracker archived?
          </span>
        }
        onChange={({ target }) => setIsArchived(target.checked)}
      />
    </div>
  );

  return (
    <Modal
      title={`${isEditing ? "Edit" : "Add"} Story Tracker`}
      subtitle={`Fill out the form to ${
        isEditing ? "edit" : "add a new"
      } Story Tracker`}
      content={formContent}
      footer={
        <FormFooter
          onCancel={() => handleOpenForm(false)}
          onSubmit={handleSubmit}
          disabled={isEmpty(trackerName) || isEmpty(trackerId)}
        />
      }
      open={open}
      setOpen={handleOpenForm}
    />
  );
}
