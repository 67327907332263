import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { BarChart } from "components/charts/Charts";
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import MetricChartContainer from "components/MetricChartContainer";
import ChartSummaryValues from "./ChartSummaryValues";
import { DateFiltersContext } from "contexts/DateFiltersContext";
import useMetricsQuery from "hooks/v2/useMetricsQuery";

function tooltipFormatter(value, label, { dataKey, payload }) {
  const hours = payload[dataKey.slice(0, -4)];
  const customizedValue = `${hours} hours (${value}%)`;
  return [customizedValue, label];
}

export default function PullRequestCycleTimeTotalChart() {
  const { projectId } = useParams();
  const { dateFilters } = useContext(DateFiltersContext);
  const { data: metrics, isLoading } = useMetricsQuery(
    "pull_request_cycle_time_total",
    {
      ...dateFilters,
      projectId,
    },
  );
  const displayMetric =
    metrics?.data[0].total_prs !== 0 || metrics?.data[0].total_time !== 0;

  const renderChartSummary = (
    <ChartSummaryValues
      chartSummary={
        metrics
          ? [
              { label: "Total PRs", value: metrics.data[0].total_prs },
              {
                label: "PR Cycle average time",
                value: `${metrics.data[0].total_time.toFixed()} hour(s)`,
              },
            ]
          : null
      }
    />
  );

  const renderChart = (
    <BarChart data={metrics?.data || []}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="label" />
      <YAxis domain={[0, 100]} allowDataOverflow={true} />
      <Tooltip cursor={{ fill: "transparent" }} formatter={tooltipFormatter} />
      <Legend wrapperStyle={{ position: "relative" }} />
      <Bar
        dataKey="first_commit_to_pr_pct"
        name="First commit To PR"
        stackId="pr_cycle_time"
        fill="#5087ed"
        maxBarSize={48}
      />
      <Bar
        dataKey="pr_to_ready_pct"
        name="PR To Ready"
        stackId="pr_cycle_time"
        fill="#9b59b6"
        maxBarSize={48}
      />
      <Bar
        dataKey="ready_to_review_pct"
        name="PR Ready to review"
        stackId="pr_cycle_time"
        fill="#a50730"
        maxBarSize={48}
      />
      <Bar
        dataKey="review_to_approval_pct"
        name="First Review To Approval"
        stackId="pr_cycle_time"
        fill="#ecb43d"
        maxBarSize={48}
      />
      <Bar
        dataKey="approval_to_merge_pct"
        name="Approval To Merge"
        stackId="pr_cycle_time"
        fill="#479a5e"
        maxBarSize={48}
      />
    </BarChart>
  );

  const renderDescription = (
    <p>
      This chart shows the{" "}
      <strong>
        average hours of all pull requests since its first commit until it is
        merged.
      </strong>{" "}
      Only Pull requests that were closed and approved in the filtered period of
      time are considered.
    </p>
  );

  return (
    <>
      <MetricChartContainer
        data={displayMetric ? metrics : []}
        isLoading={isLoading}
        title="PR Cycle Time (total)"
        description={renderDescription}
      >
        {renderChartSummary}
        {renderChart}
      </MetricChartContainer>
    </>
  );
}
