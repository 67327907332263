import React from "react";
import { Frame } from "@ableco/baseline";
import Filter from "components/Filter";
import DateFiltersProvider from "contexts/DateFiltersContext";
import BugsReportedChart from "components/charts/BugsReportedChart";
import IssuesBeforeDeploymentChart from "components/charts/IssuesBeforeDeploymentChart";
import UncaughtExceptionsChart from "components/charts/UncaughtExceptionsChart";

function QualityAssuranceChart() {
  return (
    <DateFiltersProvider>
      <Filter />
      <Frame className="mb-10">
        <BugsReportedChart />
      </Frame>
      <Frame className="mb-10">
        <IssuesBeforeDeploymentChart />
      </Frame>
      <Frame className="mb-10">
        <UncaughtExceptionsChart />
      </Frame>
    </DateFiltersProvider>
  );
}

export default QualityAssuranceChart;
