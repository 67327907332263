import React from "react";
import { PROJECT_MENU } from "../config";
import ProjectHeader from "./ProjectHeader";
import Tabs from "./Tabs";
import UserHeader from "./UserHeader";

const settingsTabs = [
  { title: "Projects", path: "" },
  { title: "Recruitment", path: "recruitment" },
  { title: "Data Fetching", path: "data_fetching" },
];

export function getUserMenu(userId, currentUserId) {
  if (userId.toString() !== currentUserId.toString()) {
    return [
      { title: "Metrics", path: "" },
      { title: "Snapshots", path: "snapshot" },
    ];
  }
  return [
    { title: "My Metrics", path: "" },
    { title: "My Snapshots", path: "snapshot" },
  ];
}

export function getHeaderAndTabs(currentRoute, currentUser, dateFilters) {
  let renderHeader, renderTabs;
  if (currentRoute?.path.includes("project")) {
    const projectId = currentRoute?.path.split("/")[2];
    renderHeader = (
      <ProjectHeader
        projectId={projectId}
        title={currentRoute?.title}
        dateFilters={dateFilters}
      />
    );
    renderTabs = (
      <Tabs
        entity={"project"}
        resource={projectId}
        currentRoute={currentRoute?.path}
        tabs={PROJECT_MENU}
      />
    );
  } else if (currentRoute?.path.includes("users")) {
    const userId = currentRoute?.path.split("/")[2];
    renderHeader = <UserHeader userId={userId} title={currentRoute?.title} />;
    renderTabs = (
      <Tabs
        entity={"users"}
        tabs={getUserMenu(userId, currentUser.id)}
        resource={userId}
        currentRoute={currentRoute?.path}
      />
    );
  } else if (window.location.pathname.includes("users")) {
    const path = window.location.pathname;
    const splitPath = path.split("/");
    const userId = splitPath[2];

    const title = splitPath.length === 3 ? "Metrics" : "Snapshots";

    renderHeader = <UserHeader userId={userId} title={title} />;
    renderTabs = (
      <Tabs
        entity={"users"}
        tabs={getUserMenu(userId, currentUser.id)}
        resource={userId}
        currentRoute={path}
      />
    );
  } else if (window.location.pathname.includes("admin")) {
    const path = window.location.pathname;
    renderHeader = (
      <UserHeader userId={currentUser.id} title={"Admin Settings"} />
    );
    renderTabs = (
      <Tabs
        entity={"admin"}
        tabs={settingsTabs}
        resource={"settings"}
        currentRoute={path}
      />
    );
  } else if (
    currentRoute?.path === "/" ||
    currentRoute?.path.startsWith("/recruitment")
  ) {
    renderHeader = (
      <div className="text-2xl font-bold text-white">{currentRoute?.title}</div>
    );
  }

  return [renderHeader, renderTabs];
}
