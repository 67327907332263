import React from "react";
import GreenhouseRefetchButton from "../../dashboard/refetchButton/GreenhouseRefetchButton";
import RefetchData from "./RefetchData";

export default function RefetchGreenhouseData({ projectId = null }) {
  return (
    <RefetchData
      projectId={projectId}
      title="Greenhouse"
      RefetchButton={GreenhouseRefetchButton}
      fromDateLabel="Last Activity at"
      hasToDate={false}
    />
  );
}
