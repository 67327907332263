import React, { useContext, useEffect } from "react";
import Filter from "components/Filter";
import { DateFiltersContext } from "../../contexts/DateFiltersContext";
import { eachWeekOfInterval, format } from "date-fns";
import { groupBy } from "lodash";
import AccordionSection from "components/AccordionSection";
import TableWithPagination from "components/TableWithPagination";
import {
  detailsColumns,
  detailsPRsColumns,
  detailsCRColumns,
  detailsBugColumns,
  projectPRsColumns,
  projectDetailsBugColumns,
  projectCRColumns,
  projectDetailsColumns,
} from "./SnapshotTableColumns";
import { groupByWeek, dataDigest, groupByWeekSameRow } from "./SnashotCommons";
import { SnapshotRow, SnapshotRowCR } from "./SnapshotRows";
import LoadingData from "./Loading";
import Legend from "./SnapshotLegend";
import Ptos from "./Ptos";
import WeekHeader from "./SnapshotsWeekHeader";
import { useNotification } from "../../contexts/NotificationsContext";

export default function Snapshot({
  snapshotData,
  isLoading,
  isProject = false,
}) {
  const notification = useNotification();
  const { changeResolution, dateFilters } = useContext(DateFiltersContext);
  const { from, to } = dateFilters;
  const rawWeeks = eachWeekOfInterval(
    { start: from, end: to },
    { weekStartsOn: 1 },
  );
  const weeks = rawWeeks.map(week => format(week, "MM/dd"));
  useEffect(() => {
    changeResolution("quarterly");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isProject && snapshotData?.stories_data.length > 0) {
      const currentStories = snapshotData?.stories_data.filter(
        el => !el.data.completed_date,
      );

      currentStories.forEach(el => {
        if (el.data.assignees.length === 0) {
          //timeout is needed to prevent a ref error
          setTimeout(() => {
            notification({
              id: `snapshot_story_without_assignees_notification_${el.data.id}`,
              label: (
                <div>{`The story with id ${el.data.id} has no asignees`}</div>
              ),
              type: "warning",
            });
          }, 10);
        }
        if (!el.data.schedule_date) {
          setTimeout(() => {
            notification({
              id: `snapshot_story_without_due_date_${el.data.id}`,
              label: (
                <div>{`The story with id ${el.data.id} has no scheduled dates`}</div>
              ),
              type: "warning",
            });
          }, 10);
        }
      });
    }
  }, [notification, snapshotData, isProject]);

  const groupByType = groupBy(snapshotData?.stories_data, el => el.type);
  const groupPitchs = groupByWeekSameRow(
    weeks,
    dataDigest(groupByType.pitch, rawWeeks[0]),
  );
  const groupTweaks = groupByWeekSameRow(
    weeks,
    dataDigest(groupByType.tweak, rawWeeks[0]),
  );
  const groupChores = groupByWeekSameRow(
    weeks,
    dataDigest(groupByType.chore, rawWeeks[0]),
  );
  const groupBugs = groupByWeekSameRow(
    weeks,
    dataDigest(groupByType.bug, rawWeeks[0]),
  );
  const groupStories = groupByWeekSameRow(
    weeks,
    dataDigest(groupByType.story, rawWeeks[0]),
  );
  const groupCodeReviews = groupByWeek(
    weeks,
    dataDigest(snapshotData?.cr_data, rawWeeks[0]),
  );
  const notPtoCrBug = snapshotData?.stories_data?.filter(
    el => el.type != "bug",
  );
  const onlyCR = snapshotData?.cr_data;
  const onlyBugs = snapshotData?.stories_data?.filter(el => el.type == "bug");
  const prData = snapshotData?.pr_data;
  const onlyPto = snapshotData?.ptos_data;

  const renderTableDetails = (
    <AccordionSection title={`Stories (${notPtoCrBug?.length})`}>
      <TableWithPagination
        columns={isProject ? projectDetailsColumns : detailsColumns}
        data={notPtoCrBug}
      />
    </AccordionSection>
  );

  const renderTableCR = (
    <AccordionSection title={`Code Reviews (${onlyCR?.length})`}>
      <TableWithPagination
        columns={isProject ? projectCRColumns : detailsCRColumns}
        data={onlyCR}
      />
    </AccordionSection>
  );

  const renderTableBug = (
    <AccordionSection title={`Bugs (${onlyBugs?.length})`}>
      <TableWithPagination
        columns={isProject ? projectDetailsBugColumns : detailsBugColumns}
        data={onlyBugs}
      />
    </AccordionSection>
  );

  const renderTablePRs = (
    <AccordionSection title={`PRs (${prData?.length})`}>
      <TableWithPagination
        columns={isProject ? projectPRsColumns : detailsPRsColumns}
        data={prData}
      />
    </AccordionSection>
  );

  return (
    <div className="mb-16">
      <div>
        {!isLoading ? (
          <div>
            <Filter showResolutions={false} />
            <div className="bg-neutral-800 p-4 rounded">
              <div
                className={`grid grid-cols-${weeks.length} text-white rounded mx-2 border border-neutral-200 mt-2 pb-1`}
              >
                {rawWeeks.map((week, index) => (
                  <div
                    className={`row-start-1 col-start-${index +
                      1} text-center border-l border-b border-neutral-200 relative`}
                    key={index}
                  >
                    <Ptos onlyPto={onlyPto} week={week} isProject={isProject} />
                    <WeekHeader week={week} />
                  </div>
                ))}
                <SnapshotRow groupStories={groupPitchs} color="orange-base" />
                <SnapshotRow groupStories={groupStories} color="orange-base" />
                <SnapshotRow groupStories={groupTweaks} color="green-base" />
                <SnapshotRow groupStories={groupChores} color="blue-base" />
                <SnapshotRow groupStories={groupBugs} color="alert-base" />
                <SnapshotRowCR crs={groupCodeReviews} isProject={isProject} />
              </div>
              <Legend />
              {renderTableDetails}
              {renderTablePRs}
              {renderTableCR}
              {renderTableBug}
            </div>
          </div>
        ) : (
          <LoadingData />
        )}
      </div>
    </div>
  );
}
