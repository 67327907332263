import React, { useContext } from "react";
import { useParams, useLocation } from "react-router-dom";
import { LineChart } from "components/charts/Charts";
import { Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import { DateFiltersContext } from "contexts/DateFiltersContext";
import MetricChartContainer from "components/MetricChartContainer";
import { useMetricsQuery } from "hooks/useMetricsQuery";
import labelDate from "helpers/labelDate";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length !== 0) {
    return (
      <div
        style={{
          borderColor: "rgb(204, 204, 204)",
        }}
        className="p-3 bg-white border"
      >
        <p style={{ color: "#000000" }}>{payload[0].payload.closed_at}</p>
        <p
          style={{ color: "#2E71A6" }}
        >{`${payload[0].name} : ${payload[0].payload.first_code_review_time_mean_words}`}</p>
      </div>
    );
  }

  return null;
};

export default function FirstCodeReviewTimeChartByDate() {
  const { projectId, userId } = useParams();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const resolution = query.get("resolution");

  const { dateFilters } = useContext(DateFiltersContext);
  const { data: metrics, isLoading } = useMetricsQuery(
    "first_code_review_time_by_date",
    {
      ...dateFilters,
      resolution,
      projectId,
      userId,
    },
  );

  const fetchedData = metrics?.data || [];
  const renderDescription = (
    <p className="pb-2">
      This chart shows{" "}
      <strong>
        the average of time passed since a pull request was created until it was
        code reviewed for first time grouped by periods of time
      </strong>
      .
    </p>
  );

  const renderChart = (
    <LineChart data={fetchedData}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey="closed_at"
        tickFormatter={value => labelDate(value, resolution)}
      />
      <YAxis />
      <Tooltip content={<CustomTooltip />} cursor={{ fill: "transparent" }} />
      <Legend wrapperStyle={{ position: "relative" }} />
      <Line
        dataKey="first_code_review_time_mean"
        name="Time to first code review"
        fill="#2E71A6"
        type="monotone"
        strokeWidth={3}
      />
    </LineChart>
  );

  return (
    <MetricChartContainer
      data={fetchedData}
      isLoading={isLoading}
      title="Time to First Code Review By Date"
      description={renderDescription}
    >
      {renderChart}
    </MetricChartContainer>
  );
}
