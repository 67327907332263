import { useQuery } from "react-query";
import { format } from "date-fns";
import buildURL from "helpers/buildURL";
import fetchJSON from "helpers/fetchJSON";

function fetchMetric(metricType, { projectId, userId, from, to, resolution }) {
  const filter = {
    from: format(from, "yyyy-MM-dd"),
    to: format(to, "yyyy-MM-dd"),
    resolution,
  };

  let apiResource = "";
  let resourceId = "";

  if (projectId) {
    apiResource = "projects";
    resourceId = projectId;
  }

  if (userId) {
    apiResource = "users";
    resourceId = userId;
  }

  return fetchJSON(
    buildURL({
      path: `/api/${apiResource}/${resourceId}/metrics/${metricType}`,
      filter,
    }),
  );
}

export function useMetricsQuery(metricType, filters) {
  // using '/**/**/' in the query array keys as we don't have apiResource and resourceId at this moment
  // but will be extracted in the 'fetchMetric' function
  const query = useQuery(
    [`/api/**/**/metrics/${metricType}`, filters],
    () => fetchMetric(metricType, filters),
    { refetchOnWindowFocus: false },
  );

  return query;
}

export function updateCodeReviewComment({ userId, reviewId, body }) {
  return fetchJSON(
    buildURL({
      path: `/api/users/${userId}/metrics/code_reviews?id=${reviewId}`,
      filter: {},
    }),
    "PATCH",
    body,
  );
}

export default useMetricsQuery;
