export const COLOR_SET = [
  "#c0392b",
  "#9b59b6",
  "#e67e22",
  "#1abc9c",
  "#3498db",
  "#3b5fcf",
  "#274582",
  "#ecb43d",
];
