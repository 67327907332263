import React from "react";
import { Frame } from "@ableco/baseline";
import DateFiltersProvider from "contexts/DateFiltersContext";
import Filter from "components/Filter";
import ApplicantCycleTimeChart from "components/charts/recruitment/ApplicantCycleTimeChart";

export default function ApplicantCycleTime() {
  return (
    <DateFiltersProvider>
      <Filter filters="mqy" defaultResolution="monthly" />
      <Frame className="mb-10">
        <ApplicantCycleTimeChart />
      </Frame>
    </DateFiltersProvider>
  );
}
