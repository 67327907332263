import React, { useEffect, useState } from "react";
import LabelValue from "components/LabelValue";
import DropdownMenu from "components/DropdownMenu";
import ConfirmationModal from "components/ConfirmationModal";
import Toggle from "components/Toggle";
import MetricForm from "./MetricForm";
import MenuIcon from "icons/menu.svg";
import { METRICS_DATA } from "helpers/constants";
import projectQueries from "queries/projectQueries";
import { useMutation, useQueryClient } from "react-query";
import { isEmpty } from "lodash";

const menuItems = [
  {
    label: "Edit",
    value: "edit",
  },
  {
    label: "Delete",
    value: "delete",
  },
];

function MenuButton({ toggleMenu }) {
  return (
    <MenuIcon
      className="text-neutral-200 fill-current flex-shrink-0 cursor-pointer"
      onClick={toggleMenu}
    />
  );
}

export default function Metric({ metricKey, details, projectId, project }) {
  const [isMetricFormOpen, setMetricFormOpen] = useState(false);
  const [isDeleteConfirmationOpen, SetDeleteConfirmationOpen] = useState(false);
  const [isThresholdAscendant, setThresholdAscendant] = useState(true);
  const [metricSuffix, setMetricSuffix] = useState("");

  const {
    is_visible,
    green_threshold,
    yellow_threshold,
    red_threshold,
    is_percentage,
  } = details;

  const queryClient = useQueryClient();
  const { editProject } = projectQueries();

  const { mutateAsync: updateProject } = useMutation(editProject, {
    onSuccess: () => queryClient.invalidateQueries(`projects/${projectId}`),
  });

  useEffect(() => {
    if (!isEmpty(details)) {
      setThresholdAscendant(
        parseInt(green_threshold) > parseInt(yellow_threshold),
      );
      setMetricSuffix(is_percentage ? "%" : "");
    }
  }, [details, green_threshold, yellow_threshold, is_percentage]);

  const deleteMetric = () => {
    delete project.status_metrics[metricKey];

    const payload = {
      id: project.id,
      status_metrics: { ...project.status_metrics },
    };

    updateProject(payload);
  };

  const onChangeVisibility = value => {
    project.status_metrics[metricKey] = {
      ...project.status_metrics[metricKey],
      is_visible: value,
    };

    const payload = {
      id: project.id,
      status_metrics: { ...project.status_metrics },
    };

    updateProject(payload);
  };

  return (
    <dl className="flex justify-between p-4 mb-4 text-sm text-white mt-1 rounded-lg border border-gray-500">
      <div className="w-2/5 flex">
        <LabelValue
          wrapperClassName="w-1/2"
          label="Name"
          value={METRICS_DATA[metricKey].name}
        />
        <div className="flex flex-col">
          <dt className="text-sm font-medium text-neutral-200">
            Status Visible?
          </dt>
          <Toggle
            wrapperClassName="mt-1"
            defaultValue={is_visible}
            onChange={onChangeVisibility}
          />
        </div>
      </div>

      <div className="flex-1">
        <dt className="text-sm font-medium text-neutral-200">Thresholds</dt>
        <div className="flex mt-1">
          <dd className="w-1/3 text-indicator-green">
            {isEmpty(green_threshold)
              ? "--"
              : (isThresholdAscendant ? "Greater than " : "Less than ") +
                green_threshold +
                metricSuffix}
          </dd>
          <dd className="w-1/3 text-indicator-yellow">
            {isEmpty(yellow_threshold)
              ? "--"
              : (isThresholdAscendant ? "Greater than " : "Less than ") +
                yellow_threshold +
                metricSuffix}
          </dd>
          <dd className="w-1/3 text-indicator-red">
            {isEmpty(red_threshold)
              ? "--"
              : (isThresholdAscendant ? "Less than " : "Greater than ") +
                red_threshold +
                metricSuffix}
          </dd>
        </div>
      </div>

      <div className="m-auto">
        <DropdownMenu
          items={menuItems}
          width="110px"
          extraClassName={"bg-neutral-800"}
          textClassName={"text-neutral-200"}
          onSelect={item => {
            switch (item.value) {
              case "edit":
                setMetricFormOpen(true);
                break;
              case "delete":
                SetDeleteConfirmationOpen(true);
                break;
            }
          }}
        >
          <MenuButton />
        </DropdownMenu>
      </div>

      <MetricForm
        open={isMetricFormOpen}
        setOpen={setMetricFormOpen}
        projectId={projectId}
        project={project}
        metric={metricKey}
        isEditing
      />

      <ConfirmationModal
        title="Delete Project Metric"
        prompt={
          <span>
            Are you sure you want to delete{" "}
            <strong>{METRICS_DATA[metricKey].name}</strong> metric?
          </span>
        }
        open={isDeleteConfirmationOpen}
        setOpen={SetDeleteConfirmationOpen}
        onConfirm={deleteMetric}
      />
    </dl>
  );
}
