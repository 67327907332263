import React from "react";
import { Color, Flex, Text } from "@ableco/baseline";

export default function Checkbox({ prompt, ...props }) {
  return (
    <Flex as="label" className="items-center">
      <input type="checkbox" className="form-checkbox" {...props} />
      {prompt ? (
        <Text color={Color.Neutral100} className="ml-2">
          {prompt}
        </Text>
      ) : null}
    </Flex>
  );
}
