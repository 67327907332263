import React, { Fragment } from "react";
import SnapshotCell from "./SnapshotCell";
import ClockIcon from "icons/clock-regular.svg";
import RuningMan from "icons/running-solid.svg";
import Flag from "icons/flag.svg";
import ReactTooltip from "react-tooltip";
import { CrsTooltipCard, StoryTooltipCard } from "./SnapshotTooltips";
import { groupBy } from "lodash";
import { Avatar } from "@ableco/baseline";

function SnapshotRowCR({ crs = [], isProject = false }) {
  return isProject ? (
    <ProjectSnapshotRowCR crs={crs} />
  ) : (
    <IcSnapshotRowCR crs={crs} />
  );
}

function IcSnapshotRowCR({ crs = [] }) {
  return Object.keys(crs).map((key, index) => {
    return crs[key]?.length > 0 ? (
      <Fragment key={key}>
        <SnapshotCell
          col={index + 1}
          color={"blue-light"}
          storyId={`${index}/${key}`}
        >
          <span className="text-xs text-center max-w-xxs">
            CR ({crs[key]?.length})
          </span>
        </SnapshotCell>
        <ReactTooltip id={`${index}/${key}`} backgroundColor="#ffffff">
          <CrsTooltipCard codeReviews={crs} currentKey={key} />
        </ReactTooltip>
      </Fragment>
    ) : (
      <SnapshotCell key={Math.random()} col={index + 1} />
    );
  });
}

function ProjectSnapshotRowCR({ crs = [] }) {
  return Object.keys(crs).map((key, index) => {
    if (crs[key].length > 0) {
      const groupedByUsers = groupBy(crs[key], el => el.user.name);
      return (
        <div key={key}>
          <SnapshotCell
            col={index + 1}
            color={"blue-light"}
            storyId={`${index}/${key}`}
          >
            <span className="text-xs text-center max-w-xxs">
              CR ({crs[key]?.length})
            </span>
          </SnapshotCell>
          <ReactTooltip id={`${index}/${key}`} backgroundColor="#ffffff">
            {Object.keys(groupedByUsers)
              .sort()
              .map((user, ind) => {
                return (
                  <div key={ind}>
                    <p className="text-black">
                      {user}: {groupedByUsers[user].length}
                    </p>
                  </div>
                );
              })}
          </ReactTooltip>
        </div>
      );
    }
    return <SnapshotCell key={Math.random()} col={index + 1} />;
  });
}

function SnapshotRow({ groupStories = [], color }) {
  return Object.keys(groupStories).map(key => {
    return groupStories[key]?.length > 0
      ? groupStories[key].map(row => {
          return row.map(story => {
            const isOverdue = story.overdue_days > 0;
            const col = story.startingCol;
            const isCurrent = story.completed_at == null;
            return (
              <Fragment key={key + story.id}>
                <SnapshotCell
                  col={col}
                  colSpan={story.colSpan}
                  color={color}
                  className={`z-10`}
                  innerClassName={""}
                  storyId={`${story.id}/${story.flow_id}`}
                  url={story.url}
                  isCurrent={isCurrent}
                >
                  <div className="w-full flex justify-center gap-1">
                    <span className="text-xs text-center max-w-xxs">
                      {`${story.flow_id ? story.flow_id : story.id}`}
                    </span>
                    {isOverdue ? (
                      <>
                        {isCurrent ? (
                          <span className="text-alert-base">
                            <Flag />
                          </span>
                        ) : (
                          <ClockIcon />
                        )}
                      </>
                    ) : (
                      <RuningMan />
                    )}
                    {story.colSpan >= 2 && story.assignees ? (
                      <div className="flex justify-center space-x-1">
                        {story.assignees.map((user, ind) => (
                          <Avatar
                            key={ind}
                            source={user.avatar_url}
                            width={15}
                            height={15}
                            name={user.name}
                          />
                        ))}
                      </div>
                    ) : null}
                  </div>
                </SnapshotCell>
                <ReactTooltip
                  id={`${story.id}/${story.flow_id}`}
                  backgroundColor="#ffffff"
                >
                  <StoryTooltipCard story={story} isOverdue={isOverdue} />
                </ReactTooltip>
              </Fragment>
            );
          });
        })
      : null;
  });
}

export { SnapshotRowCR, SnapshotRow };
