import React, { useState } from "react";
import Sidebar from "../components/metrics/Sidebar";
import Header from "../components/metrics/header/Header";
import ActivePage from "../components/metrics/ActivePage";
import DateFiltersProvider from "contexts/DateFiltersContext";
import StatusesProvider from "contexts/StatusesContext";
import {
  NotificationContainer,
  NotificationProvider,
} from "../components/Notification";

export default function MetricsPage() {
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
  const [navigationList, setNavigationList] = useState([]);
  return (
    <div className="flex flex-col h-screen w-screen">
      <div className="flex overflow-hidden h-screen w-screen">
        <Sidebar
          isExpanded={isSidebarExpanded}
          setNavigationList={setNavigationList}
          setIsSidebarExpanded={setIsSidebarExpanded}
        />
        <DateFiltersProvider>
          <StatusesProvider>
            <NotificationProvider>
              <div className="flex-1 pb-16">
                <div className="w-full">
                  <NotificationContainer />
                </div>
                <Header
                  changeSidebarExpand={() =>
                    setIsSidebarExpanded(!isSidebarExpanded)
                  }
                  navigationList={navigationList}
                />
                <ActivePage />
              </div>
            </NotificationProvider>
          </StatusesProvider>
        </DateFiltersProvider>
      </div>
    </div>
  );
}
