import React from "react";
import Modal from "components/Modal";
import Button from "components/FilterButton";

export default function ConfirmationModal({
  title,
  prompt,
  open,
  setOpen,
  buttonName = "Delete",
  onConfirm,
}) {
  const handleDelete = () => {
    onConfirm();
    setOpen(false);
  };

  const formContent = <div className="text-neutral-100">{prompt}</div>;

  const formActions = (
    <div className="flex justify-between">
      <Button
        className="h-10 rounded-md w-full sm:w-auto"
        onClick={() => setOpen(false)}
      >
        Cancel
      </Button>
      <Button
        className="h-10 rounded-md w-full sm:w-auto"
        active
        onClick={handleDelete}
      >
        {buttonName}
      </Button>
    </div>
  );

  return (
    <Modal
      title={title}
      content={formContent}
      footer={formActions}
      open={open}
      setOpen={setOpen}
    />
  );
}
