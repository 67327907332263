import React from "react";
import { QueryClientProvider } from "react-query";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import LoginPage from "pages/LoginPage";
import useDOMState from "hooks/useDOMState";
import ProjectsProvider from "contexts/ProjectsContext";
import MetricsPage from "../pages/MetricsPage";
import { ReactQueryDevtools } from "react-query/devtools";

export default function MetricsDashboard({ queryClient }) {
  const [{ currentUser }] = useDOMState("root", {});

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Switch>
          <Route exact path="/login">
            {currentUser ? <Redirect to="/" /> : <LoginPage />}
          </Route>
          <Route path="/">
            {currentUser ? (
              <ProjectsProvider>
                <MetricsPage />
              </ProjectsProvider>
            ) : (
              <Redirect to="/login" />
            )}
          </Route>
        </Switch>
      </Router>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}
