import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import { Button, Flex, Center, Text, Color } from "@ableco/baseline";
import Logo from "components/Logo";
import useDOMState from "hooks/useDOMState";

const csrfParam = document.querySelector("meta[name=csrf-param]")?.content;
const csrfToken = document.querySelector("meta[name=csrf-token]")?.content;

export default function LoginPage() {
  const [{ currentUser }] = useDOMState("root", {});
  const location = useLocation();
  if (currentUser) {
    return <Redirect to="/" />;
  }
  const nextUrl = location.state?.referrer || "/";
  return (
    <Center className="h-full">
      <Flex className="items-center" direction="vertical">
        <Logo size="LG" to="/" />
        <form method="POST" action={`/auth/google_oauth2?nexturl=${nextUrl}`}>
          <input type="hidden" name={csrfParam} value={csrfToken} />
          <Button
            className="hover:bg-primary-base bg-primary-dark border-none mt-12"
            type="submit"
          >
            <Text color={Color.Neutral100}>Log in with your Able account</Text>
          </Button>
        </form>
      </Flex>
    </Center>
  );
}
