import React, { useState } from "react";
import DashboardCardTitle from "./DashboardCardTitle";
import ArrowIcon from "icons/arrow.svg";

export default function DashboardCard({
  title,
  subtitle,
  children,
  minHeight,
  onClick,
  showAddIcon = false,
  expandable = false,
  expandedByDefault = true,
  showAlertIcon = false,
  alertIconTooltip = "",
}) {
  const [isExpanded, setIsExpanded] = useState(expandedByDefault);

  return (
    <div
      className={
        "flex bg-nord-background rounded-lg py-5 transition-width duration-700 ease-in-out " +
        (expandable ? "pl-2 pr-5 " : "flex-grow px-5 ") +
        (isExpanded ? "w-1/3" : "w-24")
      }
      style={{ minHeight: minHeight }}
    >
      {expandable && (
        <ArrowIcon
          className={
            "text-neutral-200 w-4 h-4 cursor-pointer transform mr-1 mt-1 " +
            (isExpanded ? "rotate-180" : null)
          }
          onClick={() => setIsExpanded(!isExpanded)}
        />
      )}

      <div className="flex-1 overflow-hidden">
        <DashboardCardTitle
          title={title}
          subtitle={subtitle}
          showAddIcon={showAddIcon && isExpanded}
          onClick={onClick}
          showAlertIcon={showAlertIcon && !isExpanded}
          alertIconTooltip={alertIconTooltip}
        />
        {isExpanded && children}
      </div>
    </div>
  );
}
