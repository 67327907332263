import React, { useEffect, useState } from "react";
import { useMetricsQuery } from "hooks/useMetricsQuery";
import Select from "react-select";

export default function ProspectGroupSelector({ onChangeGroup }) {
  const [selectedProspectGroup, setProspectGroup] = useState(null);

  const { data: prospectGroups, isLoading: isLoadingGroups } = useMetricsQuery(
    "recruitment/prospect_group_mapping",
    {},
  );

  const handleGroupChange = itemSelected => {
    setProspectGroup(itemSelected);
    onChangeGroup(itemSelected);
  };

  useEffect(() => {
    if (!isLoadingGroups) {
      setProspectGroup(prospectGroups[0]);
      onChangeGroup(prospectGroups[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingGroups]);

  return (
    <Select
      className="w-3/12 mr-5 mb-6"
      value={selectedProspectGroup}
      onChange={handleGroupChange}
      options={prospectGroups}
      placeholder="Select a Prospect Pool"
      isLoading={isLoadingGroups}
    />
  );
}
