import React, { useState, useEffect, useContext } from "react";
import ProjectLogo from "components/ProjectLogo";
import DefaultLogo from "components/DefaultLogo";
import ListboxPicker from "components/ListboxPicker";
import IndicatorIcon from "icons/circle.svg";
import ReactTooltip from "react-tooltip";
import useProjectStatusesQuery from "hooks/v2/useProjectStatusesQuery";
import useResourcesQuery from "queries/resourcesQueries";
import { Avatar, Spacer } from "@ableco/baseline";
import { StatusesContext } from "contexts/StatusesContext";
import { isEmpty } from "lodash";
import { format } from "date-fns";
import { useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";

const statusDetails = {
  green: {
    text: "On track",
    icon: <IndicatorIcon className="text-indicator-green fill-current" />,
  },
  yellow: {
    text: "Behind",
    icon: <IndicatorIcon className="text-indicator-yellow fill-current" />,
  },
  red: {
    text: "At risk",
    icon: <IndicatorIcon className="text-indicator-red fill-current" />,
  },
};

function buildListboxOptions(statusList = []) {
  let items = [];
  statusList.forEach(({ attributes: { id, name } }) => {
    name = name.toLowerCase();
    items.push({
      value: id,
      text: statusDetails[name].text,
      icon: statusDetails[name].icon,
    });
  });
  return items;
}

function invalidateQueries(queryClient, dateFilters, projectId) {
  queryClient.invalidateQueries([
    "/api/**/**/project_status",
    { ...dateFilters, projectId },
  ]);
}

function dateFormatter(strDate) {
  const date = new Date(`${strDate} 00:00:00`);
  return format(date, "MMM dd, yyyy");
}

function hasPtoThisWeek(startStr, currentWeek) {
  const start = new Date(`${startStr} 00:00:00`);
  let endOfWeek = new Date(currentWeek.getTime());
  endOfWeek.setDate(endOfWeek.getDate() + 7);

  return currentWeek <= start && start <= endOfWeek;
}

export default function ProjectDetails({
  project,
  preselectedStatus,
  dateFilters,
  projectId,
  ptos,
  title = "",
}) {
  const [preSelectedOption, setPreSelectedOption] = useState();
  const [isListboxDisabled, setIsListboxDisabled] = useState(false);
  const [autoSaved, setAutoSaved] = useState(false);

  const { name, current_engineers = [] } = project;
  const { statuses } = useContext(StatusesContext);

  const items = buildListboxOptions(statuses?.data);

  const queryClient = useQueryClient();
  const {
    createProjectStatus,
    updateProjectStatus,
  } = useProjectStatusesQuery();

  const { data: status, isLoading: isWeekStatusLoading } = useResourcesQuery(
    "project_status",
    {
      ...dateFilters,
      projectId,
    },
  );

  const { mutateAsync: createStatus } = useMutation(createProjectStatus, {
    onSuccess: () => invalidateQueries(queryClient, dateFilters, projectId),
    onError: error => alert(error),
  });

  const { mutateAsync: updateStatus } = useMutation(updateProjectStatus, {
    onSuccess: () => invalidateQueries(queryClient, dateFilters, projectId),
    onError: error => alert(error),
  });

  const handleStatus = option => {
    setIsListboxDisabled(true);

    const payload = {
      id: preselectedStatus?.id,
      project_id: projectId,
      status_id: option ? option.value : preSelectedOption?.value,
      week_on: dateFilters.from,
    };

    if (!isEmpty(status)) {
      updateStatus(payload).then(setIsListboxDisabled(false));
    } else {
      createStatus(payload).then(setIsListboxDisabled(false));
    }
  };

  useEffect(() => {
    if (!isWeekStatusLoading && isEmpty(status) && !autoSaved) {
      handleStatus();
      setAutoSaved(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, dateFilters, status, isWeekStatusLoading, autoSaved]);

  useEffect(() => {
    setAutoSaved(false);
  }, [projectId, dateFilters]);

  useEffect(() => {
    const item = isEmpty(preselectedStatus)
      ? {
          value: 1,
          text: statusDetails["green"].text,
          icon: statusDetails["green"].icon,
        }
      : {
          value: preselectedStatus.status_id,
          text: statusDetails[preselectedStatus.name.toLowerCase()].text,
          icon: statusDetails[preselectedStatus.name.toLowerCase()].icon,
        };

    setPreSelectedOption(item);
  }, [preselectedStatus]);

  return (
    <div className="flex items-center">
      <ProjectLogo project={project} size="SM" />
      <h1
        data-testid="projectStatusName"
        className="text-2xl font-bold text-white mx-3"
      >
        {name} - {title}
      </h1>

      <ListboxPicker
        onSelect={handleStatus}
        items={items}
        preSelectedOption={preSelectedOption}
        WrapperClassName="w-28"
        blackTheme
        showPickerIcon={false}
        disabled={isListboxDisabled}
        fixedSelectedText="Status"
      />
      <Spacer />
      <div className="flex-1 flex justify-end items-center">
        <span className="text-gray-500 font-medium block mr-2">
          Current Members:
        </span>
        <div className="flex">
          {current_engineers.map((engineer, index) => {
            const { name, avatar_url, id } = engineer;
            const userPtos = ptos.filter(pto => pto.attributes.user_id == id);
            const currentPtoEmoji =
              userPtos[0] &&
              hasPtoThisWeek(
                userPtos[0].attributes.start_at,
                dateFilters.from,
              ) &&
              userPtos[0].attributes.pto_emoji;
            //this formula was calculated empirically
            const tooltipTopOffset = -50 - userPtos.length * 10;
            return (
              <div key={index}>
                <div
                  className="relative mr-1 cursor-pointer"
                  data-tip
                  data-for={name}
                  data-place="left"
                  data-effect="solid"
                  data-offset={`{'top': ${tooltipTopOffset}, 'left': -20}`}
                  data-arrow-color="transparent"
                >
                  {!!avatar_url ? (
                    <Link to={`/users/${id}`}>
                      <Avatar
                        source={avatar_url}
                        name={name}
                        width="30"
                        height="30"
                      />
                    </Link>
                  ) : (
                    <Link to={`/users/${id}`}>
                      <DefaultLogo name={name?.charAt(0)} size="SM" />
                    </Link>
                  )}

                  <div className="absolute text-xl -top-2.5 -right-1">
                    {currentPtoEmoji}
                  </div>
                </div>
                <ReactTooltip id={name} backgroundColor="#FFFFFF">
                  <span className="text-black">{name}</span>
                  {userPtos.map((pto, index) => {
                    return (
                      <div key={index} className="flex items-center">
                        <div className="text-xl">
                          {pto.attributes.pto_emoji}
                        </div>
                        <div className="ml-1 text-black">
                          {dateFormatter(pto.attributes.start_at)}
                        </div>
                        {pto.attributes.start_at !== pto.attributes.end_at && (
                          <div className="ml-1 text-black">
                            {` → ${dateFormatter(pto.attributes.end_at)}`}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </ReactTooltip>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
