import React, { createContext, useCallback } from "react";

const noop = () => {
  return undefined;
};

function createPromise() {
  let resolve;
  const promise = new Promise(resolver => {
    resolve = resolver;
  });
  return { promise, resolve };
}

export const promiseResolutions = new Map();

export const NotificationContext = createContext([]);
export const SetNotificationContext = createContext(noop);

export function useNotificationList() {
  return React.useContext(NotificationContext);
}

export function useSetNotification() {
  return React.useContext(SetNotificationContext);
}

export function useNotifications() {
  return [useNotificationList(), useSetNotification()];
}

export function useNotification() {
  const setNotifications = useSetNotification();

  if (setNotifications === noop) {
    throw new Error(
      "useNotification must be used within a NotificationProvider",
    );
  }

  const addNotification = useCallback(
    newNotification => {
      const id = newNotification.id || Date.now();
      setNotifications(notifications => {
        const repeatedNotification = notifications.find(el => el.id === id);
        if (repeatedNotification) return notifications;
        return [...notifications, { ...newNotification, id }];
      });

      if (newNotification.action) {
        const { promise, resolve } = createPromise();
        if (resolve) {
          promiseResolutions.set(id, resolve);
        }
        return promise;
      }
    },
    [setNotifications],
  );

  return addNotification;
}
