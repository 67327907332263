import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { subDays } from "date-fns";
import dateFormat from "helpers/dateFormat";

export default function RefetchData({
  projectId,
  title,
  fromDateLabel = "From",
  hasToDate = true,
  RefetchButton,
}) {
  const [dateFrom, setDateFrom] = useState(dateFormat(subDays(Date.now(), 1)));
  const [dateTo, setDateTo] = useState(dateFormat(Date.now()));
  const [isValidRange, setIsValidRange] = useState(false);

  useEffect(() => {
    const from = new Date(dateFrom);
    const to = new Date(dateTo);
    setIsValidRange(to > from);
  }, [dateFrom, dateTo]);

  return (
    <>
      <h4 className="text-neutral-100 text-lg font-bold mb-4">{`Refetch ${title}'s data`}</h4>
      <div className="flex space-x-2 items-center">
        <label className="text-sm font-medium text-neutral-200">
          {fromDateLabel}
        </label>
        <input
          type="date"
          className="focus:outline-none rounded-md p-2"
          id="from"
          name="refetch-from"
          value={dateFrom}
          onChange={({ target }) => setDateFrom(target.value)}
        />
        {hasToDate ? (
          <>
            <label className="text-sm font-medium text-neutral-200">To</label>
            <input
              type="date"
              className="focus:outline-none rounded-md p-2"
              id="to"
              name="refetch-to"
              value={dateTo}
              onChange={({ target }) => setDateTo(target.value)}
            />
          </>
        ) : null}

        <div className={isValidRange ? "" : "pointer-events-none opacity-40"}>
          <RefetchButton projectId={projectId} from={dateFrom} to={dateTo} />
        </div>
      </div>
      {!isValidRange && !isEmpty(dateFrom) && !isEmpty(dateTo) && (
        <p className="text-alert-base text-sm">Invalid date range</p>
      )}
    </>
  );
}
