import startOfWeek from "date-fns/startOfWeek";
import React from "react";
import { endOfWeek, format } from "date-fns";
import { isStartEndSameMonth } from "./SnashotCommons";

export default function WeekHeader({ week }) {
  return (
    <>
      <div>
        {format(startOfWeek(week, { weekStartsOn: 1 }), "MMM")}
        {isStartEndSameMonth(week)
          ? ""
          : "-" + format(endOfWeek(week, { weekStartsOn: 1 }), "MMM")}
      </div>
      <div>
        {format(week, "dd")}-
        {format(endOfWeek(week, { weekStartsOn: 1 }), "dd")}
      </div>
    </>
  );
}
