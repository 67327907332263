import React from "react";
import { Frame } from "@ableco/baseline";
import CrmProspectingChart from "components/charts/recruitment/CrmProspectingChart";

export default function CrmProspecting() {
  return (
    <Frame className="mb-10">
      <CrmProspectingChart />
    </Frame>
  );
}
