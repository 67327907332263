export const STORY_TYPE_ICONS = {
  bug: "🐛",
  pitch: "📣",
  tweak: "✨",
  chore: "🔧",
  story: "✨",
};

export const LOGO_SIZES = {
  XXS: { width: "15", height: "15" },
  XS: { width: "25", height: "25" },
  SM: { width: "30", height: "30" },
  MD: { width: "50", height: "50" },
  LG: { width: "80", height: "80" },
};

export const METRICS_TYPES = [
  { value: "pitch_count", text: "# of Pitches" },
  { value: "definition_deviation", text: "Definition Δ" },
  { value: "development_deviation", text: "Development Δ" },
  { value: "deliver_sed", text: "Deliver → SED" },
  { value: "flow_issues", text: "Issues per Flow" },
  { value: "ttf_isues", text: "TTF Issues" },
  { value: "pr_ready_approved", text: "PR Ready → Approved" },
];

export const METRICS_DATA = {
  pitch_count: {
    name: "# of Pitches",
    tooltip: "Number of Pitches that completed the Development Phase.",
    formula: null,
  },
  definition_deviation: {
    name: "Definition Δ",
    tooltip:
      "Average Deviation from the Scheduled End Date to the actual completion of the Definition Phase.",
    formula: [
      "(actualDaysTotal - scheduledDaysTotal) / scheduledDaysTotal",
      "actualDaysTotal = SUM of all pitches Business days for the Definition Phase from scheduled start date to Phase completion date (when all tasks were marked as completed)",
      "scheduledDaysTotal = SUM of all pitches Business days for the Definition Phase from scheduled start date scheduled end date",
    ],
  },
  development_deviation: {
    name: "Development Δ",
    tooltip:
      "Average Deviation from the Scheduled End Date to the actual completion of the Development Phase.",
    formula: [
      "(actualDaysTotal - scheduledDaysTotal) / scheduledDaysTotal",
      "actualDaysTotal = SUM of all pitches Business days for the Development Phase from scheduled start date to Phase completion date (when all tasks were marked as completed)",
      "scheduledDaysTotal = SUM of all pitches Business days for the Development Phase from scheduled start date scheduled end date",
    ],
  },
  deliver_sed: {
    name: "Deliver → SED",
    tooltip:
      "% of Deliver Tasks that were completed X days ahead of the Scheduled End Date / Avg. Days between the last Deliver Task completion and the Scheduled End Date.",
    formula: [
      "# pitches with Deliver Task completed X business days before SED /(# pitches * 100)",
      "SUM of all pitches → Business days (Scheduled End Date - Last Deliver Task) / # pitches in days",
    ],
  },
  flow_issues: {
    name: "Issues per Flow",
    tooltip: "Average number of Issues per Flow.",
    formula: null,
  },
  ttf_isues: {
    name: "TTF Issues",
    tooltip:
      "% of Accept Tasks with issues addressed within a day / Average Time for Issues Fixes of Accept Tasks.",
    formula: null,
  },
  pr_ready_approved: {
    name: "PR Ready → Approved",
    tooltip:
      "% of PRs received Code Quality Approval within 2 days / Average Time for PR Ready to" +
      " Review to Approved.",
    formula: null,
  },
};

export const RECRUITMENT_STAGES_ORDER = [
  "Application review",
  "Experience-fit",
  "Devskiller",
  "Skills-fit",
  "Reference check",
  "Final stage",
  "Offer",
  "Hired",
];

export const RECRUITMENT_LOCATIONS = [
  { label: "LATAM", value: "latam" },
  { label: "USA", value: "usa" },
  { label: "All", value: "all" },
];

export const BAR_COLORS = [
  "#5f68ef",
  "#da713e",
  "#9d4ec9",
  "#4da2dc",
  "#a8bb51",
  "#60c2c2",
  "#D9F7BE",
  "#A8071A",
  "#516240",
  "#947fff",
  "#00ad8b",
  "#140033",
  "#714399",
  "#008dff",
  "#3d5d5f",
  "#612e24",
  "#0000ff",
  "#554b06",
  "#ff3200",
  "#3d201c",
  "#17093f",
  "#150128",
  "#062d47",
  "#34ff70",
  "#15000f",
  "#4b46cd",
  "#cc0000",
  "#714635",
  "#4e2d24",
  "#743a0a",
  "#6a8154",
  "#a98d7f",
  "#957e5e",
  "#b17c54",
  "#c3906a",
  "#a44902",
  "#843284",
  "#85e5e5",
  "#ffb8ce",
  "#afeeee",
  "#660066",
  "#aaeecc",
  "#69a499",
  "#2d4641",
  "#97ebdb",
  "#181b31",
  "#282e54",
  "#ffdd91",
  "#bc3a3a",
  "#01786f",
];
