import React from "react";
import { TextWeight, Text, Flex, Color } from "@ableco/baseline";
import { LOGO_SIZES } from "helpers/constants";

export default function DefaultLogo({ name, size = "MD" }) {
  return (
    <Flex
      alignment="center"
      className="rounded-full justify-center bg-primary-light"
      style={{
        width: parseInt(LOGO_SIZES[size].width),
        height: parseInt(LOGO_SIZES[size].height),
      }}
    >
      <Text
        color={Color.PrimaryDark}
        weight={TextWeight.Bold}
        className="uppercase"
      >
        {name}
      </Text>
    </Flex>
  );
}
