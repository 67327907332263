import classNames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  NotificationContext,
  promiseResolutions,
  SetNotificationContext,
  useNotifications,
} from "../contexts/NotificationsContext";
import Alert from "icons/remove.svg";
import { WarningFilled, CheckCircleFilled } from "@baseline/icons";
import Close from "icons/close.svg";
import useDOMState from "hooks/useDOMState";
import { useParams } from "react-router";

const notificationTypesData = {
  success: {
    bgColor: "bg-green-base",
    icon: <CheckCircleFilled className="text-indicator-green h-5 w-5" />,
  },
  warning: {
    bgColor: "bg-orange-dark",
    icon: <WarningFilled className="text-indicator-yellow h-5 w-5" />,
  },
  alert: {
    bgColor: "bg-alert-dark",
    icon: <Alert className="text-indicator-red h-5 w-5" />,
  },
};

export function NotificationProvider({ children }) {
  const [notifications, setNotifications] = useState([]);

  return (
    <NotificationContext.Provider value={notifications}>
      <SetNotificationContext.Provider value={setNotifications}>
        {children}
      </SetNotificationContext.Provider>
    </NotificationContext.Provider>
  );
}

export function NotificationContainer(props) {
  const [notifications, setNotifications] = useNotifications();
  const [showOtherNotifications, setShowOtherNotifications] = useState(false);
  const $ref = useRef();
  const [{ currentUser }] = useDOMState("root", {});
  const { level } = currentUser;
  const params = useParams();

  function removeNotification({ id, action, actionClicked }) {
    if (action) {
      const resolve = promiseResolutions.get(id);
      if (resolve) {
        promiseResolutions.delete(id);
        resolve(actionClicked);
      }
    }

    setNotifications(currentNotifications =>
      currentNotifications.filter(notification => notification.id !== id),
    );
  }

  useEffect(() => {
    const pathname = window.location.pathname;
    if (!pathname.includes("snapshot")) {
      if (notifications.find(el => el.id.toString().includes("snapshot")))
        setNotifications(currentNotifications =>
          currentNotifications.filter(
            notification => !notification.id.toString().includes("snapshot"),
          ),
        );
    }
  }, [params, notifications, setNotifications]);

  const notification = notifications.length > 0 ? notifications[0] : null;
  const otherNotificationNumbers = notifications.slice(1).length;

  if (level !== "admin") return null;

  return (
    <div className="relative w-full">
      {notification ? (
        <div className="relative w-full">
          <Notification
            labelRef={$ref}
            key={notification.id}
            {...notification}
            {...props}
            removeNotification={removeNotification}
          />
          {otherNotificationNumbers > 0 && (
            <div
              onClick={() => setShowOtherNotifications(prev => !prev)}
              className="cursor-pointer absolute text-white"
              style={{ top: "25%", left: $ref.current.offsetWidth + 30 }}
            >{`+ ${otherNotificationNumbers}`}</div>
          )}
        </div>
      ) : null}
      {showOtherNotifications && (
        <div className="absolute top-12 w-full z-50">
          {notifications.slice(1).map(notif => (
            <Notification
              key={notif.id}
              {...notif}
              {...props}
              removeNotification={removeNotification}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default function Notification({
  label,
  removeNotification,
  action,
  close,
  id,
  className,
  notificationNumber,
  labelRef,
  type = "success",
  ...rest
}) {
  const handleClose = useCallback(() => {
    removeNotification({ id, action, actionClicked: false });
  }, [id, action, removeNotification]);

  const handleAction = useCallback(() => {
    removeNotification({ id, action, actionClicked: true });
  }, [id, action, removeNotification]);

  const notificationTypeData = notificationTypesData[type];

  let closeComponent;
  if (close === undefined) {
    closeComponent = (
      <Close onClick={handleClose} className="cursor-pointer fill-current" />
    );
  } else if (close === null) {
    closeComponent = null;
  } else
    closeComponent = React.cloneElement(close, {
      onClick: handleClose,
    });

  return (
    <div
      className={classNames(
        "flex text-white h-12 px-2 items-center rounded-md border-2 border-neutral-200  space-x-2",
        notificationTypeData.bgColor,
        className,
      )}
      {...rest}
    >
      <div ref={labelRef} className="flex items-center space-x-2">
        {notificationTypeData.icon}
        <div>{label}</div>
      </div>
      <div className="flex-grow" />
      {action &&
        React.cloneElement(action, {
          onClick: handleAction,
        })}
      {closeComponent}
    </div>
  );
}
