import { subDays, subHours, intervalToDuration } from "date-fns";

function daysToDuration(value) {
  const absolutValue = Math.abs(value);
  const end = new Date();
  const start =
    absolutValue < 1
      ? subHours(end, absolutValue * 24)
      : subDays(end, absolutValue);

  return intervalToDuration({ start, end });
}

export default daysToDuration;
