import React, { useEffect, useState } from "react";
import { Switch } from "@headlessui/react";
import classNames from "classnames";

export default function Toggle({
  wrapperClassName,
  prompt,
  defaultValue = false,
  onChange,
  disabled = false,
}) {
  const [toggleOn, setToggleOn] = useState(false);

  useEffect(() => {
    setToggleOn(defaultValue);
  }, [defaultValue]);

  const handleChange = value => {
    if (onChange) onChange(value);
    else setToggleOn(value);
  };

  return (
    <div
      className={classNames("flex items-center space-x-4", wrapperClassName)}
    >
      {prompt && (
        <label className="text-sm font-medium text-neutral-200">{prompt}</label>
      )}
      <Switch
        checked={toggleOn}
        disabled={disabled}
        onChange={handleChange}
        className={classNames(
          "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75",
          toggleOn ? "bg-primary-base" : "bg-neutral-800",
          disabled ? "bg-opacity-50 cursor-not-allowed" : null,
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200",
            toggleOn ? "translate-x-5" : "translate-x-0",
            disabled ? "bg-opacity-50 cursor-not-allowed" : null,
          )}
        />
      </Switch>
    </div>
  );
}
