import React from "react";
import { useProject } from "hooks/useProject";
import useResourcesQuery from "queries/resourcesQueries";
import ProjectDetails from "components/dashboard/dashboardHeader/ProjectDetails";
import LoadingData from "../../snapshot/Loading";

export default function ProjectHeader({ projectId, title, dateFilters }) {
  const { data: project, isLoading } = useProject(projectId);
  const { data: status } = useResourcesQuery("project_status", {
    ...dateFilters,
    projectId,
  });
  const { data: ptos } = useResourcesQuery("ptos", {
    ...dateFilters,
    projectId,
  });
  const fetchedStatus = status || [];

  if (!project || !status || !ptos) return null;

  return (
    <>
      {isLoading ? (
        <LoadingData />
      ) : (
        <ProjectDetails
          project={project}
          preselectedStatus={fetchedStatus[0]}
          dateFilters={dateFilters}
          projectId={projectId}
          ptos={ptos.data}
          title={title}
        />
      )}
    </>
  );
}
