import React from "react";
import { Text, Table, TextSize } from "@ableco/baseline";

function TableLink({ value, isFullWidth = false }) {
  const linkStyles = {};
  if (!isFullWidth) {
    linkStyles["width"] = "20vw";
  }
  return (
    <a
      href={value.url}
      target="_blank"
      title={value.text}
      className="hover:text-primary-dark text-primary-base truncate block"
      style={linkStyles}
    >
      {value.text}
    </a>
  );
}

function TableSmallText({ value }) {
  return (
    <Text color="neutral-700" size={TextSize.SM}>
      {value}
    </Text>
  );
}

function TableData({
  columns,
  data,
  td: { className: tdClassName = "" } = {},
}) {
  return (
    <Table
      columns={columns}
      data={data}
      td={{
        p: 2,
        className: `text-neutral-100 pl-3 border-b border-neutral-700 border-solid ${tdClassName}`,
      }}
      th={{
        p: 2,
        className:
          "text-left pl-3 text-neutral-100 border-b-3 border-neutral-700 border-solid",
      }}
      className="w-full"
    />
  );
}

export { TableData, TableSmallText, TableLink };
