import React, { useContext } from "react";
import { DateFiltersContext } from "contexts/DateFiltersContext";
import { useParams } from "react-router-dom";
import useMetricsQuery from "hooks/v2/useMetricsQuery";
import useIssuesQuery from "hooks/v2/useIssuesQuery";
import AccordionSection from "components/AccordionSection";
import TableWithPagination from "components/TableWithPagination";
import { TableData } from "components/TableData";
import Select from "components/Select";
import useDOMState from "hooks/useDOMState";

export default function IssuesBeforeDeploymentDetails() {
  const { dateFilters } = useContext(DateFiltersContext);
  const { projectId, userId } = useParams();
  const { data: metrics } = useMetricsQuery("issues_details", {
    ...dateFilters,
    projectId,
    userId,
  });

  const { updateIssue } = useIssuesQuery();
  const [{ currentUser }] = useDOMState("root", {});
  const isAdminUser = currentUser.level === "admin";

  const fetchedData = metrics?.data || [];

  const issueDetailsColumns = [
    {
      label: "Title",
      render: ({ item }) => {
        return (
          <a
            href={item.url}
            target="_blank"
            title={item.name}
            className="hover:text-primary-dark text-primary-base truncate block"
            style={{ width: "15vw" }}
          >
            {item.name}
          </a>
        );
      },
    },
    {
      label: "Reported By",
      name: "reported_by",
    },
    {
      label: "Fixed By",
      render: ({ item }) => {
        const fixed_by = item?.assignees.reduce(
          (fixed_by, current) =>
            fixed_by === "" ? current : fixed_by + ", " + current,
          "",
        );
        if (fixed_by === "") return "unknown";
        else return fixed_by;
      },
    },
    {
      label: "Type",
      name: "type",
    },
    {
      label: "Additional Type Info",
      render: ({ item }) => {
        return isAdminUser ? (
          <Select
            previousSelectedOption={item.tags || ""}
            options={metrics.meta.issues_tags}
            handleSelect={selectedOption => {
              updateIssue(item.id, {
                tags: selectedOption === "" ? null : selectedOption,
              });
            }}
            defaultOptionValue=""
            defaultOptionPlaceholder="Unset"
            isDefaultOptionDisabled={false}
          />
        ) : (
          <p>{item.tags || "unknown"}</p>
        );
      },
    },
  ];

  const detailsColumns = [
    {
      label: "Title",
      render: ({ item }) => {
        if (item?.type) {
          return (
            <AccordionSection title={item.name}>
              <TableData columns={issueDetailsColumns} data={item.issues} />
            </AccordionSection>
          );
        }
        return <p>Loading...</p>;
      },
    },
    { label: "Type", name: "type" },
    {
      label: "#Issues",
      render: ({ item }) => {
        if (item?.type) {
          return item?.issues.length || 0;
        }
        return "-";
      },
    },
  ];

  const renderChartDetails = (
    <AccordionSection title="Details">
      <TableWithPagination
        columns={detailsColumns}
        data={fetchedData}
        td={{ className: "align-top" }}
      />
    </AccordionSection>
  );

  return <>{renderChartDetails}</>;
}
