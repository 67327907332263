import React, { useContext, useMemo } from "react";
import { buildMenus, flatMenuWithLink } from "../config";
import { ProjectsContext } from "contexts/ProjectsContext";
import useDOMState from "hooks/useDOMState";
import { DateFiltersContext } from "contexts/DateFiltersContext";
import { getHeaderAndTabs } from "./utils";

function Header({ changeSidebarExpand }) {
  const { projects } = useContext(ProjectsContext);
  const { dateFilters } = useContext(DateFiltersContext);
  const [{ currentUser }] = useDOMState("root", {});

  const menuRoute = useMemo(
    () => flatMenuWithLink(buildMenus(projects, currentUser)),
    [projects, currentUser],
  );

  const currentRoute = menuRoute.find(
    route => route.path === window.location.pathname,
  );

  const [renderHeader, renderTabs] = getHeaderAndTabs(
    currentRoute,
    currentUser,
    dateFilters,
  );

  return (
    <div className="pt-2 w-full">
      <div className="flex items-center p-2 text-semibold text-neutral-100">
        <button className="p-1 mr-4" onClick={changeSidebarExpand}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              color="#fff"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
        <div className="w-full">{renderHeader}</div>
      </div>
      {renderTabs}
    </div>
  );
}

export default Header;
