import React, { useContext } from "react";
import CalendarHeatmap from "react-calendar-heatmap";
import "react-calendar-heatmap/dist/styles.css";
import ReactTooltip from "react-tooltip";
import { Flex } from "@ableco/baseline";
import { useParams } from "react-router-dom";
import { DateFiltersContext } from "contexts/DateFiltersContext";
import useMetricsQuery from "hooks/v2/useMetricsQuery";
import MetricChartContainer from "components/MetricChartContainer";

const getQuartileNumber = (max, number) => {
  const quartiles = [max / 4, (max * 2) / 4, (max * 3) / 4, max];
  for (let index = 0; index < 4; index++) {
    if (number <= quartiles[index]) {
      return index + 1;
    }
  }
};

const generateClassForValue = (value, metrics) => {
  if (!value) {
    return "color-empty";
  }
  return `color-github-${getQuartileNumber(metrics?.max_count, value.count)}`;
};

export default function CommitsChart() {
  const { projectId, userId } = useParams();
  const { dateFilters } = useContext(DateFiltersContext);
  const { data: metrics, isLoading } = useMetricsQuery("commits", {
    ...dateFilters,
    projectId,
    userId,
  });

  const dataChart = metrics?.data || [];
  const renderDescription = (
    <p className="pb-2">
      This chart shows the{" "}
      <strong>commits contributions done by the team per day</strong>.
    </p>
  );

  return (
    <MetricChartContainer
      data={dataChart}
      isLoading={isLoading}
      title="Commits per Day"
      description={renderDescription}
    >
      <Flex className="w-full" direction="horizontal" distribution="center">
        <CalendarHeatmap
          startDate={dateFilters.from}
          endDate={dateFilters.to}
          horizontal
          values={dataChart}
          classForValue={value => generateClassForValue(value, metrics)}
          tooltipDataAttrs={value => {
            return (
              value.date && {
                "data-tip": `${value.count} commit(s) on ${value.date}`,
              }
            );
          }}
          showWeekdayLabels
          showOutOfRangeDays
        />
        {dataChart.length > 0 && <ReactTooltip />}
      </Flex>
    </MetricChartContainer>
  );
}
