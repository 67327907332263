import React from "react";
import Spinner from "components/Spinner";

export default function LoadingData() {
  return (
    <div className="text-white flex flex-wrap gap-2">
      <Spinner />
      <span>Loading data</span>
    </div>
  );
}
