import React, { useState, useEffect } from "react";
import FormLabel from "components/FormLabel";
import Checkbox from "components/Checkbox";
import Modal from "components/Modal";
import FormFooter from "components/FormFooter";
import projectQueries from "queries/projectQueries";
import { useMutation, useQueryClient } from "react-query";
import { isEmpty } from "lodash";

export default function ProjectForm({
  open,
  setOpen,
  project,
  isEditing = false,
}) {
  const [projectName, setProjectName] = useState("");
  const [logoUrl, setLogoUrl] = useState("");
  const [coreProductId, setCoreProductId] = useState("");
  const [coreProjectId, setCoreProjectId] = useState("");
  const [sentryId, setSentryId] = useState("");
  const [isStatusVisible, setIsStatusVisible] = useState(true);
  const [isArchived, setIsArchived] = useState(false);

  const queryClient = useQueryClient();
  const { createProject, editProject } = projectQueries();

  const { mutateAsync: addProject } = useMutation(createProject, {
    onSuccess: () => queryClient.invalidateQueries("projects"),
    onError: error => alert(error),
  });

  const { mutateAsync: updateProject } = useMutation(editProject, {
    onSuccess: () => queryClient.invalidateQueries("projects"),
    onError: error => alert(error),
  });

  useEffect(() => {
    if (isEditing) {
      const {
        name,
        logo_url,
        core_product_id,
        core_project_id,
        integration_ids,
        show_status,
        archived,
      } = project;

      setProjectName(name);
      setLogoUrl(logo_url);
      setCoreProductId(core_product_id?.toString());
      setCoreProjectId(core_project_id?.toString());
      setSentryId(integration_ids?.sentry_id);
      setIsStatusVisible(show_status);
      setIsArchived(archived);
    }
  }, [project, isEditing]);

  const handleOpenForm = isOpen => {
    setOpen(isOpen);

    if (!isOpen && !isEditing) {
      setProjectName("");
      setLogoUrl("");
      setCoreProductId("");
      setCoreProjectId("");
      setSentryId("");
      setIsStatusVisible(true);
      setIsArchived(false);
    }
  };

  const handleSubmit = () => {
    const payload = {
      id: project?.id,
      name: projectName,
      logo_url: logoUrl,
      core_product_id: coreProductId,
      core_project_id: coreProjectId,
      integration_ids: isEmpty(sentryId) ? null : { sentry_id: sentryId },
      show_status: isStatusVisible,
      archived: isArchived,
    };

    if (isEditing) {
      updateProject(payload).then(() => {
        handleOpenForm(false);
      });
    } else {
      addProject(payload).then(() => {
        handleOpenForm(false);
      });
    }
  };

  const formContent = (
    <div>
      <FormLabel required>Name</FormLabel>
      <input
        className="focus:outline-none rounded-md w-full p-2 mb-2"
        onChange={({ target }) => setProjectName(target.value)}
        value={projectName}
      />
      <FormLabel>Logo URL</FormLabel>
      <input
        className="focus:outline-none rounded-md w-full p-2"
        placeholder="e.g. project-logos/logo-able-metrics-l.png"
        onChange={({ target }) => setLogoUrl(target.value)}
        value={logoUrl}
      />
      <div className="flex space-x-4 my-2">
        <div className="w-1/2">
          <FormLabel required>Core Product ID</FormLabel>
          <input
            className="focus:outline-none rounded-md w-full p-2"
            placeholder="e.g. 31"
            onChange={({ target }) => setCoreProductId(target.value)}
            value={coreProductId}
          />
        </div>
        <div className="w-1/2">
          <FormLabel required>Core Project ID</FormLabel>
          <input
            className="focus:outline-none rounded-md w-full p-2"
            placeholder="e.g. 226"
            onChange={({ target }) => setCoreProjectId(target.value)}
            value={coreProjectId}
          />
        </div>
      </div>
      <FormLabel>Sentry ID</FormLabel>
      <input
        className="focus:outline-none rounded-md w-full p-2 mb-2"
        placeholder="e.g. metrics-dashboard-production"
        onChange={({ target }) => setSentryId(target.value)}
        value={sentryId}
      />

      <div className="flex flex-col space-y-2 mt-4">
        <Checkbox
          checked={isStatusVisible}
          prompt={
            <span className="text-sm text-primary-light">
              Show this Project in the Project Status page?
            </span>
          }
          onChange={({ target }) => setIsStatusVisible(target.checked)}
        />
        <Checkbox
          checked={isArchived}
          prompt={
            <span className="text-sm text-primary-light">
              Archive this project?
            </span>
          }
          onChange={({ target }) => setIsArchived(target.checked)}
        />
      </div>
    </div>
  );

  return (
    <Modal
      title={`${isEditing ? "Edit" : "Add"} Project`}
      subtitle={`Fill out the form to ${
        isEditing ? "edit" : "add a new"
      } Project`}
      content={formContent}
      footer={
        <FormFooter
          onCancel={() => handleOpenForm(false)}
          onSubmit={handleSubmit}
          disabled={
            isEmpty(projectName) ||
            isEmpty(coreProductId) ||
            isEmpty(coreProjectId)
          }
        />
      }
      open={open}
      setOpen={handleOpenForm}
    />
  );
}
