import React, { useState } from "react";
import AccordionSection from "components/AccordionSection";
import CommitmentForm from "./CommitmentForm";
import InlineDeleteConfirmation from "./InlineDeleteConfirmation";
import ChoreIcon from "icons/chore.svg";
import BugIcon from "icons/bug.svg";
import PitchIcon from "icons/pitch.svg";
import TweakIcon from "icons/tweak.svg";
import FlagIcon from "icons/flag.svg";
import DeleteIcon from "icons/delete.svg";
import EditIcon from "icons/edit.svg";
import { groupBy, prop } from "ramda";
import { isEmpty, capitalize, sortBy, indexOf } from "lodash";
import pluralize from "pluralize";
import useCommitmentsQuery from "hooks/v2/useCommitmentsQuery";
import { useMutation, useQueryClient } from "react-query";
import ReactTooltip from "react-tooltip";
import { format } from "date-fns";

const commitmentIcons = {
  pitch: PitchIcon,
  tweak: TweakIcon,
  chore: ChoreIcon,
  bug: BugIcon,
  story: TweakIcon,
};

const flagColorClass = {
  red: "text-alert-light",
  blue: "text-blue-light",
  green: "text-green-light",
};

const commitmentsOrder = ["update", "pitch", "tweak", "chore", "bug", "story"];

const groupByCommitmentType = groupBy(prop("type"));

function dateFormatter(strDate) {
  const date = new Date(`${strDate} 00:00:00`);
  return format(date, "MMM dd, yyyy");
}

function CommitmentItem({ commitment, handleDelete, projectId, dateFilters }) {
  const [isCommitmentFormOpen, setIsCommitmentFormOpen] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const {
    id,
    name,
    url,
    type,
    counter,
    flag,
    update_type,
    schedule_date,
    overdue_days,
  } = commitment;
  const isOverdue = overdue_days > 0;
  return (
    <li className="ml-6 mb-1 last:mb-0 bg-nord-light hover:bg-neutral-800 rounded-lg">
      <div className="flex justify-between">
        <div className="flex w-full items-center px-2">
          <a
            href={isEmpty(url) ? null : url}
            target="_blank"
            title={url}
            className="text-base leading-loose text-neutral-100 hover:text-primary-base flex-1"
          >
            {name}
            {type === "pitch" && <span className="ml-2">{counter}</span>}
          </a>
          {flag && (
            <div>
              <FlagIcon
                className={`${flagColorClass[flag]} fill-current flex-shrink-0`}
                data-tip
                data-for={name}
              />
              {isOverdue && (
                <ReactTooltip id={name} backgroundColor="#FFFFFF">
                  <div className="text-black">
                    <p>{`Scheduled date: ${dateFormatter(schedule_date)}`}</p>
                    <p>{`Overdue day(s): ${overdue_days}`}</p>
                  </div>
                </ReactTooltip>
              )}
            </div>
          )}
          {type === "update" &&
            (showConfirmation ? (
              <InlineDeleteConfirmation
                onCancel={() => setShowConfirmation(false)}
                onDelete={() => {
                  handleDelete(id);
                  setShowConfirmation(false);
                }}
              />
            ) : (
              <div className="flex items-center">
                <EditIcon
                  className="text-neutral-200 fill-current flex-shrink-0 ml-1.5 cursor-pointer"
                  onClick={() => setIsCommitmentFormOpen(true)}
                />

                <DeleteIcon
                  className="text-neutral-200 fill-current flex-shrink-0 ml-1.5 cursor-pointer"
                  onClick={() => setShowConfirmation(true)}
                />
              </div>
            ))}
          <CommitmentForm
            open={isCommitmentFormOpen}
            setOpen={setIsCommitmentFormOpen}
            commitmentType={update_type}
            projectId={projectId}
            dateFilters={dateFilters}
            isEditing={true}
            commitment={commitment}
          />
        </div>
      </div>
    </li>
  );
}

function EmptyMessage({ message = "No data found." }) {
  return <span className="block text-neutral-200 my-6">{message}</span>;
}

export default function CommitmentsList({
  items,
  emptyMessage,
  projectId,
  dateFilters,
  dataTestId,
}) {
  const sortedCommitments = sortBy(items, commitment =>
    indexOf(commitmentsOrder, commitment.type),
  );

  const queryClient = useQueryClient();
  const { deleteCommitmentUpdate } = useCommitmentsQuery();

  const { mutateAsync: removeUpdate } = useMutation(deleteCommitmentUpdate, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        "/api/**/**/commitments",
        { ...dateFilters, projectId },
      ]);
    },
  });

  let commitmentGroups = groupByCommitmentType(sortedCommitments);

  const renderCommitmentGroups = Object.keys(commitmentGroups)?.map(type => {
    const TypeIcon = commitmentIcons[type];
    return (
      <AccordionSection
        key={type}
        openByDefault={true}
        title={
          <span className="flex items-center">
            {`${capitalize(pluralize(type))} (${
              commitmentGroups[type].length
            })`}
            {TypeIcon && (
              <TypeIcon
                className={
                  "fill-current flex-shrink-0 ml-2 " +
                  (type === "bug"
                    ? "text-alert-light mt-1"
                    : "text-neutral-100")
                }
              />
            )}
          </span>
        }
      >
        <ul className="p-0 mt-1">
          {commitmentGroups[type].map((item, index) => (
            <CommitmentItem
              key={index}
              commitment={item}
              projectId={projectId}
              dateFilters={dateFilters}
              handleDelete={commitmentId => removeUpdate(commitmentId)}
            />
          ))}
        </ul>
      </AccordionSection>
    );
  });

  return (
    <div data-testid={dataTestId}>
      {isEmpty(items) ? (
        <EmptyMessage message={emptyMessage} />
      ) : (
        renderCommitmentGroups
      )}
    </div>
  );
}
